/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";


//CONSTANTES

const dataInicialTipoEnvio = {
    arrayTipoEnvio : [],
    loadingTipoEnvio : true,
    errorTipoEnvio: ''
};

const dataInicialDepartamentos = {
    arrayDepartamentos : [],
    loadingDepartamentos : true,
    errorDepartamentos: ''
};

const dataInicialDataConsulta = {
    arrayDC : [],
    loadingDC : true,
    errorDC: ''
};


//TYPES

const OBTENER_TIPOENVIO_EXITO = 'OBTENER_TIPOENVIO_EXITO'
const OBTENER_TIPOENVIO_REQUEST = 'OBTENER_TIPOENVIO_REQUEST'
const OBTENER_TIPOENVIO_ERROR = 'OBTENER_TIPOENVIO_ERROR'

const OBTENER_DEPARTAMENTOS_EXITO = 'OBTENER_DEPARTAMENTOS_EXITO'
const OBTENER_DEPARTAMENTOS_REQUEST = 'OBTENER_DEPARTAMENTOS_REQUEST'
const OBTENER_DEPARTAMENTOS_ERROR = 'OBTENER_DEPARTAMENTOS_ERROR'

const OBTENER_DATACONSULTA_EXITO = 'OBTENER_DATACONSULTA_EXITO'
const OBTENER_DATACONSULTA_REQUEST = 'OBTENER_DATACONSULTA_REQUEST'
const OBTENER_DATACONSULTA_ERROR = 'OBTENER_DATACONSULTA_ERROR'


//REDUCERS

export function tipoEnvioReducer (state = dataInicialTipoEnvio, action){
    switch (action.type) {
        case OBTENER_TIPOENVIO_REQUEST:
            return { loadingTipoEnvio: true, arrayTipoEnvio:[] };
        case OBTENER_TIPOENVIO_EXITO:
            return {...state, arrayTipoEnvio: action.payload, loadingTipoEnvio: false}; 
        case OBTENER_TIPOENVIO_ERROR:
            return {errorTipoEnvio: action.payload, loadingTipoEnvio: false};       
        default:
            return state
    }
}

export function departamentosReducer (state = dataInicialDepartamentos, action){
    switch (action.type) {
        case OBTENER_DEPARTAMENTOS_REQUEST:
            return { loadingDepartamentos: true, arrayDepartamentos:[] };
        case OBTENER_DEPARTAMENTOS_EXITO:
            return {...state, arrayDepartamentos: action.payload, loadingDepartamentos: false}; 
        case OBTENER_DEPARTAMENTOS_ERROR:
            return {errorDepartamentos: action.payload, loadingDepartamentos: false};       
        default:
            return state
    }
}

export function dataConsultaReducer (state = dataInicialDataConsulta, action){
    switch (action.type) {
        case OBTENER_DATACONSULTA_REQUEST:
            return { loadingDC: true, arrayDC:[] };
        case OBTENER_DATACONSULTA_EXITO:
            return {...state, arrayDC: action.payload, loadingDC: false}; 
        case OBTENER_DATACONSULTA_ERROR:
            return {errorDC: action.payload, loadingDC: false};       
        default:
            return state
    }
}


//ACCIONES

export const tipoEnvioAccion = () => async (dispatch) => {
    
    try {
        
        dispatch({type:OBTENER_TIPOENVIO_REQUEST})

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/tiposenvios')
        //console.log(res)
        /*if (res.status === 202) {
            dispatch({
                type: OBTENER_TIPOENVIO_ERROR,
                payload: res.data
            })
        }else{
            dispatch({
                type: OBTENER_TIPOENVIO_EXITO,
                payload: res.data
            })
        }*/

        dispatch({
            type: OBTENER_TIPOENVIO_EXITO,
            payload: res.data
        })
        
    } catch (error) {
        dispatch({
            type: OBTENER_TIPOENVIO_ERROR,
            payload: error
        })
    }
}


export const departamentosAccion = () => async (dispatch) => {

    try {
        dispatch({type:OBTENER_DEPARTAMENTOS_REQUEST})

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/departamentos')

        dispatch({
            type: OBTENER_DEPARTAMENTOS_EXITO,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: OBTENER_DEPARTAMENTOS_ERROR,
            payload: error
        })
    }
}

export const dataConsultaAccion = ($id) => async (dispatch) => {

    try {
        dispatch({type:OBTENER_DATACONSULTA_REQUEST})

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/datosconsulta/'+$id)

        dispatch({
            type: OBTENER_DATACONSULTA_EXITO,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: OBTENER_DATACONSULTA_ERROR,
            payload: error
        })
    }
}