/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import {useContext, useState, useEffect, useRef} from 'react'


import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import {useDispatch, useSelector} from 'react-redux'

import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../../context/UserProvider';
import { confirmationAccion } from '../../../../Redux/patos/CartDuck';

const ConfirmationPage = () => {

    const navegate = useNavigate();
    const dispatch = useDispatch();

    const {userLoading, userBer, userFire, signOutUser } = useContext(UserContext);
    const [activeIndex, setActiveIndex] = useState(2);


    const { ord } = useParams();

    useEffect(() => {

        if (userLoading) {
            
        }else{
            if (userBer && userFire) {

                const generarData = () =>{

                    dispatch(confirmationAccion(userBer.id, ord))
                   
                }
                generarData()
                
            }else{
                navegate('/')
            }
        }
        
        
    },[dispatch, userBer, ord])


    const itemSteps = [
        {label: 'Formulario'},
        {label: 'Pago'},
        {label: 'Confirmación'}
      ];

    const confirmationData = useSelector(store => store.confirmation)

    console.log(confirmationData)
  return (
    <>
        <div className=" px-4 py-8 md:px-6 lg:px-8">
            <div className='mb-2'>
                <Steps model={itemSteps} activeIndex={activeIndex} readOnly={true}/> 
            </div>

            {
                confirmationData.loadingConf ?(
                    <p>Cargando...</p>
                ):(
                    confirmationData.arrayConf.length > 0 ?(
                        confirmationData.arrayConf.map((confir, index) =>(
                            <div key={index} className="surface-section px-4 py-8 md:px-6 lg:px-8 border-round shadow-3 mt-4">
                                <span className="text-700 text-xl">Gracias!</span>
                                <div className="text-900 font-bold text-4xl my-2">Pedido Exitoso 🚀</div>
                                <p className="text-700 text-xl mt-0 mb-4 p-0">Tu pedido está por confirmar. Una vez que esté te informaremos.</p>
                                <div style={{height:"3px",background:"linear-gradient(90deg, #071826 0%, rgba(33, 150, 243, 0) 50%)"}}></div>
                                
                                <div className="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between py-5">
                                    <div className="mb-3 sm:mb-0">
                                        <span className="font-medium text-xl text-900 mr-2">Número de Orden:</span>
                                        <span className="font-medium text-xl text-blue-500">{confir.num_order}</span>
                                    </div>
                                    <div>
                                        <Button label="Details" icon="pi pi-list" className="p-button-outlined p-button-secondary mr-2"></Button>
                                        <Button label="Print" icon="pi pi-print" className="p-button-outlined p-button-secondary"></Button>
                                    </div>
                                </div>

                                <div className="border-round surface-border border-1">
                                    <ul className="list-none p-0 m-0">
                                        {
                                            confir.productos_venta_bersati.length > 0 ?(
                                                confir.productos_venta_bersati.map((prod, indexp) => (
                                                    <li key={indexp} className="p-3 border-bottom-1 surface-border flex align-items-start sm:align-items-center">
                                                        <img alt={prod.nombre} src={`${prod.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-3rem sm:w-8rem flex-shrink-0 mr-3 shadow-2" />
                                                        <div className="flex flex-column">
                                                            <span className="text-900 font-medium text-xl mb-2">{prod.nombre}</span>
                                                            <span className="text-600 font-medium mb-3">{prod.codigo} | {prod.marca}</span>
                                                            <span className="text-900 font-medium">Cantidad {prod.cantidad_prod} unidad(es)</span>
                                                            {
                                                                prod.isArmado > 0 ?(
                                                                    <span className="text-900 font-medium mt-1">Producto Armado: Si | Precio: {prod.precio_armado*prod.cantidad_prod} </span>  
                                                                ):null
                                                            }
                                                        </div>
                                                        <span className="text-900 font-medium text-lg ml-auto">{'BOB. '+(prod.precio_vendido*prod.cantidad_prod)}</span>
                                                    </li>
                                                ))
                                            ):(
                                                <p>No Data Products</p>
                                            )
                                        }
                                        
                                    </ul>
                                </div>


                                <div className="flex flex-wrap mt-6 pb-3">
                                    <div className="w-full lg:w-6 pl-3">
                                        {
                                            confir.tipo_envio === 1 ? (
                                                <>
                                                    <span className="font-medium text-900 text-2xl">Dirección de Envío</span>
                                                    <div className="flex flex-column text-900 mt-3 mb-5 text-lg">
                                                        <span className="mb-1">{confir.nombres} {confir.apellido_p} {confir.apellido_m}</span>
                                                        <span className="mb-1">{confir.direccion}, {confir.zona}</span>
                                                        <span>Celular: {confir.celular}</span>
                                                    </div>
                                                </>

                                            ):null
                                        }

                                        {
                                            confir.tipo_envio === 2 ? (
                                                <>
                                                    <span className="font-medium text-900 text-2xl">Dirección de Envío Nacional</span>
                                                    <div className="flex flex-column text-900 mt-3 mb-5 text-lg">
                                                        <span className="mb-1">Departamento de {confir.departamentoText}, {confir.ciudad}</span>
                                                        <span className="mb-1">Celular: {confir.celular}</span>
                                                        <span className='text-color-secondary text-sm'>*Nuestro personal se comunicará con usted al momento del envío.</span>
                                                    </div>
                                                </>

                                            ):null
                                        }

                                        {
                                            confir.tipo_envio === 3 ? (
                                                <>
                                                    <span className="font-medium text-900 text-2xl">Recojo en Sucursal</span>
                                                    <div className="flex flex-column text-900 mt-3 mb-5 text-lg">
                                                        <span className="mb-1">Celular: {confir.celular}</span>
                                                        <span className='text-color-secondary text-sm'>*Puede coordinar con el personal el día y hora de recojo.</span>
                                                    </div>
                                                </>

                                            ):null
                                        }

                                        <span className="font-medium text-900 text-2xl">Tipo de Pago</span>
                                        <div className="flex align-items-center mt-3">
                                            {/*<img alt='tar' src="assets/images/blocks/ecommerce/ordersummary/visa.png" className="w-4rem mr-3" />*/}
                                            <div className="flex flex-column text-lg">
                                                {
                                                    confir.tipo_pago === 101 ? (
                                                        <span className="text-900 mb-1">Pago ContraEntrega</span>
                                                    ):null
                                                }
                                                {
                                                    confir.tipo_pago === 102 ? (
                                                        <span className="text-900 mb-1">Pago por QR</span>
                                                    ):null
                                                }
                                                {
                                                    confir.tipo_pago === 103 ? (
                                                        <span className="text-900 mb-1">Pago a la Hora de Recoger (Retiro en Local)</span>
                                                    ):null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6 pl-3 lg:pl-0 lg:pr-3 flex align-items-end mt-5 lg:mt-0">
                                        <ul className="list-none p-0 m-0 w-full">
                                            <li className="mb-3"><span className="font-medium text-900 text-2xl">Resumen</span></li>
                                            <li className="flex justify-content-between mb-3 text-lg">
                                                <span className="text-900">Subtotal</span>
                                                <span className="text-900 font-medium text-lg">BOB. {confir.sub_total}</span>
                                            </li>
                                            <li className="flex justify-content-between mb-3 text-lg">
                                                <span className="text-900">Transporte</span>
                                                <span className="text-900 font-medium text-lg">BOB. {confir.total_envio}</span>
                                            </li>
                                            <li className="flex justify-content-between border-top-1 surface-border py-3 text-lg">
                                                <span className="text-900 font-medium">Total</span>
                                                <span className="text-900 font-bold text-lg">BOB. {confir.total_total}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        ))
                    ):(
                        <p>No Data</p>
                    )
                )
            }

        </div>
    </>
  )
}

export default ConfirmationPage