/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React,{ useEffect, useState, useContext, useRef } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import {CFlGPAccion, gpStoreAccion} from '../../../../../Redux/patos/storeCFLGP'
import { cartSaveAccion } from '../../../../../Redux/patos/CartDuck';


import imgProd from '../../../../../assets/img/product-list-7-1.png'
import Cargando from '../../../comps/Cargando';


import { UserContext } from '../../../../../context/UserProvider';
import axios from 'axios';



const StoreGP = () => {

    const {userBer, userFire, signOutUser} = useContext(UserContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { cate, fam, lin, gp } = useParams();

    const [varcito, setVarcito] = useState('');
    const [varcito1, setVarcito1] = useState('');
    const [varcito2, setVarcito2] = useState('');
    const [varcito3, setVarcito3] = useState('');
    const [displayPosition, setDisplayPosition] = useState(false);

    const toast = useRef(null);
    useEffect(() => {
        const generarData = () =>{

            dispatch(gpStoreAccion(cate, fam, lin, gp))
            dispatch(CFlGPAccion())

        }
        generarData()
    },[dispatch, cate, fam, lin, gp])


    const storeGP = useSelector(store => store.storeGP)
    const storeCFLGP = useSelector(store => store.listcflgp)
    const cartSave = useSelector(store => store.cartSave)
    

    useEffect(() => {

        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
          if (storeCFLGP.arrayCFlGP[index][cate]) {
            //console.log('hola')
            setVarcito(storeCFLGP.arrayCFlGP[index][cate])
            break
          }
          
        }

        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
            if (storeCFLGP.arrayCFlGP[index][fam]) {
              //console.log('hola')
              setVarcito1(storeCFLGP.arrayCFlGP[index][fam])
              break
            }
            
          }

        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
            if (storeCFLGP.arrayCFlGP[index][lin]) {
                //console.log('hola')
                setVarcito2(storeCFLGP.arrayCFlGP[index][lin])
                break
            }
        
        }

        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
            if (storeCFLGP.arrayCFlGP[index][gp]) {
                //console.log('hola')
                setVarcito3(storeCFLGP.arrayCFlGP[index][gp])
                break
            }
        
        }
  
      },[cate, fam, lin, gp, storeCFLGP])

      //const navigate = useNavigate();
      // <button onClick={() => navigate(-1)}>go back</button>
      const VerificarToken = () =>{
        let token = ''
          try {
            token = atob(localStorage.getItem('_vita'));
            //console.log(token)
            return token;
          } catch (error) {
              localStorage.removeItem('_vita');
              localStorage.removeItem('_dolce');
              navigate('/login');
              signOutUser()
            return 401;
          }
        
      }

      const SaveAlCarrito = async (data, token) =>{

        const tok = 'Bearer ' + token;
        const config = {
            method: 'post',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritoadd',
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
            data: data,
        }

        const res = await axios(config)

        return res;
      }
    const añadirAlCarrito =  async (id) => {

        if (userBer && userFire) {
            const tokenV =  VerificarToken();
            //console.log(tokenV)
            if (tokenV === 401) {
                
            }else{
                const data = {
                    'producto_id' : id,
                    'cantidad' : 1,
                    'usuario_id' : userBer.id
                }

                const responFinal = await SaveAlCarrito(data, tokenV)

               if (responFinal.data) {
                    if (responFinal.data.dis === 1) {
                        toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }else if(responFinal.data.dis === 0){
                        toast.current.show({severity:'success', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }
               }
            }
            
        }else{
            //console.log('Inicia Sesión')
            onDisplay()
        }
    }


    const onDisplay = () => {
        setDisplayPosition(true)
    }
    
    const onHide = () => {
        setDisplayPosition(false)
    }

    const renderFooter = () => {
        return (
            <div>
                <Link to={'/register'} className='no-underline'>   
                    <Button label="Regístrate" className="p-button-text text-900 hover:surface-hover" />
                </Link>
                <Link to={'/login'} className='no-underline'>
                    <Button label="Iniciar Sesión" className='surface-900' autoFocus />
                </Link>
            </div>
        );
    }

    //console.log(storeGP)
  return (
    <>
        <Toast ref={toast} position="center-right" className='z-5' />
        {
            storeCFLGP.loadingCFlGP ? (
                <div className='flex align-items-center justify-content-center '>
                    <Cargando />
                </div>
            ) 
            : 
            (
                <>

                    <ul className="list-none p-3 m-0 surface-card flex align-items-center font-medium overflow-x-auto">
                        <li className="pr-3">
                            <Link to={`/tienda`} className="no-underline cursor-pointer">
                                <i className="pi pi-home text-900"></i>
                            </Link>
                        </li>
                        <li className="px-2">
                            <i className="pi pi-angle-right text-900"></i>
                        </li>
                        <li className="px-2">
                            <Link to={`/tienda/${cate}`} className="no-underline cursor-pointer text-500 white-space-nowrap">{varcito}</Link>
                        </li>
                        <li className="px-2">
                            <i className="pi pi-angle-right text-500"></i>
                        </li>
                        <li className="px-2">
                            <Link to={`/tienda/${cate}/${fam}`} className="no-underline cursor-pointer text-500 white-space-nowrap">{varcito1}</Link>
                        </li>
                        <li className="px-2">
                            <i className="pi pi-angle-right text-500"></i>
                        </li>
                        <li className="px-2">
                            <Link to={`/tienda/${cate}/${fam}/${lin}`} className="no-underline cursor-pointer text-500 white-space-nowrap">{varcito2}</Link>
                        </li>
                        <li className="px-2">
                            <i className="pi pi-angle-right text-500"></i>
                        </li>
                        <li className="px-2">
                            <Link to={`/tienda/${cate}/${fam}/${lin}/${gp}`} className="no-underline cursor-pointer text-500 white-space-nowrap">{varcito3}</Link>
                        </li>
                    </ul>

                    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                        <div className="text-5xl font-medium text-900 text-center mb-6">Lista de Productos</div>

                        <div className="grid -mt-3 -ml-3 -mr-3">
                            {
                                storeGP.loadingGP ? (
                                    <p>Cargando..</p>
                                )
                                :(
                                    storeGP.arrayGP.map((store, index)=>(

                                        <div key={index} className="col-12 md:col-6 lg:col-3 mb-5 md:mb-0 ">
                                            {
                                                store.producto_promo.length > 0 ? (
                                                    <div className="p-2">
                                                        <div className="relative mb-3">
                                                            {
                                                                store.producto_promo[0].imagenPP ? (
                                                                    <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">
                                                                        <img alt={store.nombre} src={`${store.producto_promo[0].imagenPP}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                                                                    </Link>
                                                                ):(
                                                                    <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">
                                                                        <img alt={store.nombre} src={`${store.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                                                                    </Link>
                                                                )
                                                            }
                                                            {
                                                                store.producto_promo[0].descuentoNumber > 0 ? (

                                                                    <span className="bg-green-600 text-white font-bold text-xl px-3 py-1 absolute" style={{borderRadius: '1rem', right: '1rem', bottom: '1rem'}}>-%{store.producto_promo[0].descuentoNumber}</span>
                                                                ):null
                                                            }
                                                            {
                                                                store.producto_promo[0].envioGratis === 1 ? (
                                                                    <span className="bg-green-600 text-white font-bold px-2 py-1 absolute" style={{borderRadius: '1rem', left: '1rem', top: '1rem'}}>Envio Gratis</span>
                                                                ):null
                                                            }
                                                            
                                                        </div>
                                                        <div className='h-4rem'>
                                                            <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">
                                                                <span className="text-900 font-medium text-xl">{store.nombre} - {store.codigo}</span>
                                                            </Link>
                                                        </div>
                                                        <div className="my-3">
                                                            <span className="mr-3">
                                                                <i className="pi pi-star-fill text-yellow-500"></i>
                                                            </span>
                                                            <span className="text-500">{store.rating} Estrellas!</span>
                                                        </div>
                                                        <div className="mb-4 flex">
                                                            {
                                                                store.producto_promo[0].descuentoNumber === 0 ? (
                                                                    <span className="font-bold text-900 text-lg">BOB. {store.precio}</span> 
                                                                ):(

                                                                    <>
                                                                        <span className="font-bold text-500 text-lg line-through ">BOB. {store.precio}</span> <span className="font-bold text-lg text-900 ml-2">BOB. {(store.precio - (store.precio * (store.producto_promo[0].descuentoNumber / 100))).toFixed(2)}</span>
                                                                    </>
                                                                )
                                                            }
                                                            
                                                        </div>
                                                        <div className='grid'>
                                                            <div className='col-6 p-1'>
                                                                <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">
                                                                    <Button label="Detalles"  className="p-button-sm surface-700 hover:surface-900 border-none  w-full" />
                                                                </Link>
                                                            
                                                            </div>
                                                            <div className='col-6 p-1'>
                                                                <Button onClick={()=>añadirAlCarrito(store.id)} icon="pi pi-cart-plus" className="p-button-sm surface-900 hover:surface-700 border-none text-0 w-full transition-all transition-duration-300 text-center" tooltip="Añadir al Carrito" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Button>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                ):(
                                                    <div className="p-2">
                                                        <div className="relative mb-3">
                                                            <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">                                                               
                                                                <img alt={store.nombre} src={`${store.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                                                            </Link>
                                                            {/*<span className="bg-pink-500 text-pink-50 font-bold px-2 py-1 absolute" style={{borderRadius: '1rem', right: '1rem', bottom: '1rem'}}>-%25</span>*/}
                                                        </div>
                                                        <div className='h-4rem'>
                                                            <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">
                                                                <span className="text-900 font-medium text-xl">{store.nombre} - {store.codigo}</span>
                                                            </Link>
                                                        </div>
                                                        <div className="my-3">
                                                            <span className="mr-3">
                                                                <i className="pi pi-star-fill text-yellow-500"></i>
                                                            </span>
                                                            <span className="text-500">{store.rating} Estrellas!</span>
                                                        </div>
                                                        <div className="mb-4">
                                                            
                                                            <span className="font-bold text-900 text-lg">BOB. {store.precio}</span> 
                                                            
                                                        </div>
                                                        <div className='grid'>
                                                            <div className='col-6 p-1'>
                                                                <Link to={`/tienda/productodetalle/${store.id}`} className="no-underline">
                                                                    <Button label="Detalles"  className="p-button-sm surface-700 hover:surface-900 border-none  w-full" />
                                                                </Link>
                                                            
                                                            </div>
                                                            <div className='col-6 p-1'>
                                                                <Button onClick={()=>añadirAlCarrito(store.id)} icon="pi pi-cart-plus" className="p-button-sm surface-900 hover:surface-700 border-none text-0 w-full transition-all transition-duration-300 text-center" tooltip="Añadir al Carrito" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Button>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>

                    <Dialog header="Regístrate o Inicia Sesión" visible={displayPosition} position={'right'} modal={true} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide()}
                        draggable={false} resizable={false}>
                        <p className="m-0">Que esperas para poder disfrutar de toda la tienda de Bersati.</p>
                        <br></br>
                        <p className="m-0 text-800">Si no tienes una cuenta <span className='text-900 font-semibold'>Regístrate</span>, si ya tienes una cuenta <span className='text-900 font-semibold'>Inicia Sesión</span></p>
                    </Dialog>

                </>
            )
        }

        

    </>
  )
}

export default StoreGP