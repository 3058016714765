/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';

import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { BlockUI } from 'primereact/blockui';
import { ToggleButton } from 'primereact/togglebutton';

import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';

import { SpeedDial } from 'primereact/speeddial';
 
import axios from "axios";

const NuevoProducto = () => {

    const [categorias, setCategorias] = useState(null);
    
    const [familias, setFamilias] = useState(null);
    const [familias1, setFamilias1] = useState([]);
    const [lineas, setLineas] = useState(null);
    const [lineas1, setLineas1] = useState(null);
    const [gp, setGP] = useState(null);
    const [gp1, setGP1] = useState(null);

    const [estadoFami, setEstadoFami] = useState(true);
    const [estadoLine, setEstadoLine] = useState(true);
    const [estadoGP, setEstadoGP] = useState(true);

    const [loading, setLoading] = useState(false);
    const [blockedPanel, setBlockedPanel] = useState(false);

    const [val, setVal] = useState([])

    const [imgArray, setImgArray] = useState([])
    const [imgP, setImgP] = useState(null)
    const [imgUP, setImgUP] = useState([])

    const [checked1, setChecked1] = useState(true);
    const [text1, setText1] = useState(null)

    const btnRefInput = useRef();
    const toast = useRef(null);

    
    

    useEffect(() => {

        setLoading(true)

        const generarData = async () =>{
            const resCate = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/categorias')
            const resFami = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')
            const resLine = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/lineas')
            const resGP = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/gp')
            
            setCategorias(resCate.data)
            setFamilias(resFami.data)
            setLineas(resLine.data)
            setGP(resGP.data)

        }

        generarData()

        setLoading(false)

    },[])

    const formik = useFormik({
        initialValues: {
            nombre: '',
            estado: '',
            codigo: '',
            imagenPrincipal: '',
            descripcionCorta: '',
            precio: null,
            rating: null,
            marca: '',
            descripcionLarga: '',
            categoria: '',
            familia: null,
            linea: null,
            grupoProductos: null,
            caracteristicas: '',
            img1:'',
            img2:'',
            img3:'',
            img4:'',
            imagenes: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.nombre) {
                errors.nombre = 'Nombre es Requerido.';
            }

            if (!data.codigo) {
                errors.codigo = 'Código es Requerido.';
            }

            if (!data.descripcionCorta) {
                errors.descripcionCorta = 'Descripción Corta es Requerido.';
            }

            if (!data.precio) {
                errors.precio = 'Precio es Requerido.';
            }

            if (!data.marca) {
                errors.marca = 'Marca es Requerido.';
            }
            //toast.current.show({ severity: 'error', summary: 'Datos Faltantes', detail: 'Campos vacios en varios campos.', life: 6000 });
            /*if (!data.rating) {
                errors.rating = 'Rating es Requerido.';
            }*/

            return errors;
        },
        onSubmit: (data) => {

            formik.values.caracteristicas = val;
            formik.values.imagenes = imgArray; 
            formik.values.imagenPrincipal = imgP;
            formik.values.estado = checked1;
            formik.values.descripcionLarga = text1;
            
            console.log(data)
            
            let errorcito = 0;

            for (let index = 0; index < formik.values.caracteristicas.length; index++) {
                if (formik.values.caracteristicas[index].caracteristica === null || formik.values.caracteristicas[index].caracteristica === '') {
                    
                    errorcito = errorcito + 1;
                    console.log('edas')       
                    toast.current.show({ severity: 'error', summary: 'Datos Faltantes', detail: 'Campos vacios en las características del producto.', life: 6000 });
                    break;      
                }
                if (formik.values.caracteristicas[index].detalle === null || formik.values.caracteristicas[index].detalle === '') {
                    toast.current.show({ severity: 'error', summary: 'Datos Faltantes', detail: 'Campos vacios en las características del producto.', life: 6000 });
                    errorcito = errorcito + 1;
                   
                }
                
            }
            
            if (errorcito > 0) {
                toast.current.show({ severity: 'error', summary: 'Error al Registrar', detail: 'Errores o faltantes en el formulario', life: 6000 });
                
            }else{
                if (formik.values.caracteristicas.length > 0) {
                    if (formik.values.imagenes.length > 0) {
                        procederSave(data);
                        setVal([])
                        setText1('')
                        formik.resetForm();
                    }else{
                    toast.current.show({ severity: 'error', summary: 'Imágenes Faltantes!', detail: 'Suba imágenes para el producto.', life: 6000 });
                    }
                }else{
                    toast.current.show({ severity: 'error', summary: 'Características Vacias', detail: 'El producto tiene que tener al menos 1 característica.', life: 6000 });
                }
            }

            
            //console.log(data)

            //procederSave(data)
            //formik.resetForm();
        }
    });


    const procederSave = async (data) => {
        const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/save/producto', data);

        //const result = await resSave.json();

        //console.log(resSave)

        if (resSave.status === 200) {
            toast.current.show({ severity: 'success', summary: 'Acción Realizada con Exito', detail: 'El producto fue registrado correctamente.', life: 4000 });
        }else{
            toast.current.show({ severity: 'error', summary: 'Acción NO Realizada', detail: 'El producto NO fue registrado.', life: 4000 });
        }

    }

    const onChangeVariable = (e) => {
        
        formik.values.categoria = e.value;
        console.log(formik.values.categoria)
    }
    
    useEffect(()=>{
       
        const respuesta = () =>{
            if (formik.values.categoria) {
                setEstadoFami(true)
                let varcito = []
                for (let index = 0; index < familias.length; index++) {
                    if (familias[index].categoria_id === formik.values.categoria) {
                        varcito.push(familias[index])
                    }else{
                        //familias1.splice(index, 1)
                    }
                }
                setFamilias1(varcito)

            }else{
                setEstadoFami(true)
            }
        }

        respuesta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formik.values.categoria])

    useEffect(()=>{
       
        const respuesta = () =>{
            if (formik.values.familia) {
                setEstadoLine(true)
                let varcito1 = []
                for (let index = 0; index < lineas.length; index++) {
                    if (lineas[index].familias_id === formik.values.familia) {
                        varcito1.push(lineas[index])
                    }else{
                        //familias1.splice(index, 1)
                    }
                }
                setLineas1(varcito1)

            }else{
                setEstadoLine(true)
            }
        }

        respuesta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formik.values.familia])

    useEffect(()=>{
       
        const respuesta = () =>{
            if (formik.values.linea) {
                setEstadoGP(true)
                let varcito2 = []
                for (let index = 0; index < gp.length; index++) {
                    if (gp[index].lineas_id === formik.values.linea) {
                        varcito2.push(gp[index])
                    }else{
                        //familias1.splice(index, 1)
                    }
                }
                setGP1(varcito2)

            }else{
                setEstadoGP(true)
            }
        }

        respuesta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formik.values.linea])

    //console.log(formik)


    const isFormFieldValid = (nombre) => !!(formik.touched[nombre] && formik.errors[nombre]);
    const getFormErrorMessage = (nombre) => {
        return isFormFieldValid(nombre) && <small className="p-error">{formik.errors[nombre]}</small>;
    };

    const handleAdd= () =>{
        //console.log('entra')
        let varcitos = {
            caracteristica : '',
            detalle : ''
        }
        const abc = [...val,varcitos]
        
        setVal(abc)

    }

    const handleChange= (onChangeValue, i) =>{
        const inputData = [...val]
        inputData[i].caracteristica = onChangeValue.target.value;
        setVal(inputData)
    }

    const handleChange1= (onChangeValue, i) =>{
        const inputData = [...val]
        inputData[i].detalle = onChangeValue.target.value;
        setVal(inputData)
    }

    const handleDelete= (i) =>{
        const deleteVal = [...val]
        deleteVal.splice(i, 1)
        setVal(deleteVal)
    }

    const limpieza = () =>{
        btnRefInput.current.clear();
        //console.log(btnRefInput)
    }

    const myUploader = async (event) => {
        // convert file to base64 encoded

        setBlockedPanel(true)
        const tam = event.files.length;
        if (tam > 4) {
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 4 imágenes.', life: 3000 });
        }else if (tam < 4) {
            toast.current.show({ severity: 'warn', summary: 'Muy Pocas Imágenes!', detail: 'Si o si ingrese 4 imágenes.', life: 3000 });
        }else{
            let varcitos = []
            for (let index = 0; index < event.files.length; index++) {
                const file = event.files[index];
                

                const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
                const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                    method: 'post',
                    body: data,

                })

                const fileImg = await res.json();
                

                if (index === 0) {
                    let itemus = {
                        imagenURL : '',
                    }
                    setImgP(fileImg.secure_url)  
                    itemus.imagenURL = fileImg.secure_url
                    varcitos.push(itemus)   
                    formik.values.img1 = fileImg.secure_url
                }else{
                    if (index === 1) {
                        let itemus = {
                            imagenURL : '',
                        }
                        itemus.imagenURL = fileImg.secure_url
                        varcitos.push(itemus) 
                        formik.values.img2 = fileImg.secure_url
                    }
                    if (index === 2) {
                        let itemus = {
                            imagenURL : '',
                        }
                        itemus.imagenURL = fileImg.secure_url
                        varcitos.push(itemus)
                        formik.values.img3 = fileImg.secure_url 
                    }
                    if (index === 3) {
                        let itemus = {
                            imagenURL : '',
                        }
                        itemus.imagenURL = fileImg.secure_url
                        varcitos.push(itemus) 
                        formik.values.img4 = fileImg.secure_url
                    }
                    
                    
                }
            }
            setImgArray(varcitos)

            limpieza()
            setBlockedPanel(false)
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágenes subidas al servidor', life: 3000 });
        }
        
        /*const data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'bersatimg')
        data.append('cloud_name', 'ddnbi0nfz')
    
        const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
            method: 'post',
            body: data,

        }) //blob:url
        //setImgURL(blob)
        const fileImg = await res.json();

        //console.log(fileImg);
        setImgURL(fileImg.secure_url)
        formik.values.image = fileImg.secure_url*/
    }

    //console.log(formik.values.img1)
    //console.log(imgP)

  return (
    <>
        <Toast ref={toast} />
        <div className='p-2 w-full overflow-scroll'>
            {
                loading ? (
                    <p>Cargando...</p>
                )
                :
                (

                    <>
                        <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
                            <div className="border-bottom-1 surface-border">
                                <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">Registro de Nuevo Producto</span>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} className="">
                            <div className='grid  p-fluid PX2 md:px-3 lg:px-5'>
                                
                                <div className='col-12 md:col-7 md:border-right-1 md:surface-border'>                 
                                    <div className="card">
                                        <div className='flex  align-items-center '>
                                            <h6 className="text-left text-color-secondary flex-grow-1 flex-shrink-1">*Ingresa los datos del producto (Obligatorio)</h6>
                                            <ToggleButton checked={checked1} onLabel='Visble' offLabel='No Visble' className='m-0 w-8rem h-3rem ' onChange={(e) => setChecked1(e.value)} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />
                                        </div>
                                        
                                        
                                            <div className='grid grid-nogutter'>
                                                <div className='col-12'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <InputText id="nombre" name="nombre" value={formik.values.nombre} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('nombre') } )} />
                                                            <label htmlFor="nombre" className={classNames({ 'p-error': isFormFieldValid('nombre') })}>Nombre Del Producto*</label>
                                                        </span>
                                                        {getFormErrorMessage('nombre')}
                                                    </div>
                                                </div>
                                                <div className='col-12 md:col-6'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <InputText id="codigo" name="codigo" value={formik.values.codigo} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('codigo') })} />
                                                            <label htmlFor="codigo" className={classNames({ 'p-error': isFormFieldValid('codigo') })}>Código Del Producto*</label>
                                                        </span>
                                                        {getFormErrorMessage('codigo')}
                                                    </div>
                                                </div>
                                                {/*<div className='col-6 md:col-3'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <InputNumber id="rating" name="rating" value={formik.values.rating} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={1}  className={classNames({ 'p-invalid': isFormFieldValid('rating') })} />
                                                            <label htmlFor="rating" className={classNames({ 'p-error': isFormFieldValid('rating') })}>Rating*</label>
                                                        </span>
                                                        {getFormErrorMessage('rating')}
                                                    </div>
                                                </div>*/}
                                                <div className='col-6 md:col-3'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <InputText id="marca" name="marca" value={formik.values.marca} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('marca') })} />
                                                            <label htmlFor="marca" className={classNames({ 'p-error': isFormFieldValid('marca') })}>Marca*</label>
                                                        </span>
                                                        {getFormErrorMessage('marca')}
                                                    </div>
                                                </div>
                                                <div className='col-6 md:col-3'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <InputNumber id="precio" name="precio" prefix='Bob.' value={formik.values.precio} onValueChange={formik.handleChange} mode="decimal" minFractionDigits={2} className={classNames({ 'p-invalid': isFormFieldValid('precio') })}  />
                                                            
                                                            <label htmlFor="precio" className={classNames({ 'p-error': isFormFieldValid('precio') })}>Precio*</label>                                          
                                                        </span>
                                                        {getFormErrorMessage('precio')}
                                                    </div>
                                                </div>

                                                <div className='col-12 md:col-6'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <Dropdown inputId="categoria" name="categoria" filter  optionValue="id" value={formik.values.categoria} optionLabel="nombreCategoria" options={categorias} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('categoria') })} />
                                                            <label htmlFor="categoria" className={classNames({ 'p-error': isFormFieldValid('categoria') })}>Categoría*</label>
                                                        </span>
                                                        {getFormErrorMessage('categoria')}
                                                    </div>
                                                </div>

                                                <div className='col-12 md:col-6'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <Dropdown inputId="familia" name="familia" filter disabled={estadoFami} optionValue="id" value={formik.values.familia} optionLabel="nombreFamilia" options={familias1} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('familia') })} />
                                                            <label htmlFor="familia" className={classNames({ 'p-error': isFormFieldValid('familia') })}>Familia*</label>
                                                        </span>
                                                        {getFormErrorMessage('familia')}
                                                    </div>
                                                </div>

                                                <div className='col-12 md:col-6'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <Dropdown inputId="linea" name="linea" filter disabled={estadoLine} optionValue="id" value={formik.values.linea} optionLabel="nombreLinea" options={lineas1} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('linea') })} />
                                                            <label htmlFor="linea" className={classNames({ 'p-error': isFormFieldValid('linea') })}>Linea*</label>
                                                        </span>
                                                        {getFormErrorMessage('linea')}
                                                    </div>
                                                </div>

                                                <div className='col-12 md:col-6'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <Dropdown inputId="grupoProductos" name="grupoProductos" filter disabled={estadoGP} optionValue="id" value={formik.values.grupoProductos} optionLabel="nombreGrupoProductos" options={gp1} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('grupoProductos') })} />
                                                            <label htmlFor="grupoProductos" className={classNames({ 'p-error': isFormFieldValid('grupoProductos') })}>Grupo de Productos*</label>
                                                        </span>
                                                        {getFormErrorMessage('grupoProductos')}
                                                    </div>
                                                </div>

                                                <div className='col-12 md:col-12'>
                                                    <div className="field pt-3">
                                                        <span className="p-float-label p-1">
                                                            <InputTextarea autoResize rows={3} cols={30}  id="descripcionCorta" name="descripcionCorta" value={formik.values.descripcionCorta} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('descripcionCorta') })} />
                                                            <label htmlFor="descripcionCorta" className={classNames({ 'p-error': isFormFieldValid('descripcionCorta') })}>Descripción Corta del Producto*</label>
                                                        </span>
                                                        {getFormErrorMessage('descripcionCorta')}
                                                    </div>
                                                </div>
                                                <div className='col-12 md:col-12'>
                                                    <div className="field pt-3">
                                                        <span className=" p-1">
                                                            <label htmlFor="descripcionLarga" className={classNames({ 'p-error': isFormFieldValid('descripcionLarga')})}>Descripción Larga del Producto*</label>
                                                            <Editor style={{ height: '150px' }} id="descripcionLarga" name="descripcionLarga" value={text1} onTextChange={(e) => setText1(e.htmlValue)} className='mt-2' />
                                                        </span>
                                                        {getFormErrorMessage('descripcionLarga')}
                                                    </div>
                                                </div>
                                                    <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                                                    <div className='col-12 md:col-12'>
                                                            <div className="field pt-3">
                                                                <label>Subida de Imágenes*</label>
                                                                <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploader}  maxFileSize={1000000}
                                                                        emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />
                                                            </div>
                                                        </div>
                                                        <div className='col-12 md:col-12'>
                                                            <div className="field">
                                                                <h4 className='text-color'>URL's de las Imágenes</h4>
                                                                <InputText id="img1" name="img1" value={formik.values.img1} onChange={formik.handleChange} disabled className='' />
                                                                <InputText id="img2" name="img2" value={formik.values.img2} onChange={formik.handleChange} disabled className='mt-2' />
                                                                <InputText id="img3" name="img3" value={formik.values.img3} onChange={formik.handleChange} disabled className='mt-2' />
                                                                <InputText id="img4" name="img4" value={formik.values.img4} onChange={formik.handleChange} disabled className='mt-2' />
                                                            </div>
                                                        </div>
                                                    </BlockUI>
                                            </div>
                                    
                                    </div>        
                                </div>

                                <div className='col-12 md:col-5'>
                                    <h2>Características del Producto</h2>
                                    <Button label='Añadir' type="button" className='p-button-sm p-1 my-3' onClick={handleAdd} />
                                    <div className='grid'>
                                        { 
                                            val.map((data, i)=>(
                                                <div key={i} className='col-12 '>
                                                    <div className='grid' >
                                                        <div  className='col-5'>
                                                            <InputText value={data.caracteristica} onChange={e=>handleChange(e,i)} className='' />
                                                        </div>

                                                        <div  className='col-5'>
                                                            <InputText value={data.detalle} onChange={e=>handleChange1(e,i)} className='ml-1 ' />
                                                        </div>
                                                        <div  className='col-2'>
                                                            <Button type="button" label='X' className='p-button-sm m-1 p-1' onClick={() => handleDelete(i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) ) 
                                        }
                                    </div>
                                   
                                </div>
                                
                            </div>
                            <div className='p-2 mt-4 w-full flex align-items-center justify-content-center '>
                                <Button type="submit" className="p-button-raised p-button-success px-6" label='Guardar Producto'></Button>
                            </div>
                        </form>
                    </>
                )
            }
            
        </div>
    </>
  )
}

export default NuevoProducto