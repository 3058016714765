/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { FileUpload } from 'primereact/fileupload';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { PickList } from 'primereact/picklist';


import axios from "axios";


import { Fab, Action } from 'react-tiny-fab';

const EjemploComponent = (data) => {
    //console.log(data)
    //const [item, setItem] = useState(null)
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        //console.log('????')
        //setLoading(true)
            const generarData = async () =>{
                //console.log('????')
                const resData = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')
                setSource(resData.data)
            }
        generarData()
        //console.log('????')
    
    }, []);

    const formik = useFormik({
        initialValues: {
            eleccion_editor_id: '',
            productos: '',
            estado: 1,
  
        },onSubmit: (datita) => {
            if (target.length > 0) {
                datita.eleccion_editor_id = data.selection.id;
                datita.productos = target;

                guardarData(datita)
            }else{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Error al guardar los datos....', life: 3000 });
            }
            
        }
    })
    //setTarget()
    //console.log(target)

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }


    const guardarData = async (data) =>{
        setLoadingBtn(true)
            const guardar = async (data) =>{
                const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/eco/editorschprodstore`, data);
                //console.log(resSave)
                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Productos añadidos con éxito.', life: 3000 });
                    setLoadingBtn(false)
                    setTarget([])
                }else if(resSave.status === 202){
                    toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Productos NO añadidos.', life: 3000 });
                    setLoadingBtn(false)
                }

            }

        guardar(data)
        setLoadingBtn(false)
        //formik.resetForm()
      }


    const itemTemplate = (item) => {
        
        return (
            
            <div className="product-item">
                <Toast ref={toast} />
                <div className="image-container">
                    <img src={`images/product/${item.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
                </div>
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.nombre}</h5>
                    <i className="pi pi-tag product-category-icon"></i>
                    <span className="product-category" onError={(e) => e.target.label='...'}>{item.codigo}</span>
                    <div>
                        <span className="product-category">{item.nombreCategoria} - {item.nombreFamilia} - {item.nombreLinea} - {item.nombreGrupoProductos}</span>
                    </div>
                </div>
                <div className="product-list-action">
                    <h6 className="mb-2 marca">{item.marca}</h6>
                    {
                        item.estado ? (
                            <span className={`product-badge status-instock`}>ACTIVO</span>
                        )
                        :
                        (
                            <span className={`product-badge status-outofstock`}>OCULTO</span>
                        )
                    }
                    
                </div>
            </div>
        );
    }

  return (
    <div className='card mt-4'>
        <h3 className='px-2 mb-6 md:px-4 lg:px-6'>*Seleccione los productos</h3>
        <div className="picklist-demo ">
            <div className="card ">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <BlockUI blocked={false}  template={<i className="pi pi-lock text-white" style={{'fontSize': '5rem'}} />}>
                        <PickList source={source} target={target}  itemTemplate={itemTemplate} sourceHeader="Productos." targetHeader="Seleccionados."
                            sourceStyle={{ minHeight: '500px' }} targetStyle={{ minHeight: '500px' }} onChange={onChange}
                            filterBy={'codigo'}   sourceFilterPlaceholder="Buscar por Código" targetFilterPlaceholder="Buscar por Código" />
                    </BlockUI>
                    
                        {
                            target.length > 0 ? (
                                <Fab
                                    style={{bottom: '10px', right: '-8px', padding:'0'}}
                                    icon = {<i className="pi pi-plus"></i>}
                                    alwaysShowTitle={true}
                                    >
                                    <Action
                                        text="Guardar!"
                                        style={{background: '#0f346c'}}
                                        onClick={formik.handleSubmit}
                                        disabled={loadingBtn}
                                    >
                                        <i className="pi pi-save"></i>
                                    </Action>
                                </Fab>
                            )
                            :
                            (
                                ''
                            )
                        }

                </form>
            </div>
        </div>
    </div>
  )
}

export default EjemploComponent