/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";



//CONSTANTES

const dataInicialLogin = {
    userInfo : [],
    loadingLogin : '',
    error: '',
};

const dataInicialToken = {
    userToken : [],
    loadingToken : true,
    error: '',
};


//TYPES

const OBTENER_USER_EXITO = 'OBTENER_USER_EXITO'
const OBTENER_USER_REQUEST = 'OBTENER_USER_REQUEST'
const OBTENER_USER_ERROR = 'OBTENER_USER_ERROR'
const USER_LOGOUT = 'USER_LOGOUT'

const OBTENER_TOKEN_EXITO = 'OBTENER_TOKEN_EXITO'
const OBTENER_TOKEN_REQUEST = 'OBTENER_TOKEN_REQUEST'
const OBTENER_TOKEN_ERROR = 'OBTENER_TOKEN_ERROR'




//REDUCERS

export function userLoginReducer (state = dataInicialLogin, action){
    switch (action.type) {
        case OBTENER_USER_REQUEST:
            return { loadingLogin: true };
        case OBTENER_USER_EXITO:
            return {...state, userInfo: action.payload, loadingLogin: false};
        case OBTENER_USER_ERROR:
            return {error: action.payload, loadingLogin: false};    
        case USER_LOGOUT:
            return {};        
        default:
            return state
    }
}

export function userTokenReducer (state = dataInicialToken, action){
    switch (action.type) {
        case OBTENER_TOKEN_REQUEST:
            return { loadingToken: true };
        case OBTENER_TOKEN_EXITO:
            return {...state, userToken: action.payload, loadingToken: false};
        case OBTENER_TOKEN_ERROR:
            return {error: action.payload, loadingToken: false};    
        case USER_LOGOUT:
            return {};        
        default:
            return state
    }
}


//ACCIONES

export const login = (email, password) => async (dispatch) => {

    try {
        dispatch({type:OBTENER_USER_REQUEST})

        const config = {
            headers:{
                'Content-Type':'application/json',
            },
        }

        const res = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/bersati/login', {email, password}, config)
        //console.log(res)
        localStorage.setItem('_uid', btoa(res.data.token))
        //localStorage.setItem('_uid', res.data.token)
        dispatch({
            type: OBTENER_USER_EXITO,
            payload: res.data
        })
        
    } catch (error) {
        dispatch({
            type: OBTENER_USER_ERROR,
            payload:
                error.response && error.response.data.mensaje
                    ? error.response.data.mensaje
                    : error.message
        })
        //console.log(error.response.data.mensaje)
    }
}

export const verificarToken = (token) => async (dispatch) => {
    try {
        dispatch({type:OBTENER_TOKEN_REQUEST})

        const config = {
            headers:{
                'Content-Type':'application/json',
            },
        }

        const res = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/token', {token}, config).catch((e)=>{console.log(e)})
        //console.log(res)
        dispatch({
            type: OBTENER_TOKEN_EXITO,
            payload: res.data
        })
        
    } catch (error) {
        dispatch({
            type: OBTENER_TOKEN_ERROR,
            payload:
                error.response && error.response.data.mensaje
                    ? error.response.data
                    : error.message
        })
        //console.log(error.response.data.mensaje)
    }
}