import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
 
import {navbarReducer, navbarReducerC, navbarReducerGP, navbarReducerL} from './patos/navbarDuck'
import { productosIndexReducer } from './patos/storeIndexDuck'
import { categoriasStoreReducer, CFlGPReducer, familiasStoreReducer, LineasStoreReducer, GPStoreReducer, categoriasStoreProdReducer, familiasStoreProdReducer, LineasStoreProdReducer } from './patos/storeCFLGP'
import { categoriaSaveeReducer } from './patos/CrudCategoriaDuck'
import { userLoginReducer, userTokenReducer } from './patos/userDuck'
import { carouselReducer, eleccionEditorReducer, promocionReducer } from './patos/EcoDuck'
import { itemDetailsProdReducer } from './patos/ItemDetails'
import { cartIndexFiveReducer, cartIndexReducer, cartSaveReducer, ConfirmationReducer } from './patos/CartDuck'
import { dataConsultaReducer, departamentosReducer, tipoEnvioReducer } from './patos/FormDuck'
import { comprasUserIndexReducer, ventasPC2Reducer, ventasPC3Reducer, ventasPCReducer } from './patos/ComprasUserDuck'
import { despachoDDIndexReducer, despachoDetalleReducer, despachoIndexReducer1, despachoIndexReducer2, despachoIndexReducer3 } from './patos/DespachosDuck'
import { CrossIndexReducer, CrossSaveReducer, CrossShowReducer } from './patos/CrossUpSelling'
 
const rootReducer = combineReducers({
    navbarLista: navbarReducer,
    navbarListaL: navbarReducerL,
    navbarListaC : navbarReducerC,
    navbarListaGP : navbarReducerGP,
    
    productosListaIndex : productosIndexReducer,

    listcflgp : CFlGPReducer,
    storeCategorias : categoriasStoreReducer,
    storeCategoriasProd : categoriasStoreProdReducer,

    storeFamilias : familiasStoreReducer,
    storeFamiliasProd : familiasStoreProdReducer,

    storeLineas: LineasStoreReducer,
    storeLineasProd: LineasStoreProdReducer,

    storeGP : GPStoreReducer,


    //Guardar
    categoriaSave : categoriaSaveeReducer,

    //Login
    userLogin : userLoginReducer,
    userToken : userTokenReducer,


    //Ecommerce
    ecoCarousel : carouselReducer,
    ecoEEP : eleccionEditorReducer,
    ecoIDP : itemDetailsProdReducer,
    ecoPromo : promocionReducer,
    
    //CART
    cartIndex: cartIndexReducer,
    cartSave : cartSaveReducer,
    cartFive : cartIndexFiveReducer,
    confirmation : ConfirmationReducer,

    //COMPRAS USER
    comprasUser : comprasUserIndexReducer,
    ventasBPC : ventasPCReducer,
    ventasBPC2 : ventasPC2Reducer,
    ventasBPC3 : ventasPC3Reducer,
    
    //Forms
    storeTipoEnvio : tipoEnvioReducer,
    storeDepartamentos : departamentosReducer,
    storeDataConsulta : dataConsultaReducer,

    //Despachos
    storeDespacho1 : despachoIndexReducer1,
    storeDespacho2 : despachoIndexReducer2,
    storeDespacho3 : despachoIndexReducer3,
    storeDespachoDD : despachoDDIndexReducer,
    storeDespachoDetalle : despachoDetalleReducer,


    //Cros y Up Selling
    storeCrossIndex : CrossIndexReducer,
    storeCrossShow : CrossShowReducer,
    storeCrossSave : CrossSaveReducer

})
 
export default function generateStore() {
    const store = createStore( rootReducer, composeWithDevTools( applyMiddleware(thunk) ) )
    return store
}