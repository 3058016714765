/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { FileUpload } from 'primereact/fileupload';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';


import axios from "axios";

import './Carousel.css';

const Carousel = () => {

    const [products4, setProducts4] = useState(null);
    const [loading, setLoading] = useState(true);
    const [productDialog, setProductDialog] = useState(false);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [carousel, setCarousel] = useState('');
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);

    const btnRefInput = useRef();
    const btnRefInput1 = useRef();
    const toast = useRef(null);

    const estadoItems = [
        {label: 'Activo', value: 1},
        {label: 'Oculto', value: 0},
    ];

    const paymentOptions = [
        {name: 'Activo', estado: 1},
        {name: 'Oculto', estado: 0},
      ];

      const formik = useFormik({
        initialValues: {
            imgUrl: '',
            imgUrlRespon: '',
            link: '',
            estado:'',
            error: 0
  
        },onSubmit: (data) => {
            //console.log(data)
            data.error = 0;
            if (data.imgUrl === '' || data.imgUrl === null || data.imgUrlRespon === '' || data.imgUrlRespon === null) {
                data.error = 1;
            }else{
                guardarData(data)
                formik.values.imgUrl = ''
                formik.values.imgUrlRespon = ''
                formik.resetForm();  
            }

            if (data.error > 1) {
                toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Error al guardar los datos....', life: 3000 });
            }
        }
      })

      const hideDialog = () => {
        formik.resetForm();
        formik.values.imgUrl = ''
        formik.values.imgUrlRespon = ''
        setProductDialog(false);
      }
    
      useEffect(() => {
        setLoading(true)
            const generarData = async () =>{
                
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/carouselindex')
        
                setProducts4(res.data)
                setLoading(false)
            }
        generarData()
        
      
      }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
      const generarDatita = async () =>{
        setLoading(true)

            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/carouselindex')
                
            setProducts4(res.data)
            formik.resetForm();
            formik.values.imgUrl = ''
            formik.values.imgUrlRespon = ''
            
        setLoading(false)
        
      }
    
      const guardarData = async (data) =>{
        setLoadingBtn(true)

            const guardar = async (data) =>{
                const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/eco/carouselstore`, data);


                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Imágen guardada con éxito.', life: 3000 });
                    
                    formik.resetForm();
                    formik.values.imgUrl = ''
                    formik.values.imgUrlRespon = ''
                    setLoadingBtn(false)
                    hideDialog()
                }else if(resSave.status === 202){
                    toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Imágen NO guardada.', life: 3000 });
                    setLoadingBtn(false)
                        formik.values.imgUrl= ''
                        formik.values.imgUrlRespon = ''
                }

                generarDatita()
            }

        guardar(data)
        
      }
    
        const EditEstado = async (val, rowData) =>{
    
            let id = rowData.id;
            let data = {
            estado : val
            }
            const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/eco/carouselupdate/${id}`, data);
        
            if (resSave.status === 200) {      
            toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Estado editado con éxito.', life: 3000 });
        
            }else {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Estado NO editado.', life: 3000 });
            }
        
        
            generarDatita()
    
        }
    
        const estadoBodyTemplate = (rowData) => {
            return <SelectButton className='z-0' value={rowData.estado} optionLabel="name" optionValue="estado" unselectable onChange={(e) => EditEstado(e.value, rowData)} options={paymentOptions}  />;
        }
        const imageBodyTemplate = (rowData) => {
            return <img src={`${rowData.imagen}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.imagen} className="product-image" />
        }

        const imageBodyTemplateRespon = (rowData) => {
            return <img src={`${rowData.imgresponsive}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.imgresponsive} className="product-image" />
        }

      

      const openDialog = () => {
        formik.resetForm();
            formik.values.imgUrl = '';
            formik.values.imgUrlRespon = '';
        setProductDialog(true);
      }


      const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
              
                <Button label="Nuevo Carousel" onClick={openDialog} icon="pi pi-plus" className="p-button-success mr-2" />

            </React.Fragment>
        )
      }
      const limpieza = () =>{
        btnRefInput.current.clear();
        //console.log(btnRefInput)
        }

        const limpieza1 = () =>{
            btnRefInput1.current.clear();
            //console.log(btnRefInput)
            }

      const myUploader = async (event) => {


        setBlockedPanel(true)
        const tam = event.files.length;

        //console.log(tam)
        if (tam > 1) {
            //console.log('entra,,,,,')
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 1 imágen.', life: 3000 });
        }else if (tam === 0) {
            toast.current.show({ severity: 'warn', summary: 'Sin Imágenes!', detail: 'Si o si ingrese 1 imágen.', life: 3000 });
        }else{
            const file = event.files[0];

            const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
            const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                method: 'post',
                body: data,

            })

            const fileImg = await res.json();

            formik.values.imgUrl = fileImg.secure_url
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágen subidas al servidor', life: 3000 });
            limpieza()
        }

        
        setBlockedPanel(false)
        
      }

      const myUploaderRespon = async (event) => {


        setBlockedPanel(true)
        const tam = event.files.length;

        //console.log(tam)
        if (tam > 1) {
            //console.log('entra,,,,,')
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 1 imágen.', life: 3000 });
        }else if (tam === 0) {
            toast.current.show({ severity: 'warn', summary: 'Sin Imágenes!', detail: 'Si o si ingrese 1 imágen.', life: 3000 });
        }else{
            const file = event.files[0];

            const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
            const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                method: 'post',
                body: data,

            })

            const fileImg = await res.json();

            formik.values.imgUrlRespon = fileImg.secure_url
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágen subidas al servidor', life: 3000 });
            limpieza1()
        }

        
        setBlockedPanel(false)
        
      }

      const deleteProduct = async () => {
        setLoadingBtn(true)
    
        if (carousel.id) {
          //console.log(product)
          const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/eco/carouseldelete/${carousel.id}`)
    
          //console.log(resSave)
          if (resSave.status === 200) {      
              toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Item eliminado con éxito.', life: 3000 });
              setCarousel('');
              setLoadingBtn(false)
              setDeleteProductDialog(false);
    
          }else if (resSave.status === 202) {
              setDeleteProductDialog(false);
              setLoadingBtn(false)
              setCarousel('');
              toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Item NO eliminado.', life: 3000 });
          }
          generarDatita()
        }
      }


      const confirmDeleteProduct = (data) => {
        setCarousel(data);
        setDeleteProductDialog(true);
        
      }

      const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex'>
                    <Button icon="pi pi-trash" placeholder="Left" tooltip="Eliminar Carrousel" tooltipOptions={{position: 'left'}} onClick={() => confirmDeleteProduct(rowData)} className="p-button-rounded p-button-danger ml-1"  />
                </div>
            </React.Fragment>
        );
      }

      const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setCarousel('')
      }
      const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
      );


  return (
    <>
        <div className='w-full overflow-scroll speeddial-linear-demo '>
            <Toast ref={toast} />
            <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">CARRUSEL ECOMMERCE</span>
                </div>
                
            </div>

            <div className="card w-full p-fluid datatable-crud-demo px-2 md:px-3 lg:px-5" >
                <Toolbar className="mb-1 text-sm p-2" left={leftToolbarTemplate}></Toolbar>
                <DataTable value={products4} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading} filterDisplay="row" emptyMessage='Sin datos...'
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items" size="small"  showGridlines 
                editMode="cell"  className="editable-cells-table overflow-scroll p-2 z-1" responsiveLayout="scroll" scrollHeight="flex" scrollable  >

                    <Column header='Imagen' className='flex justify-content-center' filter sortable style={{ minWidth: '12rem' }} body={imageBodyTemplate} />
                    <Column header='Imagen Responsive' className='flex justify-content-center' filter sortable style={{ minWidth: '12rem' }} body={imageBodyTemplateRespon} />
                    <Column header='Estado' className='flex justify-content-center' filter sortable style={{ minWidth: '16rem' }} body={estadoBodyTemplate} />
                    <Column field='link' header='Link Bersati' className=' overflow-scroll' filter sortable style={{ minWidth: '13rem' }}  />
                    <Column header='Acciones' body={actionBodyTemplate} exportable={false} style={{ minWidth: '13rem' }}></Column>
                </DataTable>


                <Dialog visible={productDialog} style={{ width: '450px' }} header="Nuevo Carousel" modal className="p-fluid"  onHide={hideDialog}>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                            <div className="field">
                                <label>Subida de Imágenes Vista Normal</label>
                                <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploader}  maxFileSize={100000000}
                                    emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                            </div>
                            <div className="field">
                                <label>Subida de Imágenes Vista Responsive</label>
                                <FileUpload className='mt-2' ref={btnRefInput1} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploaderRespon}  maxFileSize={100000000}
                                    emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                            </div>
                            <div className="field">
                                <label htmlFor="imgUrl">URL de la Imágen Normal</label>
                                <InputText id="imgUrl" value={formik.values.imgUrl} onChange={formik.handleChange}  required  />               
                            </div>
                            <div className="field">
                                <label htmlFor="imgUrlRespon">URL de la Imágen Responsive</label>
                                <InputText id="imgUrlRespon" value={formik.values.imgUrlRespon} onChange={formik.handleChange}  required  />               
                            </div>
                            <div className="field">
                                <label htmlFor="link">Link para la Imágen</label>
                                <InputText id="link" value={formik.values.link} onChange={formik.handleChange} />               
                            </div>
                            <div className="field">
                                <label htmlFor="estado">Estado</label>
                                <Dropdown inputId="estado" name="estado" 
                                optionValue="value" value={formik.values.estado} optionLabel="label" 
                                options={estadoItems} onChange={formik.handleChange} />              
                            </div>
                            {
                                formik.values.imgUrl && formik.values.imgUrlRespon ? (
                                    <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                                )
                                :
                                (
                                    ''
                                )
                            }
                        </BlockUI> 

                    </form>
                </Dialog>

                <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                        <span>Estas seguro de eliminar ?</span>
                    </div>
                </Dialog>
            </div>
        </div>  
    </>
  )
}

export default Carousel