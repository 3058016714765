// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDi7k6fqMP_TFj607Av2DN4m-l0GRBSM-M",
  authDomain: "bersati-fire.firebaseapp.com",
  projectId: "bersati-fire",
  storageBucket: "bersati-fire.appspot.com",
  messagingSenderId: "949555232807",
  appId: "1:949555232807:web:984d6ffb6ecd80f49dce02"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth };