/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";

//CONSTANTES

const dataInicialC = {
    arrayC : [],
    loadingC : '',
};


//TYPES

const OBTENER_CATEGORIA_EXITO = 'OBTENER_CATEGORIA_EXITO'
const OBTENER_CATEGORIA_REQUEST = 'OBTENER_CATEGORIA_REQUEST'


//REDUCERS

export function categoriaSaveeReducer (state = {dataInicialC}, action){
    switch (action.type) {
        case OBTENER_CATEGORIA_REQUEST:
            return { loadingC: true, arrayC:[] };
        case OBTENER_CATEGORIA_EXITO:
            return {...state, arrayC: action.payload, loadingC: false};       
        default:
            return state
    }
}


//ACCIONES

export const categoriaStoreAccion = (categoria) => async (dispatch, getState) => {
    
    

    try {
        dispatch({type:OBTENER_CATEGORIA_REQUEST})
        const res = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/save/categoria', categoria)
        //console.log(res)
        dispatch({
            type: OBTENER_CATEGORIA_EXITO,
            payload: res.data
        })
        
    } catch (error) {
        console.log(error)
    }
}