/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';

import { classNames } from 'primereact/utils';

import { login } from '../../../Redux/patos/userDuck';

import Bersati3 from '../../../assets/img/Bersati3.png'

const LoginAdmin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const btnPass = useRef();

    const userLogin = useSelector((store) => store.userLogin);
    //const {error, loadingLogin, userInfo} = userLogin;
    
    const [header, setHeader] = useState(null);
    //const header = <h6>Mayúsculas Activado</h6>;
    const formik = useFormik({
        initialValues:{

            email: '',
            password: ''

        },
        onSubmit: (data)=>{
            //console.log(data)
            dispatch(login(data.email, data.password))
        }

    })

    useEffect(() => {
        let header = ''
        const handleClick = event => {
          console.log('Button clicked');
        };
        const isCapsLockOn = event => {
            const caps = event.getModifierState && event.getModifierState('CapsLock');

            caps ? setHeader('Mayúsculas Activado') : setHeader(null)
            //console.log('¿Está el bloqueo de mayúsculas activado?', caps);
            
        };
    
        const element = btnPass.current;
    
        element.addEventListener('keydown', isCapsLockOn);
    
        return () => {
          element.removeEventListener('keydown', isCapsLockOn);
        };
      }, []);

    useEffect(() =>{
        if (userLogin) {
            if (userLogin && localStorage.getItem('_uid')) {
                formik.resetForm()
                //console.log('entra......')
                navigate('/protected/admin');
                //console.log(userLogin)
                
             }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userLogin])
    

    //console.log(btnPass)

  return (
    <>
        <div className='min-h-screen min-w-screen flex flex-1 justify-content-center align-items-center'>

            <div className=' flex justify-content-center align-items-center w-full'>
                <div className="surface-card p-4 shadow-2 w-11 border-round w-full lg:w-4">
                    <div className="text-center mb-5">
                        <img lang='Logito' src={Bersati3} alt="Image" height="50" className="mb-3" />
                        <div className="text-900 text-3xl font-medium mb-3">Bienvenido!</div>
                        <span className="text-600 font-medium line-height-3">Volver al Ecommerce?</span>
                        <Link to={'/'} className="font-medium no-underline ml-2 text-gray-500 hover:text-gray-800 cursor-pointer">Pinche Aqui!</Link>
                    </div>

                    <div>
                        {userLogin.error && userLogin.error}
                        <form onSubmit={formik.handleSubmit} className="">     
                            <div className="field">     
                                <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                                <InputText id="email" value={formik.values.email} onChange={formik.handleChange} type="text" className="w-full " />
                            </div>

                            <div className="field">
                                <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                                <InputText  ref={btnPass} id="password" value={formik.values.password} className={classNames('w-full',{'p-invalid': header })}onChange={formik.handleChange} type="password" />
                                <small className="p-info">{header}</small>
                            </div>
                            <div className="flex align-items-center justify-content-between mb-6">
                                
                                <a className="font-medium no-underline ml-2 text-gray-500 hover:text-gray-800 text-right cursor-pointer">Olvido su Contraseña?</a>
                            </div>

                            <Button  loading={userLogin.loadingLogin} label="Iniciar Sesión" type='submit' icon="pi pi-user" className="w-full surface-900 hover:surface-700 border-none text-0"></Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LoginAdmin