/* eslint-disable default-case */
/* eslint-disable no-unused-vars */


import axios from "axios";


//CONSTANTES

const dataInicialCross = {
    arrayCross : [],
    loadingCross : true,
    errorCross: ''
};

const dataInicialCrossShow = {
    arrayCrossShow : [],
    loadingCrossShow : true,
    errorCrossShow: ''
};

const dataInicialCrosSave = {
    arrayCrosSave : [],
    loadingCrosSave : true,
    errorCrosSave: ''
};



//TYPES

const OBTENER_CROSSELLING_EXITO = 'OBTENER_CROSSELLING_EXITO'
const OBTENER_CROSSELLING_REQUEST = 'OBTENER_CROSSELLING_REQUEST'
const OBTENER_CROSSELLING_ERROR = 'OBTENER_CROSSELLING_ERROR'

const OBTENER_CROSSELLINGSHOW_EXITO = 'OBTENER_CROSSELLINGSHOW_EXITO'
const OBTENER_CROSSELLINGSHOW_REQUEST = 'OBTENER_CROSSELLINGSHOW_REQUEST'
const OBTENER_CROSSELLINGSHOW_ERROR = 'OBTENER_CROSSELLINGSHOW_ERROR'

const OBTENER_CROSSELLINGSAVE_EXITO = 'OBTENER_CROSSELLINGSAVE_EXITO'
const OBTENER_CROSSELLINGSAVE_REQUEST = 'OBTENER_CROSSELLINGSAVE_REQUEST'
const OBTENER_CROSSELLINGSAVE_ERROR = 'OBTENER_CROSSELLINGSAVE_ERROR'


//REDUCERS

export function CrossIndexReducer (state = dataInicialCross, action){
    switch (action.type) {
        case OBTENER_CROSSELLING_REQUEST:
            return { loadingCross: true, arrayCross:[] };
        case OBTENER_CROSSELLING_EXITO:
            return {...state, arrayCross: action.payload, loadingCross: false}; 
        case OBTENER_CROSSELLING_ERROR:
            return {arrayCross: action.payload, loadingCross: false};       
        default:
            return state
    }
}

export function CrossShowReducer (state = dataInicialCrossShow, action){
    switch (action.type) {
        case OBTENER_CROSSELLINGSHOW_REQUEST:
            return { loadingCrossShow: true, arrayCrossShow:[] };
        case OBTENER_CROSSELLINGSHOW_EXITO:
            return {...state, arrayCrossShow: action.payload, loadingCrossShow: false}; 
        case OBTENER_CROSSELLINGSHOW_ERROR:
            return {arrayCrossShow: action.payload, loadingCrossShow: false};       
        default:
            return state
    }
}

export function CrossSaveReducer (state = dataInicialCrosSave, action){
    switch (action.type) {
        case OBTENER_CROSSELLINGSAVE_REQUEST:
            return { loadingCrosSave: true, arrayCrosSave:[] };
        case OBTENER_CROSSELLINGSAVE_EXITO:
            return {...state, arrayCrosSave: action.payload, loadingCrosSave: false}; 
        case OBTENER_CROSSELLINGSAVE_ERROR:
            return {errorCrosSave: action.payload, loadingCrosSave: false};       
        default:
            return state
    }
}


//ACCIONES

export const CrossIndexAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CROSSELLING_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/crosprodindex')
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_CROSSELLING_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        dispatch({
            type: OBTENER_CROSSELLING_ERROR,
            payload: []
        })
        console.log(error)
    }
}

export const CrossShowAccion = (id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CROSSELLINGSHOW_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/crosprodshow/'+id)
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_CROSSELLINGSHOW_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        dispatch({
            type: OBTENER_CROSSELLINGSHOW_ERROR,
            payload: []
        })
        console.log(error)
    }
}

export const CrossSaveAccion = (data) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CROSSELLINGSAVE_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)
        //console.log(data)

        const res = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/crosprodsave', data)
        
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            
            dispatch({
                type: OBTENER_CROSSELLINGSAVE_EXITO,
                payload: res
            })
        }
        
    } catch (error) {
        dispatch({
            type: OBTENER_CROSSELLINGSAVE_ERROR,
            payload: []
        })
        console.log(error)
    }
}