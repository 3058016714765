/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, {useContext, useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import Cargando from '../comps/Cargando';
import { Link, useLocation, useNavigate } from 'react-router-dom'

import imgCN from '../../../assets/img/nuestras_categotias.png'

import { obtenerNavbarAccionC } from '../../../Redux/patos/navbarDuck';

import { UserContext } from '../../../context/UserProvider';


import './Stylo.css'


const CategoriasNew = () =>{

    const dispatch = useDispatch();


    useEffect(() => {
        const generarData = () =>{

            dispatch(obtenerNavbarAccionC())
        }
        generarData()
    },[dispatch])

    const navbarC = useSelector(store => store.navbarListaC)

    return(

        <>
            <div className='flex relative p-6 overflow-hidden bg-no-repeat bg-cover bg-bottom lg:bg-center h-45rem align-content-center' style={{backgroundImage: `url(${imgCN})`}}>
                    
                    <div className='flex justify-content-end align-content-end text-center w-full bottom-0 '>
                    </div>
                    
            </div>
            <div className='surface-section  py-8 z-2'>

                
                <div className='grid '>
                    {
                        navbarC.loadingC ? (
                            
                                <div className='col-12'> <Cargando /> </div>
                            
                            
                        )
                        :
                        (
                            navbarC.arrayC.map((navcate, index) => (
                                
                                    index === 0 || index % 2 === 0 ? 
                                        (
                                        <div key={index} className='col-12 md:col-6 mb-8'>
                                            <div className='relative'>
                                                <div className=' box '>
                                                    <div className='movede grid flex flex-wrap align-content-center align-items-center justify-content-end z-1'>
                                                        <div className='p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                                            <h1 className='mb-0 font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                            <h2 className='mt-0 font-bold text-6xl'>BERSATI</h2>
                                                            <h2 className='text-4xl md:text-right px-6 md:pl-4'>{navcate.descripcion}</h2>
                                                        </div>
                                                        <div id='imagenPrueba' className='p-0 col-12 md:col-7 flex justify-content-center md:justify-content-start align-items-center cursor-pointer z-1'>
                                                            <Link to={`/productos/${navcate.bCategoria}`} className="no-underline">
                                                                <img src={`${navcate.image}`} alt="stad1" className="w-20rem h-30rem border-round "/>
                                                            </Link>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} */}
                                                    <div id='activador'  className='fondoCate absolute h-30rem p-0 m-0 w-10 z-0'>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        )
                                    :
                                        (
                                        <div key={index} className='col-12 md:col-6 '>
                                            <div className='relative'>
                                                <div className='box-izq '>
                                                    <div className='moveiz grid flex align-content-center align-items-center justify-content-start  z-1 '>

                                                        <div id='imagenPrueba' className='hidden md:flex p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                                            <Link to={`/productos/${navcate.bCategoria}`} className="no-underline">
                                                                <img  src={`${navcate.image}`} alt="stad1" className="hidden md:flex imgE  w-20rem h-30rem border-round "/>
                                                            </Link>
                                                        </div>
                                                        <div  className='hidden md:inline p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                                            <h1 className='mb-0  font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                            <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                                            <h2 className='text-4xl  md:text-left px-6 md:pr-4'>{navcate.descripcion}</h2>
                                                        </div>
                                                        

                                                        <div  className='inline md:hidden p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-4 md:mt-8 '>
                                                            <h1 className='mb-0  font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                            <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                                            <h2 className='text-4xl px-6 md:pr-2'>{navcate.descripcion}</h2>
                                                        </div>
                                                        <div id='imagenPrueba' className='flex md:hidden p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                                            <Link to={`/productos/${navcate.bCategoria}`} className="no-underline">
                                                                <img  src={`${navcate.image}`} alt="stad1" className="flex md:hidden imgE  w-20rem h-30rem border-round "/>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                    {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} */}
                                                    
                                                    <div id='activador'  className='fondoCate-izq absolute h-30rem p-0 m-0 w-10 z-0 right-0'>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        )
   
                            ))
                        )
                    }

                    {/*<div className='col-12 md:col-6'>
                        <div className='relative'>
                            <div className='box'>
                                <div className='movede grid flex align-content-center align-items-center z-1'>
                                    <div className='p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                        <h1 className='mb-0 font-bold text-6xl'>MESAS</h1>
                                        <h2 className='mt-0 font-bold text-6xl'>BERSATI</h2>
                                        <h2 className='text-4xl md:text-right px-6 md:pl-4'>Diseños exclusivos de mesas linea Bersati.</h2>
                                    </div>
                                    <div id='imagenPrueba' className='p-0 col-12 md:col-7 flex justify-content-center md:justify-content-start align-items-center cursor-pointer z-1'>
                                        <img src={imgPruebita} alt="stad1" className="w-20rem h-30rem border-round hover:shadow-2"/>
                                    </div>
                                    
                                </div>
                                {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} 
                                <div id='activador'  className='fondoCate absolute h-30rem p-0 m-0 w-full z-0'>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className='col-12 md:col-6'>
                        <div className='relative'>
                            <div className='box-izq'>
                                <div className='moveiz grid flex align-content-center align-items-center z-1 '>

                                    <div id='imagenPrueba' className='hidden md:flex p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                        <img src={imgPruebita} alt="stad1" className="hidden md:flex imgE  w-20rem h-30rem border-round hover:shadow-2"/>
                                    </div>
                                    <div  className='hidden md:inline p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                        <h1 className='mb-0  font-bold text-6xl'>MESAS</h1>
                                        <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                        <h2 className='text-4xl  md:text-left px-6 md:pr-4'>Diseños exclusivos de mesas linea Bersati.</h2>
                                    </div>
                                    

                                    <div  className='inline md:hidden p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-4 md:mt-8 '>
                                        <h1 className='mb-0  font-bold text-6xl'>MESAS</h1>
                                        <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                        <h2 className='text-4xl px-6 md:pr-2'>Diseños exclusivos de mesas linea Bersati.</h2>
                                    </div>
                                    <div id='imagenPrueba' className='flex md:hidden p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                        <img src={imgPruebita} alt="stad1" className="flex md:hidden imgE  w-20rem h-30rem border-round hover:shadow-2"/>
                                    </div>

                                </div>
                                {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} 
                                <div id='activador'  className='fondoCate-izq absolute h-30rem p-0 m-0 w-full z-0'>
                                </div>
                            </div>
                            
                        </div>
                    </div>*/}

                </div>
            </div>
        </>
    )
}

export default CategoriasNew