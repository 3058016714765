/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';


import axios from "axios";

const CotizacionesBersati = () =>{

    const [source, setSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [datita, setDatita] = useState([]);

    const toast = useRef(null);

    useEffect(() => {
        setLoading(true)
            const generarData = async () =>{
                
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/cotizacionindex')
    
                setSource(res.data)


                setLoading(false)
            }

            generarData()
    
        //setLoading(false)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    //console.log(source)

    const allowExpansion = (rowData) => {
        
        return rowData.grupos.length > 0;
    };
    const imageBodyTemplate = (rowData) => {
        return <img src={`${rowData.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.imagen} className="w-5" />
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex'>
                    <Button icon="pi pi-trash" placeholder="Left" tooltip="Eliminar Promoción" tooltipOptions={{position: 'left'}} onClick={() => null} className="p-button-rounded p-button-danger ml-1"  />
                </div>
            </React.Fragment>
        );
    }
    const hideDialog = () => {
        setProductDialog(false);
        setDatita([])
      }
    
    const generarData = async () =>{
        setLoading(true)
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/cotizacionindex')

            setSource(res.data)
        setLoading(false)
    }

    const onRowSelect = async (rowData) =>{   
        setDatita(rowData.data)
        setProductDialog(true);

       

        if (rowData.data.leido === 0) {
            let id = rowData.data.id;
            let data = {
                leido : 1
              }
            //let resSave = null;
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/cotizacionleida/${id}`, data);
              //console.log(resSave)
            if (resSave.status === 200) {      
                //toast.current.show({ severity: 'success', summary: 'Estado Modificado', life: 3000 });
                //setLoadingBtn(false)
                generarData()
              }else {
                  toast.current.show({ severity: 'error', summary: 'Estado No Modificado!', life: 3000 });
                  
            }
        }
        //console.log(rowData)
    }
    const onRowSelect1 = async (rowData) =>{   
        setDatita(rowData)
        setProductDialog(true);

        if (rowData.leido === 0) {
            let id = rowData.id;
            let data = {
                leido : 1
              }
            //let resSave = null;
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/cotizacionleida/${id}`, data);
              //console.log(resSave)
            if (resSave.status === 200) {      
                //toast.current.show({ severity: 'success', summary: 'Estado Modificado', life: 3000 });
                //setLoadingBtn(false)
                generarData()
              }else {
                  toast.current.show({ severity: 'error', summary: 'Estado No Modificado!', life: 3000 });
                  
            }
        }
    }


    const statusBodyTemplate = (rowData) => {
        //return rowData.leido;
        if (rowData.leido === 0) {
            return (
                <React.Fragment>
                    <div onClick={() => onRowSelect1(rowData)} className='flex justify-content-center text-center p-2 border-circle' style={{color:'#cec8b7', background: '#565656'}}>
                        <span  className="flex"><i className=" text-xl pi pi-eye-slash "></i></span>
                    </div>
                </React.Fragment>
            );
        }
        if (rowData.leido === 1) {
            return (
                <React.Fragment>
                    <div onClick={() => onRowSelect1(rowData)} className='flex justify-content-center text-center text-white bg-green-500 p-2 border-circle'>
                        <span className="flex "><i className=" text-xl pi pi-eye "></i></span>
                    </div>
                </React.Fragment>
            );
        }
    }
    
    

    //console.log(datita)

    return(
        <div className='surface-section m-0 p-0 md:m-2 md:p-2 max-w-full overflow-hidden shadow-4'>
             <Toast ref={toast} />
            <div className=" px-2 py-5 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4 uppercase ">Solicitud de Cotizaciones</span>
                </div>
            </div>
            <div className="card w-full datatable-crud-demo px-2 md:px-3 lg:px-5" >
                <DataTable value={source} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading} filterDisplay="row" emptyMessage='Sin datos...'
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} items" size="small"    dataKey="id"
                    editMode="cell"  className="editable-cells-table z-1 " responsiveLayout="scroll" scrollHeight="flex" scrollable  
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} selectionMode="single" onRowDoubleClick={onRowSelect}>

                       
                        <Column header='Estado' body={statusBodyTemplate} className='flex justify-content-center text-center' exportable={false} style={{ maxWidth: '5rem' }}></Column>
                        <Column field='consulta_nombre' header='Nombre Solicitante' className=' overflow-scroll' sortable style={{ minWidth: '13rem' }}  />
                        <Column field='celular' header='Celular' className=' overflow-scroll' sortable style={{ maxWidth: '8rem' }}  />
                        <Column field='email' header='Correo' className=' overflow-scroll' sortable style={{ minWidth: '15rem' }}  />
                        <Column field='nombre' header='Nombre Producto' className=' overflow-scroll' sortable style={{ minWidth: '13rem' }}  />
                        <Column field='codigo' header='Código Producto' className=' overflow-scroll' sortable style={{ maxWidth: '10rem' }}  />
                        {/*<Column field='marca' header='Marca Producto' className=' overflow-scroll' sortable style={{ maxWidth: '10rem' }}  />*/}
                        {/*<Column header='Imagen Producto' className='flex justify-content-center' style={{ minWidth: '15rem' }} body={imageBodyTemplate} />*/}
                </DataTable>

                <Dialog visible={productDialog} style={{ width: '550px' }} header="Datos del Solicitante" modal className="p-fluid"  onHide={hideDialog}>

                    <div className="text-500 mb-5">Información de la solicitud.</div>

                    <ul className="list-none p-0 m-0 border-top-1 border-300">
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-3 font-medium">Nombre</div>
                            <div className="text-900 w-full md:w-9 mt-1 md:mt-0 lg:mt-0">{datita.consulta_nombre}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-3 font-medium">Correo</div>
                            <div className="text-900 w-full md:w-9 line-height-3">{datita.email}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-3 font-medium">Celular</div>
                            <div className="text-900 w-full md:w-9">{datita.celular}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-3 font-medium">Tipo de Contacto</div>
                            <div className="text-900 w-full md:w-9">
                                {
                                    datita.tipo_contacto_whatsapp === 1 ?(
                                        <Chip label="Whatsapp" icon="pi pi-whatsapp" className="mr-2 mb-2 custom-chip bg-green-200" />
                                    ):('')
                                }
                                {
                                    datita.tipo_contacto_celular === 1 ?(
                                        <Chip label="Teléfono" icon="pi pi-phone" className="mr-2 mb-2 custom-chip bg-blue-200" />
                                    ):('')
                                }
                                {
                                    datita.tipo_contacto_email === 1 ?(
                                        <Chip label="Correo" icon="pi pi-envelope" className="mr-2 mb-2 custom-chip bg-red-200" />
                                    ):('')
                                }
                            </div>
                        </li>
                    </ul>
                    <div className="font-medium text-2xl text-900 mb-3">Datos del Producto</div>
                    <ul className="list-none p-0 m-0 border-top-1 border-300">
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-8 font-medium">{datita.nombre}</div>
                            <div className="text-900 w-full md:w-4 mt-1 md:mt-0 lg:mt-0">{datita.codigo}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-8 font-medium">{datita.nombreCategoria}</div>
                            <div className="text-900 w-full md:w-4 mt-1 md:mt-0 lg:mt-0">{datita.marca}</div>
                        </li>
                        <li className="flex align-items-center justify-content-center py-3 px-2 flex-wrap">
                            <div className="flex justify-content-center text-500 w-full md:w-12 font-medium">
                                <img src={datita.imagenPrincipal} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={datita.codigo} className="w-5" />
                            </div>
                        </li>
                    </ul>
                </Dialog>
            </div>
        </div>
    )
}

export default CotizacionesBersati