/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */


import React,{ useEffect, useState, useContext, useRef } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Galleria } from 'primereact/galleria';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useFormik } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { Helmet } from 'react-helmet-async';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCube, EffectCoverflow, EffectFlip } from 'swiper';


import { UserContext } from '../../../../../context/UserProvider';
import axios from 'axios';


import { itemDetailsProdAccion } from '../../../../../Redux/patos/ItemDetails';
import Cargando from '../../../comps/Cargando';
import './store.css'
import './ProductoDetalleStylo.css'

const ProductoDetalleNew2 = () =>{

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const [images, setImages] = useState(null);
    const [activeIndex,setActiveIndex] = useState(null)
    const [productDialog, setProductDialog] = useState(false);
    const [checked, setChecked] = useState(false);
    const [cities, setCities] = useState([]);
    const [cargando, setCargando] = useState(false);
    const toast = useRef(null);


    useEffect(() => {
        const generarData = (id) =>{   
            dispatch(itemDetailsProdAccion(id))
        }
        generarData(id)
    },[dispatch, id])

    const formik = useFormik({
        initialValues: {
            nombre: '',
            producto_id: '',
            email: '',
            celular: '',
            descripcion: '',
            tipo_contacto_celular: 0,
            tipo_contacto_email: 0,
            tipo_contacto_whatsapp: 0,
           
      },validate: (data) => {
        let errors = {};

        if (!data.nombre) {
            errors.nombre = 'Nombre es Requerido.';
        }

        if (!data.email) {
            errors.email = 'El correo es Requerido.';
        }

        if (!data.celular) {
            errors.celular = 'Celular es Requerido.';
        }

        return errors;
        },
        onSubmit: (data) => {
                setCargando(true)
                data.producto_id = id;
            if (cities.length > 0) {
                    
                for (let index = 0; index < cities.length; index++) {

                    if (cities[index] == 1){
                        data.tipo_contacto_email = 1;
                    } 
                    if (cities[index] == 2){
                        data.tipo_contacto_celular = 1;
                    } 
                    if (cities[index] == 3){
                        data.tipo_contacto_whatsapp = 1;
                    } 
                }
                    
                
                setCities([])
                //console.log(data);
                function proceder(){
                    hideDialog()
                    toast.current.show({ severity: 'success', summary: 'Formulario Enviado', detail: 'La solicitud de cotización fue enviada correctamente.', life: 4000 });
                }
                //setTimeout(proceder, 3000);
                
                guardarData(data)

            }else{
                console.log('Error Sin Tipo de Contacto Seleccionado...')
                setCargando(false)
            }
        }
      })
      //console.log(cities)
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const guardarData = async (data) =>{

      
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/cotizacionueva`, data);
            console.log(resSave)
            if (resSave.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Formulario Enviado', detail: 'La solicitud de cotización fue enviada correctamente.', life: 4000 });
                hideDialog()
                setCargando(false)
            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Formulario NO Enviada!', detail: 'Genere un nuevo formulario..', life: 5000 });
                setCargando(false)
                hideDialog()
            }else{
                setCargando(false)
                hideDialog()
            }

            
        
    }

    const {arrayIDP, loadingIDP} = useSelector(store => store.ecoIDP)
    const [imagenes, setImagenes] = useState([]);
    const [imgeSel, setImgSel] = useState(null);

    const itemTemplate = (item) => {
        return <img src={item} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item} className='h-24rem md:h-30rem border-round-3xl' style={{width: '100%', height: '30rem', display: 'block' }} />;
    }
    const thumbnailTemplate = (item) => {
        return <img src={item} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ display: 'block' }} />;
    }

    const hideDialog = () => {
        setCargando(false)
        formik.resetForm()
        formik.values.tipo_contacto_email = 0;
        formik.values.tipo_contacto_celular = 0;
        formik.values.tipo_contacto_whatsapp = 0;
        //setProduct(null)
        setProductDialog(false);
        setCities([])
    }

    useEffect(()=>{
        //console.log('Entra...')

        if (arrayIDP.status === 200) {
        
            let data = [

                arrayIDP.data[0].img1,
                arrayIDP.data[0].img2,
                arrayIDP.data[0].img3,
                arrayIDP.data[0].img4
            ]

            setImagenes(data)
            setImgSel(data[0])
        }
    },[arrayIDP])

    //console.log(imagenes)
    const solicitarCoti = (product) => {
        setProductDialog(true)
    }

    const onCityChange = (e) => {
        let selectedCities = [...cities];

        if (e.checked)
            selectedCities.push(e.value);
        else
            selectedCities.splice(selectedCities.indexOf(e.value), 1);

        setCities(selectedCities);
    }

    
    const isFormFieldValid = (nombre) => !!(formik.touched[nombre] && formik.errors[nombre]);
    const getFormErrorMessage = (nombre) => {
        return isFormFieldValid(nombre) && <small className="p-error">{formik.errors[nombre]}</small>;
    };

    const refContainer = useRef(null); 
        useEffect(() => { 
            refContainer.current.scrollIntoView({ behavior: "smooth" }); 
        }, [images]);

    return (
        <div ref={refContainer}>
            {
                loadingIDP ? (''):(
                    arrayIDP.status === 200 ? (
                        <Helmet>
                            <title>{arrayIDP.data[0].nombre} - Bersati</title> 
                        </Helmet>
                    ):(
                        <Helmet>
                            <title>Bersati</title> 
                        </Helmet>
                    )
                )
            }
        <Toast ref={toast} />
            {
                loadingIDP ? (
                    <div className='flex justify-content-center align-content-center'>
                        <Cargando />
                    </div>
                ):( 
                    arrayIDP.status === 200 ? (
                        arrayIDP.data[0].producto_promo.length > 0 ? (
                            
                            <div className='surface-section px-4 py-8 md:px-6 lg:px-8'>
                                
                                <div className='grid'>
                                    <div className='col-12 lg:col-6 flex justify-content-center'>
                                    <Galleria  value={imagenes} responsiveOptions={responsiveOptions} numVisible={4} className='w-11' transitionInterval={3000}
                                         thumbnail={thumbnailTemplate} showThumbnails={false} showIndicators showItemNavigators showItemNavigatorsOnHover item={itemTemplate} circular autoPlay />
                                    </div>
                                    <div className='col-12 lg:col-6 lg:col-6 py-3 lg:pl-5'>
                                        <div className="flex align-items-center justify-content-between align-items-end  mb-4">
                                            <span className="flex text-900 text-7xl font">{arrayIDP.data[0].nombre} </span>
                                            <span className="flex align-items-center text-4xl">{arrayIDP.data[0].codigo}</span>
                                        </div>
                                        <div class="text-700 text-3xl mb-5">Marca: {arrayIDP.data[0].marca}</div>
                                        <div className="flex mb-5">
                                            <Button label="Solicitar una Cotización" className="w-6 surface-900"></Button>
                                        </div>

                                            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                                <AccordionTab header="Descripción">
                                                    <div dangerouslySetInnerHTML={{__html: arrayIDP.data[0].descripcionLarga}} />    
                                                </AccordionTab>
                                                <AccordionTab header="Caracteristicas">
                                                    <DataTable value={arrayIDP.data[1]} showGridlines responsiveLayout="scroll">
                                                        <Column field='nombreCaracteristica' header="Caracteristica"></Column>
                                                        <Column field='detalle' header="Detalle"></Column>
                                                        
                                                    </DataTable>
                                                </AccordionTab>
                                            </Accordion>
                                    </div>

                                </div>
                            </div>
                        ):
                        (
                            <>
                                <div className='flex flex-column surface-section px-4 py-2 md:py-4 md:px-6  lg:px-8 lg:py-8 '>

                                    <div className='flex flex-column w-full justify-content-center h-auto text-4xl'>
                                        <h1 className='flex align-items-center justify-content-center py-0 my-0 '>{arrayIDP.data[0].nombre} </h1>
                                        <h3 className='flex align-items-center justify-content-center pt-1 mt-1 text-xl'>{arrayIDP.data[0].codigo}</h3>
                                    </div>
                                    <div className='flex w-full justify-content-center h-auto'>
                                        <Button onClick={() => solicitarCoti()} label="Solicitar una Cotización" className="w-full md:w-4 lg:w-3 surface-900 text-sm border-round-3xl"></Button>
                                    </div>
                                    <div className='flex justify-content-center mt-4'>
                                        <div className='flex '>
                                        
                                            {/*<Galleria  value={imagenes} responsiveOptions={responsiveOptions} numVisible={5} className='w-12' transitionInterval={5000}
                                            showThumbnails={false} showIndicators showItemNavigators item={itemTemplate} circular  autoPlay />  */}
                                            <Swiper
                                                slidesPerView={1}
                                                //slidesPerView={'auto'}
                                                loop={true}
                                                //effect={'flip'}

                                                centeredSlides={true}
                                                modules={[EffectFlip, Navigation, Pagination, A11y, Scrollbar, Autoplay]}
                                                autoplay={{
                                                    delay: 4000,
                                                    disableOnInteraction: false,
                                                }}
                                                navigation
                                             
                                                spaceBetween={10}
                                                pagination={{ clickable: true }}
                                                className='w-12 md:w-10 lg:w-8 flex justify-content-center '
                                            >
                                                {
                                                    imagenes.map((imaginito, index) => (
                                                        <SwiperSlide  key={index} className='flex justify-content-center w-12 overflow-hidden'>
                                                            <img src={imaginito} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={'ecitsa'} className='border-round-3xl w-9 md:w-7 lg:w-5' style={{ display: 'block' }} />
                                                        </SwiperSlide>
                                                    ))
                                                }
                                            </Swiper>

                                        </div>
                                    </div>
                                    <div className='flex justify-content-center w-full mt-4'>
                                            
                                        <Accordion className='w-12 md:w-10 lg:w-8' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                            <AccordionTab header="Descripción">
                                                <div dangerouslySetInnerHTML={{__html: arrayIDP.data[0].descripcionLarga}} />    
                                            </AccordionTab>
                                            <AccordionTab header="Caracteristicas">
                                                <DataTable value={arrayIDP.data[1]} showGridlines responsiveLayout="scroll">
                                                    <Column field='nombreCaracteristica' header="Caracteristica"></Column>
                                                    <Column field='detalle' header="Detalle"></Column>
                                                    
                                                </DataTable>
                                            </AccordionTab>
                                        </Accordion>
                                    </div>

                                </div>
                                <Dialog visible={productDialog} style={{ width: '650px' }}  modal className="p-fluid"  onHide={hideDialog}>
                                    <div className='px-4'>
                                        <div className='flex w-full justify-content-center'>
                                            <h1 className='text-7xl mb-2'>Cotización</h1>
                                        </div>
                                        <div className='flex mb-5 w-full text-center justify-content-center'>
                                            <span>Envíe el formulario y reciba la Cotización.</span>
                                        </div>

                                        <form onSubmit={formik.handleSubmit} className="p-fluid">
                                            <div className="field">
                                                <label htmlFor="nombre" className={classNames({ 'p-error': isFormFieldValid('nombre') })}>*Nombre Completo</label>
                                                <InputText id="nombre" value={formik.values.nombre} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('nombre') } )} required  />               
                                                {getFormErrorMessage('nombre')}
                                            </div>
                                            <div className="field">
                                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>*Email</label>
                                                <InputText id="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') } )}  required  />  
                                                {getFormErrorMessage('email')}             
                                            </div>
                                            <div className="field">
                                                <label htmlFor="celular" className={classNames({ 'p-error': isFormFieldValid('celular') })}>*Celular</label>
                                                <InputText id="celular" value={formik.values.celular} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('celular') } )} required  /> 
                                                {getFormErrorMessage('celular')}                    
                                            </div>
                                            <div className='field '>
                                            <label >Método de contacto</label>
                                                <div className='flex flex-wrap mt-1'>
                                                    <div className="field-checkbox">
                                                        <Checkbox id='edito' inputId="city1" name="city" value="1" onChange={onCityChange} checked={cities.indexOf('1') !== -1} />
                                                        <label htmlFor="city1">Email</label>
                                                    </div>
                                                    <div className="field-checkbox ml-4">
                                                        <Checkbox id='edito' inputId="city2" name="city" value="2" onChange={onCityChange} checked={cities.indexOf('2') !== -1} />
                                                        <label htmlFor="city2">Teléfono</label>
                                                    </div>
                                                    <div className="field-checkbox ml-4 ">
                                                        <Checkbox id='edito'  inputId="city3" name="city" value="3" onChange={onCityChange} checked={cities.indexOf('3') !== -1} />
                                                        <label htmlFor="city3">Whatsapp</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='field w-full text-center'>
                                                <Button loading={cargando} onClick={formik.handleSubmit} label="Enviar" type="button" className='surface-900 text-center'></Button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog>
                            </>
                        )
                    )
                    :
                    (
                        <div className='flex min-h-screen surface-section justify-content-center px-4 py-2 md:py-4 md:px-6 md:mt-4 lg:px-8 lg:py-8 lg:mt-8'>
                            <h1>No item found</h1>
                        </div>
                    )

                    
                )
            }
            
        </div>
    )
}
export default ProductoDetalleNew2