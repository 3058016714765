/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { StyleClass } from 'primereact/styleclass';
import { InputText } from 'primereact/inputtext';
import { PanelMenu } from 'primereact/panelmenu';
import { Skeleton } from 'primereact/skeleton';
import { Tree } from 'primereact/tree';

import { obtenerNavbarAccionC, obtenerNavbarAccionGP, obtenerNavbarAccion, obtenerNavbarAccionL } from '../../../../Redux/patos/navbarDuck'

import NavbarNew from '../../comps/NavbarNew';
import NavbarNewBlack from '../../comps/NavbarNewBlack';
import FooterVisitors from '../../comps/FooterVisitors';


const TiendaVisitors = () => {

    const dispatch = useDispatch();
    //navbarListaA

    useEffect(() => {
        const generarData = () =>{

            /*dispatch(obtenerNavbarAccionC())
            dispatch(obtenerNavbarAccion())
            dispatch(obtenerNavbarAccionL())
            dispatch(obtenerNavbarAccionGP())*/

        }
    },[dispatch])

    const location = useLocation();
    const { cate, id } = useParams();

    /*const navbarC = useSelector(store => store.navbarListaC)
    const navbarF = useSelector(store => store.navbarLista)
    const navbarL = useSelector(store => store.navbarListaL)
    const navbarGP = useSelector(store => store.navbarListaGP)*/

    const toggleBtn1 = useRef(null);
    const toggleBtn2 = useRef(null);
    const toggleBtn3 = useRef(null);

    //console.log(navbarC);
    //console.log(navbarGP);

    const [menucito1, setMenucito1] = useState('');

    /*useEffect(() => {

        const generarMenucito = () =>{

            let menucito = [];

            if (navbarC.loadingC) {
                //console.log(navbarC.loadingC)
            }else{
                //console.log(navbarC.loadingC)
                try {

                    for (let index = 0; index < navbarC.arrayC.length; index++) {
                        let itemus = [];
                        for (let index1 = 0; index1 < navbarF.array.length; index1++) {
                            if (navbarF.array[index1].categoria_id === navbarC.arrayC[index].id) {

                                let itemus1 = [];
                                for (let index2 = 0; index2 < navbarL.arrayL.length; index2++) {
                                    if (navbarL.arrayL[index2].familias_id === navbarF.array[index1].id) {
                                        
                                        let itemus2 = []
                                        for (let index3 = 0; index3 < navbarGP.arrayGP.length; index3++) {
                                            if (navbarGP.arrayGP[index3].lineas_id === navbarL.arrayL[index2].id) {

                                                let InToCuatro = {
                                                    key : navbarGP.arrayGP[index3].bGP,
                                                    label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}/${navbarF.array[index1].bFamilia}/${navbarL.arrayL[index2].bLinea}/${navbarGP.arrayGP[index3].bGP}`}>{navbarGP.arrayGP[index3].nombreGrupoProductos}</Link>,
                                                    //icon:'pi pi-shopping-bag',
                                                }
                                                itemus2.push(InToCuatro)
                                            }
                                        }

                                        let InToTres = {
                                            key : navbarL.arrayL[index2].bLinea,
                                            label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}/${navbarF.array[index1].bFamilia}/${navbarL.arrayL[index2].bLinea}`}>{navbarL.arrayL[index2].nombreLinea}</Link>,
                                            icon:'pi pi-shopping-bag',
                                            children: itemus2
                                        }
                                        itemus1.push(InToTres)
                                    }
                                }

                                let InToDos = {
                                    key : navbarF.array[index1].bFamilia,
                                    label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}/${navbarF.array[index1].bFamilia}`}>{navbarF.array[index1].nombreFamilia}</Link>,
                                    icon:'pi pi-shopping-bag',
                                    children: itemus1
                                }
                                itemus.push(InToDos)   
                            } 
                        }
            
                        let inTo = {
                            key : index,
                            label:<Link className='text-900 no-underline hover:text-400' to={`/tienda/${navbarC.arrayC[index].bCategoria}`}>{navbarC.arrayC[index].nombreCategoria}</Link>,
                            //icon:'pi pi-fw pi-file',
                            
                            children: itemus
                        }
                        menucito.push(inTo)
                    }
                    const define = () =>{
                        setMenucito1(menucito)
                    }
        
                    define()
                    
                } catch (error) {
                    setMenucito1([])
                }
            }
        }

        generarMenucito();

    },[navbarC, navbarF, navbarGP, navbarL]); */

    //console.log(id)

  return (
    <>
        <div className='block z-1'> 
            {
                location.pathname === '/productos' || location.pathname === `/productos/${cate}` || location.pathname === `/productos/productodetalle/${id}` ?
                (
                    <NavbarNewBlack />
                )
                :
                (
                    < NavbarNew />
                )
            }
        </div>
        <div className='block z-0 pt-8'>
            <Outlet></Outlet>
        </div>
        <div className='block z-0'>
            < FooterVisitors />
        </div>

    </>
  )
}

export default TiendaVisitors



/*

 <div className="min-h-screen max-w-full flex surface-ground z-0">

            {/*<div id="app-sidebar-1" className="surface-section min-h-full absolute md:sticky lg:sticky flex-shrink-0 hidden z-5 left-0 top-0 z-2 border-right-1 surface-border select-none" style={{width:"260px"}}>
                {
                    navbarC.loadingC ? (
                        <div className="field ">
                            <h5>Cargando.....</h5>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                            <Skeleton width="10rem" height="4rem" borderRadius="16px" className='m-2 w-auto'></Skeleton>
                        </div>
                    )
                    : (
                        //<PanelMenu model={menucito1} />
                        <div className='mt-3 min-h-full'>
                            <h1 className='m-2'>Index - Bersati</h1>
                            <Tree className='min-h-full' value={menucito1} filter expandedKeys={'true'}  />
                        </div>
                    )
                }
                
            </div>*

            <div className="min-h-screen max-w-full flex flex-column relative flex-auto">
                {/*<div className="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static" style={{height: "60px"}}>
                    <div className="flex">

                    <StyleClass nodeRef={toggleBtn3} selector='#app-sidebar-1' enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick="true">
                        <a ref={toggleBtn3} className="cursor-pointer inline-flex align-items-center justify-content-center  text-700 mr-3">
                            <i className="pi pi-bars text-4xl"></i>
                        </a>
                    </StyleClass>               
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"></i>
                            <InputText type="text" className="border-none w-10rem sm:w-20rem" placeholder="Buscar..." />
                        </span>
                    </div>
            </div>  *
                <div className="p-4 flex flex-column flex-auto max-w-full z-0">
                    <div className="w-full border-2 surface-border border-round min-h-full surface-section ">
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </div>*/