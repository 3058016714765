import { useContext, useRef, useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import * as Yup from 'yup';
import { classNames } from 'primereact/utils';

import './Stylo.css';
import { UserContext } from "../../../context/UserProvider";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Cargando from "../comps/Cargando";

const RegisterVisitors = () => {

    const toast = useRef(null);
    const navegate = useNavigate();

    const {userLoading, registerUser, loginGoogle, setUserBer, setUserFire, userBer, userFire } = useContext(UserContext);
    const [cargando, setCargando] = useState(false);


    const handleGoogleRegister = async () =>{
        setCargando(true)
        try {
            const respon = await loginGoogle();
            //console.log(respon);

            if (respon.user) {
                
                setUserFire(respon.user);
                localStorage.setItem('_dolce', btoa(respon.user.accessToken))
        
    
                registroWithGoogle(respon.user)
            }else{
                toast.current.show({severity:'error', summary: 'Error en el Registro', detail: 'Usuario no Creado, pruebe otro tipo de registro..', life: 3000});
            }  
        } catch (error) {
            console.log(error);
            setCargando(false)
        }
        
    }

    const registroWithGoogle = async (data) =>{
        const response = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/visitors/registerGoogle', data)

        //console.log(response.data)
        try {
            if (response.status === 200) {
                setUserBer(response.data.userData[0])
                localStorage.setItem('_vita', btoa(response.data.userData[1]));
                navegate("/");
                formik.resetForm();
                setCargando(false)
    
    
            }else if (response.status === 202) {
                formik.resetForm();
                toast.current.show({severity:'error', summary: 'Error en el Registro', detail: 'Usuario no Creado, pruebe otro tipo de registro..', life: 3000});
                setCargando(false)
            }
    
            setCargando(false)
            
        } catch (error) {
            setCargando(false)
            toast.current.show({severity:'error', summary: 'Error al Registrar', detail: 'Usuario no Creado, pruebe otro tipo de registro..', life: 3000});
        }
        
        
        //navegate("/");
        setCargando(false)

    }

    const formik = useFormik({
        initialValues:{

            nombre: '',
            email: '',
            password: '',
            confirm:''

        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .min(3, 'Mínimo tres caracteres.')
                .required('El campo es Requerido'),
            email: Yup.string()
                .min(3, 'Mínimo tres caracteres.')
                .email('Ingrese un Email válido')
                .required('El campo es Requerido'),
            password: Yup.string()
                .min(6, 'Mínimo seis caracteres.')
                .required('Ingrese su Contraseña.'),
            confirm: Yup.string()
                .min(6, 'Mínimo seis caracteres.')
                .required('Confirme su Contraseña.')
                .oneOf([Yup.ref('password')], "Las contraseñas no son iguales."),
        }),
        onSubmit: async (data)=>{
            //console.log(data)
            setCargando(true)
            if (data.password === data.confirm) {
               
                try {
                    const userConfirme = await registerUser(data.email, data.password);
                    //console.log(userConfirme);
                    localStorage.setItem('_dolce', btoa(userConfirme.user.accessToken))
                    setUserFire(userConfirme.user);
                    //navegate("/");
                    if (userConfirme.user) {
                        //navegate("/");
                        guardarBersati(data)
                        
                    }else{
                        console.log(userConfirme)
                        setCargando(false)
                    }
                } catch (error) {
                    setCargando(false)
                    formik.values.password = '';
                    formik.values.confirm = '';
                    toast.current.show({severity:'error', summary: 'Error en el Registro', detail: error.code, life: 3000});
                    console.log(error.code);
                }
            }else{
                toast.current.show({severity:'error', summary: 'Error en el Registro', detail:'Confirma tu contraseña', life: 3000});
                formik.values.password = '';
                formik.values.confirm = '';
                setCargando(false);
            }
            
        }

    })

    const guardarBersati = async (data) =>{
        
        const response = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/visitors/register', data)

        //console.log(response.data.userData)
        try {
            if (response.status === 200) {
                setUserBer(response.data.userData)
                localStorage.setItem('_vita', btoa(response.data.userData[1]));
                navegate("/");
                formik.resetForm();
                setCargando(false)
    
    
            }else if (response.status === 202) {
                formik.resetForm();
                toast.current.show({severity:'error', summary: 'Error en el Registro', detail: 'Usuario no Creado, pruebe otro tipo de registro..', life: 3000});
                setCargando(false)
            }
    
            setCargando(false)
            
        } catch (error) {
            setCargando(false)
            toast.current.show({severity:'error', summary: 'Error al Registrar', detail: 'Usuario no Creado, pruebe otro tipo de registro..', life: 3000});
        }
        
        
    }

    useEffect(() =>{
        if (userBer && userFire) {
            navegate('/');
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userBer, userFire])

  return (
    <>
        <Toast ref={toast} />
        {
            userLoading ?  (
                <div className='flex align-items-center justify-content-center '>
                    <Cargando />
                </div>
            ):(
                <div className="flex justify-content-center surface-white px-4 py-8 md:px-6 lg:px-8">
                    <div className="lg:w-6 shadow-2">
                        <div className="w-full p-4 lg:p-7 ">
                            <div className="flex align-items-center justify-content-between mb-6">
                                <span className="text-2xl font-medium text-900">REGISTRO EN BERSATI</span>
                            </div>
                            
                            <div className="flex justify-content-between">
                                <Button loading={cargando} className="mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                    <i className="pi pi-facebook text-indigo-400 mr-2"></i>
                                    <span>Regístrate con Facebook</span>
                                </Button>
                                <Button loading={cargando} onClick={handleGoogleRegister}  className="ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                    <i className="pi pi-google text-pink-400 mr-2"></i>
                                    <span>Regístrate con Google</span>
                                </Button>
                            </div>
                            <Divider align="center" className="my-4">
                                <span className="text-600 font-normal text-sm">O</span>
                            </Divider>
                            <form onSubmit={formik.handleSubmit} className="">
                                <div className="field">
                                    <label htmlFor="nombre" className="block text-900 font-medium mb-2">Nombre Completo</label>
                                    <InputText  id="nombre" value={formik.values.nombre} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('w-full p-3',{ 'p-invalid':  formik.errors.nombre && formik.touched.nombre })}/>
                                    { formik.errors.nombre && formik.touched.nombre ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.nombre}</p>
                                        ) : null }
                                </div>
                                <div className="field">
                                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                                    <InputText  id="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('w-full p-3',{ 'p-invalid':  formik.errors.email && formik.touched.email })}/>
                                    { formik.errors.email && formik.touched.email ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.email}</p>
                                        ) : null }
                                </div>
                                <div className="field">
                                    <label htmlFor="password" className="block text-900 font-medium mb-2">Contraseña</label>
                                    <InputText  id="password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className={classNames('w-full p-3',{ 'p-invalid':  formik.errors.password && formik.touched.password })} />
                                    { formik.errors.password && formik.touched.password ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.password}</p>
                                        ) : null }
                                </div>
                                <div className="field">
                                    <label htmlFor="confirm" className="block text-900 font-medium mb-2">Confirma tu Contraseña</label>
                                    <InputText  id="confirm" value={formik.values.confirm} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" className={classNames('w-full p-3',{ 'p-invalid':  formik.errors.confirm && formik.touched.confirm })} />
                                    { formik.errors.confirm && formik.touched.confirm ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.confirm}</p>
                                        ) : null }
                                </div>
                            
                                <Button type="submit" loading={cargando} label="Registrarse" className="w-full surface-900 mt-4 py-3 font-medium"></Button>
                            </form>  
                        </div>
                    </div>
                </div>   
            )
        }
         
    </>
  )
}

export default RegisterVisitors