/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';

import {useDispatch, useSelector} from 'react-redux'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { PickList } from 'primereact/picklist';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';


import { CrossIndexAccion } from '../../../../../Redux/patos/CrossUpSelling';
import { CrossShowAccion } from '../../../../../Redux/patos/CrossUpSelling';
import { CrossSaveAccion } from '../../../../../Redux/patos/CrossUpSelling';

import axios from "axios";

const CrosSellingPage = () => {

    const toast = useRef(null);
    const dispatch = useDispatch();

    const [prodTotal, setProdTotal] = useState([]);
    const [prodSel, setProdSel] = useState([]);
    const [productoShow, setProductoShow] = useState(null)
    const [selectedProduct1, setSelectedProduct1] = useState(null);
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    //const [prodasd, setProdasd] = useState([]);

    const {arrayCross, loadingCross} = useSelector(store => store.storeCrossIndex)
    const {arrayCrossShow, loadingCrossShow} = useSelector(store => store.storeCrossShow)
    const {arrayCrosSave, loadingCrosSave} = useSelector(store => store.storeCrossSave)

    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nombre': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'codigo': { value: null, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    });
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');


    useEffect(() =>{
        dispatch(CrossIndexAccion())  
    },[dispatch])
    const recagarDataCrosIndex = () =>{
        dispatch(CrossIndexAccion()) 
    }

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }
    const itemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="image-container">
                    <img src={`${item.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
                </div>
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.nombre}</h5>
                    <i className="pi pi-tag product-category-icon"></i>
                    <span className="product-category">{item.codigo}</span>
                </div>
            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <Button icon="pi pi-plus" placeholder="Left" tooltip="Añadir Productos" tooltipOptions={{position: 'left'}} onClick={() => AñadirProductos(rowData)} className="p-button-rounded p-button-info"  />
                </div>
            </React.Fragment>
        );
    }
    const AñadirProductos = (rowData) =>{
        setSource(arrayCross)
        setProductoShow(rowData)
       
    }
    const CancelarAddicion = () =>{
        setProductoShow(null)
        setSource([])
        setTarget([])
    }


    const header1 = (
        <div className="text-4xl">
            <h5 className="mx-0 my-1">Productos Activos</h5>
        </div>
    );

    const headerPS = (
        <div className="text-4xl">
            <h5 className="mx-0 my-1">Productos Cros-Selling</h5>
        </div>
    );
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" onClick={() => recagarDataCrosIndex()} className="p-button-text" />;

    const onRowSelect = async (rowData) =>{
        
        dispatch(CrossShowAccion(rowData.data.id)) 
        //console.log(arrayCrossShow)
    }

    
    const ActionDeleteCS = async (data) =>{  

        const id = data.id;
        const producto_id = data.producto_id;

        //console.log(data)
        try {
            
            const res = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/crosdelete/`+id);

            if (res.status === 200) {
                toast.current.show({severity:'success', summary: 'Acción Exitosa', detail: res.data.mensaje, life: 3000});
                dispatch(CrossShowAccion(producto_id)) 
            }
            if (res.status === 202) {
                toast.current.show({severity:'error', summary: 'Acción No Realizada', detail: res.data.mensaje, life: 3000});
                dispatch(CrossShowAccion(producto_id)) 
            }
        
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Error DB', detail: 'No se puede conectar con la BD.', life: 3000});
        }
    }

    const actionBodyTemplateCP = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <Button icon="pi pi-trash" placeholder="Left" onClick={() => ActionDeleteCS(rowData)} tooltip="Eliminar" tooltipOptions={{position: 'left'}} className="p-button-rounded p-button-danger"  />
                </div>
            </React.Fragment>
        );
    }
    const guardarDataCros = (productoShow, target) =>{

        const datita = {
            'id' : productoShow,
            'prod' : target
        }
        //console.log(datita)
        dispatch(CrossSaveAccion(datita))
        
    } 

    useEffect(()=>{
        if (arrayCrosSave.status === 200) {
            //console.log(arrayCrosSave)
            toast.current.show({severity:'success', summary: 'Acción Exitosa', detail: arrayCrosSave.data.mensaje, life: 3000});
            setTarget([])
        }
        if (arrayCrosSave.status === 202) {
            toast.current.show({severity:'success', summary: 'Acción Exitosa', detail: arrayCrosSave.data.mensaje, life: 3000});
            setTarget([])
            setSource(arrayCross)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[arrayCrosSave, loadingCrosSave])

    //console.log(productoShow)


    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['nombre'].value = value;
    
        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['codigo'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue1(value);
    }

      

    const header = (
        
        <div className="flex justify-content-between flex-wrap">     
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Buscar por Nombre" />
            </span>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar por Códgo"  className='mt-2 md:mt-0'/>
            </span>
        </div>
    );

  return (
    <>
    <Toast ref={toast} />
        <div className='w-full overflow-scroll speeddial-linear-demo '>
            <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">Cross Selling Bersati</span>
                </div> 

                <div className='mt-4'>
                    <div className='grid'>
                        <div className='col-12 md:col-8 lg:col-8'>
                            <div className="card w-full shadow-2" >
                                <DataTable value={arrayCross}  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loadingCross} emptyMessage='Sin datos...'
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" paginatorLeft={paginatorLeft}
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos"  showGridlines responsiveLayout="scroll" header={header} filters={filters2}
                                    scrollHeight="400px" selectionMode="single" dataKey="id" onRowDoubleClick={onRowSelect} selection={selectedProduct1} onSelectionChange={e => setSelectedProduct1(e.value)}
                                    //expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}  rowExpansionTemplate={rowExpansionTemplate} 
                                >

                                    
                                    <Column field='codigo' header='Código Producto' className='font-bold' style={{width:'15%'}}></Column>
                                    <Column field='nombre' header='Nombre Producto' />
                                    <Column header='Acciones' body={actionBodyTemplate} style={{minWidth:'20%'}}></Column>
                                </DataTable>
                            </div>
                        </div>
                        <div className='col-12 md:col-4 lg:col-4'>
                            <div className="card w-full shadow-2" >
                                <DataTable value={arrayCrossShow}  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loadingCrossShow} emptyMessage='Seleccione un Producto...'
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos"  showGridlines responsiveLayout="scroll" header={headerPS}
                                    scrollHeight="400px"
                                    //expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}  rowExpansionTemplate={rowExpansionTemplate} 
                                >
                                    <Column field='codigo' header='Código Producto' className='font-bold' style={{width:'15%'}}></Column>
                                    <Column field='nombre' header='Nombre Producto' />
                                    <Column header='Acciones' body={actionBodyTemplateCP} style={{minWidth:'20%'}}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classNames('mt-4',{ 'hidden':  productoShow != null ? false : true })}>
                    <div className="card w-full shadow-2">
                        <div className="border-bottom-1 surface-border p-4 flex justify-content-between flex-wrap">
                            <span className="block text-2xl md:text-3xl font-bold text-900">Producto Seleccionado: <span className='font-medium'>{productoShow != null ? productoShow.nombre+' - '+productoShow.codigo : null}</span></span>
                            <Button label="Cancelar" onClick={() => CancelarAddicion()} className="p-button-danger" />
                        </div> 
                        <div className="picklist-demo p-2 pt-4">
                            <PickList source={source} target={target} itemTemplate={itemTemplate} sourceHeader="Productos" targetHeader="Seleccionados"
                                sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }} onChange={onChange}
                                filterBy="codigo" sourceFilterPlaceholder="Buscar por Código" targetFilterPlaceholder="Buscar por Código" />
                            <div className='mt-4 flex align-content-center justify-content-center'>
                                {
                                    target.length > 0 ? (
                                        <Button label="Guardar Datos" onClick={() => guardarDataCros(productoShow, target)} className="p-button-success flex w-4" />
                                    ):null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CrosSellingPage