/* eslint-disable default-case */
/* eslint-disable no-unused-vars */


import axios from "axios";


//CONSTANTES

const dataInicialPromocion= {
    arrayPromo : [],
    loadingPromo : true,
};

const dataInicialCarousel = {
    arrayCarousel : [],
    loadingCarousel : true,
};

const dataInicialEEP= {
    arrayEEP : [],
    loadingEEP : true,
};


//TYPES

const OBTENER_PROMOCION_EXITO = 'OBTENER_PROMOCION_EXITO'
const OBTENER_PROMOCION_REQUEST = 'OBTENER_PROMOCION_REQUEST'
const OBTENER_PROMOCION_ERROR = 'OBTENER_PROMOCION_ERROR'



const OBTENER_CAROUSEL_EXITO = 'OBTENER_CAROUSEL_EXITO'
const OBTENER_CAROUSEL_REQUEST = 'OBTENER_CAROUSEL_REQUEST'
const OBTENER_CAROUSEL_ERROR = 'OBTENER_CAROUSEL_ERROR'



const OBTENER_EEP_EXITO = 'OBTENER_EEP_EXITO'
const OBTENER_EEP_REQUEST = 'OBTENER_EEP_REQUEST'
const OBTENER_EEP_ERROR = 'OBTENER_EEP_ERROR'


//REDUCERS


export function promocionReducer (state = dataInicialPromocion, action){
    switch (action.type) {
        case OBTENER_PROMOCION_REQUEST:
            return { loadingPromo: true, arrayPromo:[] };
        case OBTENER_PROMOCION_EXITO:
            return {...state, arrayPromo: action.payload, loadingPromo: false};       
        default:
            return state
    }
}


export function carouselReducer (state = dataInicialCarousel, action){
    switch (action.type) {
        case OBTENER_CAROUSEL_REQUEST:
            return { loadingCarousel: true, arrayCarousel:[] };
        case OBTENER_CAROUSEL_EXITO:
            return {...state, arrayCarousel: action.payload, loadingCarousel: false};       
        default:
            return state
    }
}


export function eleccionEditorReducer (state = dataInicialEEP, action){
    switch (action.type) {
        case OBTENER_EEP_REQUEST:
            return { loadingEEP: true, arrayEEP:[] };
        case OBTENER_EEP_EXITO:
            return {...state, arrayEEP: action.payload, loadingEEP: false};       
        default:
            return state
    }
}


//ACCIONES

export const promocionIndexEcoAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_PROMOCION_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/visitors/promocion')
        //console.log(res)
        if (res.status === 202) {
            //console.log(res)
        }else{
            dispatch({
                type: OBTENER_PROMOCION_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}


export const carouselIndexEcoAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CAROUSEL_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/carouselindexeco')
        //console.log(res)
        if (res.status === 202) {
            //console.log(res)
        }else{
            dispatch({
                type: OBTENER_CAROUSEL_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}

export const EEPIndexEcoAccion = () => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_EEP_REQUEST})
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/ecoindex/indexeep')
        
        if (res.status === 202) {
            //console.log(res)
        }else{
            //console.log(res)
            dispatch({
                type: OBTENER_EEP_EXITO,
                payload: res.data
            })
        }
        
        
    } catch (error) {
        console.log(error)
    }
}