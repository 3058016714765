/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../service/ProductService';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from 'formik';
import { BlockUI } from 'primereact/blockui';
import convert from 'image-file-resize';

import logoPlace from '../../../../../assets/img/placeholder.png';



import axios from "axios";

const EditImgProducto = () => {

  let emptyProduct = {
    imagenPrincipal: null,
  };
    const [imagenT, setImagenT] = useState(null);
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const isMounted = useRef(false);
    const [product, setProduct] = useState(emptyProduct);
    const [productDialog, setProductDialog] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const [blockedPanel, setBlockedPanel] = useState(false);

    const btnRefInput = useRef();

    useEffect(() => {
      if (isMounted.current) {
          const summary = expandedRows !== null ? 'All Rows Expanded' : 'All Rows Collapsed';
          toast.current.show({severity: 'success', summary: `${summary}`, life: 3000});
      }
    }, [expandedRows]);


    useEffect(() => {
      setLoading(true)

      isMounted.current = true;
      const generarData = async () =>{
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/imgproductoindex');

        //onsole.log(res)
        setProducts(res.data);
        setLoading(false)
      }
      generarData()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
      initialValues: {
          id: '',
          imagenPrincipal: '',
          img1:'',
          img2:'',
          img3:'',
          img4:'',
      },
      onSubmit: (data) => {

        let error = 0;

        if (data.id && data.img1) {
          
          data.imagenPrincipal = data.img1;
          if (data.img1 && data.img2 && data.img3 && data.img4) {
            setLoading1(true)
            guardarData(data)
          }else{
            toast.current.show({severity: 'error', summary: 'Error.', detail: 'Error al Guardar las imágenes.', life: 3000});
          }

        }else{
          toast.current.show({severity: 'error', summary: 'Error.', detail: 'Error al Guardar las imágenes.', life: 3000});
        }
        //console.log(data)
      }
    });  

    const guardarData = async (data) =>{

      const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/save/imgproductopost`, data);

      //console.log(resSave)

      if (resSave.status === 200) {     
        toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Imágenes Guardadas.', life: 3000 });
        setLoading1(false)
        hideDialog()
      }else {
        setLoading1(false)
          toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Imágenes NO Guardados.', life: 3000 });
      }

      setLoading(true)

      isMounted.current = true;
      const generarData = async () =>{
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/imgproductoindex');

        //onsole.log(res)
        setProducts(res.data);
        setLoading(false)
      }
      generarData()

      //setLoading1(false)
    }

    const onRowExpand = (event) => {
      //toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    }

    const onRowCollapse = (event) => {
        //toast.current.show({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    }

    const expandAll = () => {
        let _expandedRows = {};
        products.forEach(p => _expandedRows[`${p.id}`] = true);

        setExpandedRows(_expandedRows);
    }

    const collapseAll = () => {
        setExpandedRows(null);
    }

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    }

    const amountBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    }

    const statusOrderBodyTemplate = (rowData) => {
        return <span className={`order-badge order-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    }

    const searchBodyTemplate = () => {
        return <Button icon="pi pi-search" />;
    }

    const imageBodyTemplate = (rowData) => {
        return <img src={`${rowData.imagenPrincipal}`} onError={(e) => e.target.src=logoPlace} alt={rowData.imagenPrincipal} className="product-image w-7rem" />;
    }

    const imageBodyTemplate1 = (rowData) => {
      return <img src={`${rowData.img1}`} onError={(e) => e.target.src=logoPlace} alt={rowData.img1} className="product-image w-6rem" />;
    }
    const imageBodyTemplate2 = (rowData) => {
      return <img src={`${rowData.img2}`} onError={(e) => e.target.src=logoPlace} alt={rowData.img2} className="product-image w-6rem" />;
    }
    const imageBodyTemplate3 = (rowData) => {
      return <img src={`${rowData.img3}`} onError={(e) => e.target.src=logoPlace} alt={rowData.img3} className="product-image w-6rem" />;
    }
    const imageBodyTemplate4 = (rowData) => {
      return <img src={`${rowData.img4}`} onError={(e) => e.target.src=logoPlace} alt={rowData.img4} className="product-image w-6rem" />;
    }

    const priceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.price);
    }

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    }

    const rowExpansionTemplate = (data) => {
      let datita = [
        data
      ]
        return (
            <div className="orders-subtable">
                <h5>Imágenes de {data.nombre}</h5>
                <DataTable value={datita} responsiveLayout="scroll">
                    <Column field="img1" header="Imagen 1" body={imageBodyTemplate1} sortable></Column>
                    <Column field="img2" header="Imagen 2" body={imageBodyTemplate2} sortable></Column>
                    <Column field="img3" header="Imagen 3" body={imageBodyTemplate3} sortable></Column>
                    <Column field="img4" header="Imagen 4" body={imageBodyTemplate4} sortable></Column>
                </DataTable>
            </div>
        );
    }

    const header = (
        <div className="table-header-container">
            <Button icon="pi pi-plus" label="Expandir Todos" onClick={expandAll} className="mr-2" />
            <Button icon="pi pi-minus" label="Colapsar Todos" onClick={collapseAll} className="mt-2 md:mt-0"/>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
          </React.Fragment>
      );
    }

    const hideDialog = () => {
      setProductDialog(false);
      formik.values.id = '';
      formik.values.img1 = '';
      formik.values.img2 = '';
      formik.values.img3 = '';
      formik.values.img4 = '';
      formik.resetForm();
      setProduct(emptyProduct);
    }

    const editProduct = (product) => {
      formik.values.id = product.id;
      setProduct({...product});
      setProductDialog(true);
    }
    const limpieza = () =>{
      btnRefInput.current.clear();
      //console.log(btnRefInput)
    }

    const myUploader = async (event) => {
      // convert file to base64 encoded
      setBlockedPanel(true)
      const tam = event.files.length;
      if (tam > 4) {
          toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 4 imágenes.', life: 3000 });
          setBlockedPanel(false)
      }else if (tam < 4) {
          toast.current.show({ severity: 'warn', summary: 'Muy Pocas Imágenes!', detail: 'Si o si ingrese 4 imágenes.', life: 3000 });
          setBlockedPanel(false)
      }else{
          let varcitos = []
          
          for (let index = 0; index < event.files.length; index++) {
              const file1 = event.files[index];
              //let imagenT = ''
              setImagenT(null)
              let imgTT = await convert({ 
                file: file1,  
                width: 330, 
                height: 340, 
                type: 'jpeg'
                })/*.then(async (resp) => {
                    // Response contain compressed and resized file
                    console.log(resp)
                    setImagenT(resp);
                    
                }).catch(error => {
                     // Error
                })*/
                  const data = new FormData();
                    data.append('file', file1); //imgTT
                    data.append('upload_preset', 'bersatimg')
                    data.append('cloud_name', 'ddnbi0nfz')   
                    
                    const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                        method: 'post',
                        body: data,

                    })

                    const fileImg = await res.json();
                    setImagenT(null)
                    if (index === 0) {
 
                      formik.values.img1 = fileImg.secure_url
                  }else{
                      if (index === 1) {
                          formik.values.img2 = fileImg.secure_url
                      }
                      if (index === 2) {
                          formik.values.img3 = fileImg.secure_url 
                      }
                      if (index === 3) {
                          formik.values.img4 = fileImg.secure_url
                      }   
                  }
          }


          setBlockedPanel(false)

          limpieza()
          toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágenes subidas al servidor', life: 3000 });
      }
  }

  return (
    <>
        <Toast ref={toast} />
        <div className='w-full overflow-scroll '>
              <div className="surface-section py-5 px-2 md:px-3 lg:px-5">
                  <div className="border-bottom-1 surface-border">
                      <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">GESTIÓN DE IMÁGENES EN PRODUCTOS</span>
                  </div>
              </div>
              <div className="datatable-rowexpansion-demo px-2 md:px-3 lg:px-5 mt-2">

                  <div className="card">
                      <DataTable value={products} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} paginator  rows={10} size="small"
                          onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} responsiveLayout="scroll" loading={loading} showGridlines  filterDisplay="row"
                          rowExpansionTemplate={rowExpansionTemplate} dataKey="id" header={header} scrollable>
                          <Column expander style={{ width: '3em' }} />
                          <Column field="nombre" header="Name" sortable />
                          <Column header="Imagen Principal" body={imageBodyTemplate} />
                          <Column field="codigo" header="Código" filter  showFilterMenu={false} filterPlaceholder="Buscar..."/>
                          <Column field="codigo_selkis" header="Código Selkis" sortable />
                          <Column header='Acciones' body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                      </DataTable>
                  </div>

                  <Dialog visible={productDialog} style={{ width: '450px' }} header="Producto Imágenes" modal className="p-fluid" onHide={hideDialog}>
                      {product.imagenPrincipal ? (
                        <img src={`${product.imagenPrincipal}`} onError={(e) => e.target.src=logoPlace} alt={product.imagenPrincipal} className="product-image block m-auto pb-3 w-7rem" />
                      )
                      :
                      (
                        <img src={`${product.imagenPrincipal}`} onError={(e) => e.target.src=logoPlace} alt={product.imagenPrincipal} className="product-image block m-auto pb-3 w-7rem" />
                      )}
                      <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                      <div className='field'>
                          <FileUpload name="demo[]" ref={btnRefInput}  url="https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload" cancelLabel='Cancelar' chooseLabel='Escoger' uploadLabel='Subir' multiple accept="image/*" maxFileSize={3000000}
                          customUpload='true' uploadHandler={myUploader} emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes aquí.</p>} />
                      </div>
                      <form onSubmit={formik.handleSubmit} className="">
                        <div className="field">
                            <label htmlFor="img1">Img1</label>
                            <InputText id="img1" name="img1" value={formik.values.img1} onChange={formik.handleChange} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="img2">Img2</label>
                            <InputText id="img2" name="img2" value={formik.values.img2} onChange={formik.handleChange} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="img3">Img3</label>
                            <InputText id="img3" name="img3" value={formik.values.img3} onChange={formik.handleChange} disabled />
                        </div>
                        <div className="field">
                            <label htmlFor="img4">Img4</label>
                            <InputText id="img4" name="img4" value={formik.values.img4} onChange={formik.handleChange} disabled  />
                        </div>
                        <div className='field'>
                          {
                            formik.values.img1 && formik.values.img2 && formik.values.img3 && formik.values.img4 ? (
                              
                                loading1 ? (

                                  <Button label="Guardando" type='button' loading={true} className="p-button-raised p-button-success px-6 mt-3" />
                                )
                                :
                                (
                                  <Button type="submit" className="p-button-raised p-button-success px-6 mt-3" label='Guardar Imágenes'></Button>  
                                )
                              
                            )
                            :
                            (
                              ''
                            )
                          }
                          
                        </div>
                      </form>
                      </BlockUI>
                  </Dialog>
              </div>           
          
        </div>
    </>
  )
}

export default EditImgProducto