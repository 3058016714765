/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */


import React, {useContext, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Helmet } from 'react-helmet-async';
import { Link, Outlet } from 'react-router-dom'

import imgCB from '../../../assets/img/kartinka_4.jpg'

import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'

import './Stylo.css';

const ContactoNew = () =>{

    const position = [-17.780987027932202, -63.204554498783146] 
    const options = {
        center: {lat: -17.780987027932202, lng: -63.204554498783146},
        zoom: 16
    };
    
    
    return(

        <>
        <Helmet>
          <title>Contacto - Bersati</title> 
        </Helmet>
            <div className='flex relative p-6 overflow-hidden bg-no-repeat bg-cover bg-left lg:bg-top h-45rem align-content-center' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imgCB})`}}>
                    
                    <div className='flex justify-content-end align-content-end text-center w-full bottom-0 '>

                        
                        <div className="md:hidden flex align-items-end  w-full text-6xl text-white font-light mb-4">Contáctanos para cotizar o resolver cualquier duda.</div>
                    </div>
                    
            </div>
            <div className='surface-section px-4 py-8 md:px-6 lg:px-8 h-auto'>
                
                <div className='grid '>
                    <div className="col-12 md:col-6">
                    <div className="text-900 text-2xl font-medium ">Contáctanos.</div>
                    <p className=' mt-1 mb-4 text-lg'>*Para cualquier duda mandanos un mensaje y nosotros te responderemos.</p>
                        <div className="p-fluid pr-0 md:pr-6">
                            <div className="field">
                                <label  className="font-medium">Nombre Completo</label>
                                <InputText id="name"  className="py-3 px-2 text-lg"/>
                            </div>
                            <div className="field">
                                <label className="font-medium">Email</label>
                                <InputText id="email"  className="py-3 px-2 text-lg"/> 
                            </div>
                            <div className="field">
                                <label  className="font-medium">Celular</label>
                                <InputText id="company"  className="py-3 px-2 text-lg"/> 
                            </div>
                            <div className="field">
                                <label  className="font-medium">Mensaje</label>
                                <InputTextarea rows={5} cols={30}  className="py-3 px-2 text-lg"/> 
                            </div>
                            <Button  label="Enviar Mensaje" icon="pi pi-send" className="w-auto surface-900 "/>
                        </div>
                    </div>
                    <div className='col-12 md:col-6 bg-no-repeat bg-right-bottom'>
                        
                        <div className="text-900 text-lg line-height-3 mb-6">Estamos aquí para ayudarte a darle vida a tus espacios. Visítanos en nuestra tienda o contáctanos para cotizaciones y asesoría personalizada. ¡Queremos ser parte de tu proyecto!</div>
                        <a href="https://maps.app.goo.gl/KxX1p21u22a1CC837"  rel="noreferrer"  target="_blank" className="inline-flex align-items-center text-blue-600 font-bold no-underline cursor-pointer">
                            <span className="mr-3">Ve la dirección en Google Maps</span>
                            <i className="pi pi-arrow-right"></i>
                        </a>
                        <ul className="list-none p-0 m-0 mt-6 text-700">
                            <p className="mr-3 text-xl">Nuestro teléfono de contacto.</p>

                            <li className="flex align-items-center mb-3 text-xl">
                                <i className="pi pi-phone mr-2"></i>
                                <span>+591 77668368</span>
                            </li>
                        </ul>
                        <Divider layout="horizontal" className="surface-border my-4 max-w-screen"></Divider>
                        <div className='mt-4'>

                            <MapContainer center={options.center} maxZoom={20} zoom={options.zoom} scrollWheelZoom={true} style={{width: '100%', minHeight: '320px'}} >
                                <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={position}>
                                    <Popup>
                                        BERSATI
                                    </Popup>
                                </Marker>
                                
                            </MapContainer>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default ContactoNew