/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {useContext, useState, useEffect, useRef} from 'react'

import { classNames } from 'primereact/utils';
import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { useFormik } from 'formik';

import axios from 'axios';

import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { UserContext } from '../../../../context/UserProvider';
import { PaymentContext } from '../../../../context/PaymentProvider';
import { cartIndexAccion } from '../../../../Redux/patos/CartDuck';

const PaymentPage = () => {
    
    const dispatch = useDispatch();
    const navegate = useNavigate();

    const {userLoading, userBer, userFire, signOutUser } = useContext(UserContext);
    //const { setUsuarioId, setTipoEnvio, setUserMap, setNacional, setLocal, productosFinal } = useContext(PaymentContext);
    const datas = useContext(PaymentContext);
    
  
    const toast = useRef(null);
    const [city, setCity] = useState(null);
    const [productos , setProductos] = useState([]);
    const [total, setTotal] = useState(0);
    const [envio, setEnvio] = useState(0);
    const [resOrder, setResOrder] = useState(null);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayModalQR, setDisplayModalQR] = useState(false);
    const categories = [{name: 'Pago ContraEnvío', key: '101'}, {name: 'Pago Por QR', key: '102'}];
    const {arrayCart, loadingCart} = useSelector(store => store.cartIndex)

    const [loadingBtn, setLoadingBtn] = useState(false);
    const [checked, setChecked] = useState([]);

    const [activeIndex, setActiveIndex] = useState(1);
    
    /*const itemSteps = [
        {label: 'Formulario',
        command: (event) => {
            navegate('/checkoutform')
        }},
        {label: 'Pago'},
        {label: 'Confirmación'}
      ];*/

      const itemSteps = [
        {label: 'Carrito'},
        {label: 'Formulario y Pago'},
        {label: 'Confirmación'}
      ];

      useEffect(()=>{
        const putData = () => {
            setProductos(arrayCart)
            //console.log(arrayCart)
        }

        if (loadingCart === false) {
            
            putData()


        }
       
    },[loadingCart])


    const formik = useFormik({
        initialValues: {
            usuario: '',
            tipoEnvio: '',
            tipoPago:'',
            map_cliente: '',
            departamento:'',
            ciudad:'',
            local:'',
            cantidad_prod: '',
            sub_total:'',
            total_envio:'',
            total_total:'',
            productos:[],


        },onSubmit: (data) => {
            setLoadingBtn(true)

                data.usuario = userBer.id;
                data.tipoEnvio = datas.tipoEnvio;
                data.tipoPago = city;
                data.map_cliente = datas.userMap;
                data.departamento = datas.nacional;
                data.ciudad = datas.nacional;
                data.local = datas.local;
                data.sub_total = total;
                if (datas.tipoEnvio === 3) {
                    data.total_envio = 0;
                    data.total_total = (total);
                }else if(datas.tipoEnvio === 2){
                    data.total_envio = 0;
                    data.total_total = (total);
                }else{
                    data.total_envio = envio;
                    data.total_total = (total+envio);
                }
                data.productos = datas.productosFinal;
            
            guardarData(data)
            console.log(data)
        }
    })

    useEffect(() => {

        const VerificarTokenN = () =>{
            let token = ''
              try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
                return token;
              } catch (error) {
                  localStorage.removeItem('_vita');
                  localStorage.removeItem('_dolce');
                  navegate('/login');
                  signOutUser()
                return 401;
              }
            
        }

        if (userLoading) {
            
        }else{
            if (userBer && userFire) {
                const tokenV =  VerificarTokenN();
                const id = userBer.id;
                const generarData = () =>{

                    dispatch(cartIndexAccion(tokenV, id))
                }
                generarData()
                
            }else{
                navegate('/')
            }
        }


    },[dispatch, userLoading])

    useEffect(() =>{
        if (datas.tipoEnvio) {
            if (datas.tipoEnvio === 1) {
                //console.log('Pedir LatLng')
                const id = userBer.id;
                const GenerarDatosPersonales = ()=>{
                   // const resDatita = axios.get(process.env.REACT_APP_UNSPLASH_URL+'/datosconsulta/'+id);
                }

                GenerarDatosPersonales()
            }
        }else{
            navegate('/checkoutform')
        }
    },[])

    useEffect(()=>{
        
        if (loadingCart === false) {
            if (datas.productosFinal.length > 0) {
                let price = 0; 
                let envio = 0;
                let ppEnvio = 0;

                const inputData = [...datas.productosFinal]

                for (let index = 0; index < datas.productosFinal.length; index++) {
                    if (datas.productosFinal[index]['producto_promo'].length > 0) {
                        let precioDescuento = (datas.productosFinal[index]['precio'] - (datas.productosFinal[index]['precio'] * (datas.productosFinal[index]['producto_promo'][0]['descuentoNumber'] / 100))).toFixed(2)

                        let precioProd = precioDescuento * datas.productosFinal[index]['cantidad']
                        if (datas.productosFinal[index]['producto_promo'][0]['envioGratis'] > 0) {
                            datas.productosFinal[index]['pEnvio'] = 0;
                            envio += 0;
                        } else{
                            
                            ppEnvio = CalcularDistancia(datas.userMap.latitud, datas.userMap.longitud, datas.productosFinal[index].precioPaquete) 
                            datas.productosFinal[index]['pEnvio'] = ppEnvio; 
                            envio += ppEnvio;
                        }
                        price += precioProd 
                    }else{
                        let precioProd = datas.productosFinal[index]['precio'] * datas.productosFinal[index]['cantidad'] 
                        ppEnvio = CalcularDistancia(datas.userMap.latitud, datas.userMap.longitud, datas.productosFinal[index].precioPaquete);
                        datas.productosFinal[index]['pEnvio'] = ppEnvio; 
                        envio += ppEnvio
                        price += precioProd  
                    }

                    if (datas.productosFinal[index]['armado'] && datas.productosFinal[index]['precio_armado']) {
                        const inputData1 = [...checked]
                        inputData1[index] = false;
                        setChecked(inputData1)

                    }
                    
                }
                setTotal(price)
                setEnvio(envio)
            }else{
                setTotal(0)
            }
        }

    },[productos])

    const guardarData = async(data) =>{
        setLoadingBtn(true)
        try {
            
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/ventabersati`, data);

            console.log(resSave)
            
            if (resSave.status === 200) {
                
                setResOrder(resSave.data)
                setDisplayModal(false)
                setLoadingBtn(true)
                setDisplayModalQR(true)

            }else{
                setLoadingBtn(true)
                setResOrder(null)
            }

        } catch (error) {
            setLoadingBtn(true)
            setDisplayModal(false)
        }

    }

    const CalcularDistancia = (latCliente, lngCliente, precioPaq) =>{
        const latBersati = -17.78106346785891;
        const lngBersati = -63.204554045666235;
        const latDelta = latCliente - latBersati;
        const lonDelta = lngCliente - lngBersati;

        //console.log(precioPaq)
        let distancia2 = (
            6371000 * 2 * Math.asin(
              Math.sqrt(
                Math.cos(latBersati) *  Math.cos(latCliente) * Math.pow(Math.sin(lonDelta / 2), 2) +
                Math.pow(Math.sin(latDelta / 2), 2)
              )
            )
          );

          let precio = 0;

        if (precioPaq === null) {
            precio = Math.round(distancia2 * 0.00010) 
        }else{
            precio = Math.round(distancia2 * precioPaq) 
        }
        return precio
    }

    

    const VerificarToken = () =>{
        let token = ''
        try {
        token = atob(localStorage.getItem('_vita'));
        //console.log(token)
        return token;
        } catch (error) {
            localStorage.removeItem('_vita');
            localStorage.removeItem('_dolce');
            navegate('/login');
            signOutUser()
        return 401;
        }
        
    }

    const DeleteItemCarrito = async (id, token) =>{

        const tok = 'Bearer ' + token;
        const config = {
            method: 'delete',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritodel/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
        }

        const res = await axios(config)

        return res;
    }

    const CantidadItemCarrito = async (id, token, cantidad) =>{

        const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'put',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritocantidad/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
            data: cantidad
        }

        const res = await axios(config)

        return res;
    }

    const CarritoDelete =  async (id) => {

        if (userBer && userFire) {
            const tokenV =  VerificarToken();
            //console.log(tokenV)
            if (tokenV === 401) {
                
            }else{
                const responFinal = await DeleteItemCarrito(id, tokenV)

               if (responFinal) {
                    if (responFinal.status === 202) {
                        toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }else if(responFinal.status === 200){
                        toast.current.show({severity:'info', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }
               }
            }
            
        }else{
            //console.log('Inicia Sesión')
        }
    }

    const CarritoCantidad =  async (id, cantidad) => {

        if (userBer && userFire) {
            const tokenV =  VerificarToken();
            //console.log(tokenV)
            if (tokenV === 401) {
                
            }else{
                const responFinal = await CantidadItemCarrito(id, tokenV, cantidad)

               if (responFinal) {
                    if (responFinal.status === 202) {
                        toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }else if(responFinal.status === 200){
                        toast.current.show({severity:'info', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }
               }
            }
            
        }else{
            //console.log('Inicia Sesión')
        }
    }


    const handleChangeCantidad = (onChangeValue, prod, i) =>{
        const inputData = [...productos]
        inputData[i].cantidad = onChangeValue.target.value;
        let data = {
            'cantidad': onChangeValue.target.value
        }
        setProductos(inputData)
        CarritoCantidad(prod.id, data)
    }

    const handleChangeDelete = (value, index) =>{
        const deleteVal = [...productos]
        deleteVal.splice(index,1);
        setProductos(deleteVal)
        CarritoDelete(value.id)
        //console.log(value)
    }

    const handleForm = () =>{
        formik.submitForm();
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" loading={loadingBtn}  icon="pi pi-times" onClick={() => setDisplayModal(false)} className="p-button-text text-900 hover:surface-100 mr-2" />
                <Button label="Si, proceder" loading={loadingBtn} className='surface-900' icon="pi pi-check" onClick={() => handleForm()} autoFocus />
            </div>
        );
    }

    const renderFooterQR = () => {
        return (
            <div>
                <Button label="Continuar" className='surface-900' icon="pi pi-check" onClick={() => cerrarQR()} autoFocus />
            </div>
        );
    }

    const cerrarQR = () =>{
        if (resOrder) {
            navegate('/confirmation/'+resOrder.order);
            setDisplayModalQR(false);
        }
    }

    const cerrarDialogConfirm = () =>{
        setDisplayModal(false)
    }

    const handleCheked = (target, index) =>{
        const inputData = [...checked]
        inputData[index] = target;
        setChecked(inputData)


        if (target) {
            //console.log('entraTarget')
            productos[index]['precio'] = parseInt(productos[index]['precio']) + parseInt(productos[index]['precio_armado']);
        }else if (!target) {
            productos[index]['precio'] = parseInt(productos[index]['precio']) - parseInt(productos[index]['precio_armado']);
        }
        
    }

    const PageCarrito = () =>{
        navegate('/paginacarrito')
    }

    //console.log(datas.productosFinal)
    
    //console.log(CalcularDistancia(datas.userMap.latitud, datas.userMap.longitud))
    
    return (
        <>
        <Toast ref={toast} position="center-right" className='z-5' />
            <div className=" px-4 py-8 md:px-6 lg:px-8">
                <div className='mb-2'>
                    <Steps model={itemSteps} activeIndex={activeIndex} readOnly={false}/> 
                </div>

                <div className="grid -mr-3 -ml-3">
                    <div className="col-12 lg:col-8 p-4">
                        <div className="surface-card grid formgrid border-round shadow-3 p-4">
                            <div className="flex w-full">
                                <div className="ml-0 lg:ml-5 p-2 w-full">
                                    <div className="mb-3">
                                        <span className="text-900 font-semibold text-2xl block ml-2">Elegir Tipo de Pago</span>
                                    </div>
                                    <div className="grid flex-column lg:flex-row">
                                        <div className="col p-1">
                                            {
                                                datas.tipoEnvio === 1 ?(
                                                    <div className='flex flex-column align-content-center border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                                                        <span className='text-600 text-sm mb-2'>*Seleccione un Tipo de Pago</span>
                                                        <div className="field-radiobutton my-2">
                                                            <RadioButton inputId="city1" name="city" value="101" onChange={(e) => setCity(e.value)} checked={city === '101'} />
                                                            <label htmlFor="city1">Pago ContraEntrega</label>
                                                        </div>
                                                        <div className="field-radiobutton my-2">
                                                            <RadioButton inputId="city1" name="city" value="102" onChange={(e) => setCity(e.value)} checked={city === '102'} />
                                                            <label htmlFor="city1">Pago por QR</label>
                                                        </div>
                                                    </div>
                                                ):null 
                                            }
                                            {
                                                datas.tipoEnvio === 2 ? (
                                                    <div className='flex flex-column align-content-center border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                                                        <div className="field-radiobutton my-2">
                                                            <RadioButton inputId="city1" name="city" value="102" onChange={(e) => setCity(e.value)} checked={city === '102'} />
                                                            <label htmlFor="city1">Pago por QR</label>
                                                        </div>
                                                    </div>
                                                ):null
                                            }
                                            {
                                                datas.tipoEnvio === 3 ?(
                                                    <div className='flex flex-column align-content-center border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                                                        <div className="field-radiobutton my-2">
                                                            <RadioButton inputId="city1" name="city" value="103" onChange={(e) => setCity(e.value)} checked={city === '103'} />
                                                            <label htmlFor="city1">Pago a la Hora de Recoger</label>
                                                        </div>
                                                        <div className="field-radiobutton my-2">
                                                            <RadioButton inputId="city1" name="city" value="102" onChange={(e) => setCity(e.value)} checked={city === '102'} />
                                                            <label htmlFor="city1">Pago por QR</label>
                                                        </div>
                                                    </div>
                                                ):null 
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full">
                                <div className="ml-0 lg:ml-5 p-2 w-full">
                                    <div className="mb-3">
                                        <span className="text-900 font-semibold text-2xl block ml-2">Total Carrito</span>
                                    </div>

                                    <div className="grid flex-column lg:flex-row">
                                        <div className="col p-1">
                                            <div className='flex flex-column align-content-center border-round surface-border border-1 p-4  hover:border-400 transition-duration-150'>
                                                {
                                                    loadingCart ? (
                                                        <p>Cargando...</p>
                                                    ):(
                                                        <>
                                                        
                                                        {datas.productosFinal.map((prod, index) =>(       
                                                            <div key={index} className=" py-4 flex flex-column lg:flex-row flex-wrap lg:align-items-center">
                                                                    {
                                                                        prod.producto_promo[0] ? (
                                                                            <img src={`${prod.producto_promo[0].imagenPP}`} alt={prod.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-8rem h-8rem mb-3 lg:mb-0 flex-shrink-0" />
                                                                        ):(
                                                                            <img src={`${prod.imagenPrincipal}`} alt={prod.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-8rem h-8rem mb-3 lg:mb-0 flex-shrink-0" />
                                                                        )
                                                                    }
                                                                    {/*<div className="flex-auto lg:ml-3">
                                                                        <div className="flex align-items-center justify-content-between mb-3">
                                                                            <span className="text-900 font-semibold">{prod.nombre+' - '+prod.codigo}</span>

                                                                            {
                                                                            prod.armado &&  prod.precio_armado ?(        
                                                                                <div className='flex align-items-center justify-content-between mb-3'>
                                                                                    <span className="text-900 font-semibold">{'Producto Armado?'}</span>
                                                                                    <Checkbox inputId="binary" checked={checked[index]} onChange={e => handleCheked(e.checked, index)} />
                                                                                    <InputSwitch checked={checked[index]} onChange={(e) => handleCheked(e.checked, index)} />
                                                                                </div>
                                                                            ):null
                                                                            }

                                                                            {
                                                                                prod.producto_promo[0] && (prod.producto_promo[0].descuentoNumber > 0 ) ? (
                                                                                    <div className='flex'>
                                                                                        <span className="text-900 ">  <span className='line-through '>BOB. {(prod.precio * prod.cantidad).toFixed(2)}</span> </span>
                                                                                        {
                                                                                            checked[index] === true? (
                                                                                                <span className="text-900 font-semibold ml-2 ">BOB. { (((prod.precio - (prod.precio * (prod.producto_promo[0].descuentoNumber / 100))) + prod.precio_armado ).toFixed(2)  * prod.cantidad).toFixed(2) }</span>
                                                                                            ):(

                                                                                                <span className="text-900 font-semibold ml-2 ">BOB. { ((prod.precio - (prod.precio * (prod.producto_promo[0].descuentoNumber / 100)) ).toFixed(2)  * prod.cantidad).toFixed(2) }</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                ):(
                                                                                    <span className="text-900 font-medium">BOB. {(prod.precio * prod.cantidad).toFixed(2)}</span>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="flex align-items-center justify-content-between mb-3"> 
                                                                            <span className="text-900 font-semibold">{'Delivery'}</span>
                                                                            {
                                                                                datas.tipoEnvio === 1 ? (
                                                                                    prod.producto_promo[0] && (prod.producto_promo[0].envioGratis === 1 ) ? (
                                                                                        <span className="text-600 mb-2 bg-green-500 text-sm text-center text-white w-7rem border-round-sm">{`ENVIO GRATIS`}</span>
                                                                                        
                                                                                    ): <span className="text-600">{'BOB. '+CalcularDistancia(datas.userMap.latitud, datas.userMap.longitud, prod.precioPaquete)}</span>
                                                                                ):null
                                                                            }
                                                                            {
                                                                                datas.tipoEnvio === 2 || datas.tipoEnvio === 3?(
                                                                                    <span className="text-600">{`No Aplica`}</span>
                                                                                ):null   
                                                                            }
                                                                        </div>
                                                                        
                                                                        <div className="flex flex-auto justify-content-between align-items-center">
                                                                            
                                                                            <InputNumber showButtons="true" buttonLayout="horizontal"  min="1" inputClassName="w-2rem text-center p-0 border-none surface-50" value={prod.cantidad} onValueChange={e=>handleChangeCantidad(e, prod, index)}
                                                                            decrementButtonClassName=" p-button-text text-600 hover:text-900" incrementButtonClassName="p-button-text text-600 hover:text-900" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></InputNumber>
                                                                            
                                                                            <span onClick={e=>handleChangeDelete(prod,index)} className="cursor-pointer text-red-600"><i className="pi pi-trash mr-2"></i>Eliminar</span>
                                                                        </div>
                                                                        </div> */}
                                                                        <div className="flex-auto lg:ml-3">
                                                                            <div className="flex align-items-center justify-content-between mb-3">
                                                                                <span className="text-900 font-semibold">{prod.nombre+' - '+prod.codigo}</span>

                                                                                {
                                                                                    prod.producto_promo[0] && (prod.producto_promo[0].descuentoNumber > 0 ) ? (
                                                                                        <div className='flex'>
                                                                                            <span className="text-900 ">  <span className='line-through '>BOB. {(prod.precio * prod.cantidad).toFixed(2)}</span> </span>
                                                                                            {
                                                                                                checked[index] === true? (
                                                                                                    <span className="text-900 font-semibold ml-2 ">BOB. { (((prod.precio - (prod.precio * (prod.producto_promo[0].descuentoNumber / 100))) + prod.precio_armado ).toFixed(2)  * prod.cantidad).toFixed(2) }</span>
                                                                                                ):(

                                                                                                    <span className="text-900 font-semibold ml-2 ">BOB. { ((prod.precio - (prod.precio * (prod.producto_promo[0].descuentoNumber / 100)) ).toFixed(2)  * prod.cantidad).toFixed(2) }</span>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    ):(
                                                                                        <span className="text-900 font-medium">BOB. {(prod.precio * prod.cantidad).toFixed(2)}</span>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div className="flex align-items-center justify-content-between mb-3"> 
                                                                                <span className="text-900 font-semibold">{'Delivery'}</span>
                                                                                {
                                                                                    datas.tipoEnvio === 1 ? (
                                                                                        prod.producto_promo[0] && (prod.producto_promo[0].envioGratis === 1 ) ? (
                                                                                            <span className="text-600 mb-2 bg-green-500 text-sm text-center text-white w-7rem border-round-sm">{`ENVIO GRATIS`}</span>
                                                                                            
                                                                                        ): <span className="text-600">{'BOB. '+CalcularDistancia(datas.userMap.latitud, datas.userMap.longitud, prod.precioPaquete)}</span>
                                                                                    ):null
                                                                                }
                                                                                {
                                                                                    datas.tipoEnvio === 2 || datas.tipoEnvio === 3?(
                                                                                        <span className="text-600">{`No Aplica`}</span>
                                                                                    ):null   
                                                                                }
                                                                            </div>

                                                                            <div className="flex flex-auto justify-content-between align-items-center">
                                                                                <span className="text-900 font-semibold">{'Cantidad'}</span>

                                                                                <span className="text-600">{prod.cantidad + ' item(s)'}</span>
                                                                            </div>
                                                                            {
                                                                                prod.armado === 1 && prod.isArmado === true ? (

                                                                                    <div className="flex flex-auto justify-content-between align-items-center mt-3">
                                                                                        <span className="text-900 font-semibold">{'Producto Armado? '}</span>

                                                                                        <span className="text-600">{'Si (Costo ya incluido por unidad.)'}</span>
                                                                                    </div>
                                                                            
                                                                                ):null
                                                                            }
                                                                        </div>
                                                                    
                                                            </div>
                                                        ))}
                                                        {
                                                            datas.tipoEnvio === 1 ?(
                                                                <p className='mb-0 mt-6'>*El precio del delivery se aplica por producto</p>
                                                                
                                                            ):null
                                                            
                                                        }
                                                        {
                                                            datas.tipoEnvio === 2 ?(
                                                                <p className='mb-0 mt-6'>*Los productos se enviarán por pagar al cliente.</p>
                                                            ):null
                                                        }
                                                            <p className='mb-0 mt-1'>*Para editar los productos, vuelva a la página del carrito. <span className='font-bold cursor-pointer' onClick={PageCarrito}>( Volver )</span></p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 md:col-4 lg:col-4 p-4'>

                        <div className="surface-card py-2 shadow-3 border-round">
                            <div className="flex flex-column align-items-center py-2">
                                <div className="text-900 text-4xl mb-4 font-medium">Detalle de la Compra</div>

                                <div className='text-900 text-2xl font-normal mb-4'>SubTotal: <span className='font-medium'>BOB. {(total).toFixed(2)}</span></div>
                                {
                                    datas.tipoEnvio === 3 ||  datas.tipoEnvio === 2 ?(
                                        <>
                                            <div className='text-900 text-2xl font-normal mb-4'>Total del Envío: <span className='font-medium'>BOB. {0}</span></div>
                                            <div className='text-900 text-3xl font-normal mb-4'>TOTAL: <span className='font-medium'>BOB. {(total).toFixed(2)}</span></div>
                                        </>
                                    ):(
                                        <>
                                            <div className='text-900 text-2xl font-normal mb-4'>Total del Envío: <span className='font-medium'>BOB. {(envio).toFixed(2)}</span></div>
                                            <div className='text-900 text-3xl font-normal mb-4'>TOTAL: <span className='font-medium'>BOB. {(total+envio).toFixed(2)}</span></div>
                                        </>
                                    )
                                }
    
                                
                                <div className='flex justify-content-center p-2 border-top-1 w-full'>
                                    <Button onClick={() =>setDisplayModal(true)} disabled={city ? false : true} label="Proceder a Pagar" className='surface-900 mt-4'></Button>
                                </div>
                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Dialog header="Confirmar Compra" visible={displayModal} modal={false} style={{ width: '50vw' }} footer={renderFooter('displayModal')} onHide={() => cerrarDialogConfirm()}>
                <div className="flex align-content-center flex-wrap p-2">
                    <i className="flex align-items-center pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    <span className='flex align-items-center text-800 text-2xl'>Confirma la compra de los productos ?</span>
                </div>
            </Dialog>

            <Dialog header="Pago por QR" modal style={{ width: '50vw' }} visible={displayModalQR} onHide={() => setDisplayModalQR(false)} footer={renderFooterQR}>
                <div className="flex flex-wrap border-top-1 surface-border pt-4">
                    <div className="bg-blue-50 flex align-items-center justify-content-center py-3 px-0 w-full md:w-4 border-round">
                        <img src="assets/images/blocks/illustration/security.svg" alt="ImageQR" className="w-9" />
                    </div>
                    <p className="text-700 line-height-3 m-0 p-5 w-full md:w-8">
                        Favor escanéa el código QR para poder cancelar por tu pedido, no te preocupes si se cierra esta ventana, podrás verlo de nuevo desde tus pedidos.
                    </p>
                    <p className="text-700 line-height-3 m-0 p-5 w-full md:w-8">
                        Una vez hecho el pago, tienes que subir el comprobante.
                        La confirmación del pago son aproximadamente 12 horas.
                    </p>
                </div>
            </Dialog>
        </>
    )
}

export default PaymentPage