/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */


import { useEffect, useContext, useState, useRef } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { Steps } from 'primereact/steps';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from 'formik';
import { BlockUI } from 'primereact/blockui';


import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { UserContext } from '../../../../context/UserProvider';
import { comprasUserIndexAccion } from '../../../../Redux/patos/ComprasUserDuck';

import Cargando from '../../comps/Cargando';

import axios from 'axios';

const MisCompras = () => {

    const navegate = useNavigate();
    //const [cargando, setCargando] = useState(true) ;
    const dispatch = useDispatch();


    const {userLoading, userBer, userFire, signOutUser } = useContext(UserContext);
    const toast = useRef(null);
    const btnRefInput = useRef();

    const [comprasUser, setComprasUser] = useState([])
    const [compDialog, setCompDialog] = useState(false);
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    
    
    const {arrayCompras, loadingCompras} = useSelector(store => store.comprasUser)

    const formik = useFormik({
        initialValues: {
            imagenPago: '',
            num_order: '',
            user_id:'',
            error: 0
  
        },onSubmit: (data) => {
            //console.log(data)
            data.error = 0;
            if (data.imgUrl === '' || data.imgUrl === null || data.imgUrlRespon === '' || data.imgUrlRespon === null) {
                data.error = 1;
            }else{
                guardarData(data)
            }

            if (data.error > 1) {
                toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Error al guardar los datos....', life: 3000 });
            }
        }
      });
      const generarDatita = () =>{
        const id = userBer.id;
        const generarData = () =>{

            //dispatch(comprasUserIndexAccion(tokenV, id))
            dispatch(comprasUserIndexAccion(id))
        }
        generarData()
      }
      const guardarData = (data) => {
        //console.log(data)
        setLoadingBtn(true)

            const guardar = async (data) =>{
                const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/comprobante`, data);

                //console.log(resSave)

                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Imágen guardada con éxito.', life: 3000 });
                    
                    formik.resetForm();
                    formik.values.imagenPago = ''
                    formik.values.num_order = ''
                    formik.values.user_id = ''
                    setLoadingBtn(false)
                    hideDialogComp()
                }else if(resSave.status === 202){
                    toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Imágen NO guardada.', life: 3000 });
                    setLoadingBtn(false)
                    formik.values.imagenPago = ''
                }

                generarDatita()
            }

        guardar(data)

      }

    useEffect(() => {

        const VerificarTokenN = () =>{
            let token = ''
              try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
                return token;
              } catch (error) {
                  localStorage.removeItem('_vita');
                  localStorage.removeItem('_dolce');
                  navegate('/login');
                  signOutUser()
                return 401;
              }
            
        }

        if (userLoading) {
            
        }else{
            if (userBer && userFire) {
                const tokenV =  VerificarTokenN();
                const id = userBer.id;
                const generarData = () =>{

                    //dispatch(comprasUserIndexAccion(tokenV, id))
                    dispatch(comprasUserIndexAccion(id))
                }
                generarData()
                
            }else{
                navegate('/')
            }
        }


    },[dispatch, userLoading])

    useEffect(() => {
        const putData = () => {
            const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

            if (arrayCompras.length > 0) {
                for (let index = 0; index < arrayCompras.length; index++) {
                    let fechita = new Date(arrayCompras[index]['created_at']);
                    
                    //comprasUser[index]['created_at'] = fechita.toLocaleDateString('es-Es', opciones);
                    //comprasUser[index]['fecha_compra'] = fechita.toLocaleDateString('es-Es', opciones);

                    const inputData = [...arrayCompras]
                    inputData[index]['fecha_compra'] = fechita.toLocaleDateString('es-Es', opciones);
                    setComprasUser(inputData)
                }
            }

            //setComprasUser(arrayCompras)
        }
        
        if (loadingCompras === false) {
            
            putData()
            
            
        }
        
    },[loadingCompras])

    /*useEffect(() => {
        const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

        const putData = () => {
            if (loadingCompras === false) {
                if (comprasUser.length > 0) {
                    for (let index = 0; index < comprasUser.length; index++) {
                        let fechita = new Date(comprasUser[index]['created_at']);
                        
                        //comprasUser[index]['created_at'] = fechita.toLocaleDateString('es-Es', opciones);
                        //comprasUser[index]['fecha_compra'] = fechita.toLocaleDateString('es-Es', opciones);
    
                        const inputData = [...comprasUser]
                        inputData[index]['fecha_compra'] = fechita.toLocaleDateString('es-Es', opciones);
                        setComprasUser(inputData)
                    }
                }
            }
        }

        //putData()


    },[comprasUser])*/

    //console.log(comprasUser)

    const openDialogComp = (num_order, user_id) => {
        formik.values.num_order = num_order;
        formik.values.user_id = user_id;
        setCompDialog(true)
    }

    const hideDialogComp = () => {
        formik.values.num_order = '';
        formik.values.user_id = '';
        formik.values.imagenPago = '';

        setCompDialog(false)
    }

    const limpieza = () => {
        btnRefInput.current.clear();
        //console.log(btnRefInput)
    }

    const myUploader = async (event) => {


        setBlockedPanel(true)
        const tam = event.files.length;

        //console.log(tam)
        if (tam > 1) {
            //console.log('entra,,,,,')
            toast.current.show({ severity: 'error', summary: 'Demasiadas Imágenes!', detail: 'Un máx. de 1 imágen.', life: 3000 });
        }else if (tam === 0) {
            toast.current.show({ severity: 'warn', summary: 'Sin Imágenes!', detail: 'Si o si ingrese 1 imágen.', life: 3000 });
        }else{
            const file = event.files[0];

            const data = new FormData();
                data.append('file', file);
                data.append('upload_preset', 'bersatimg')
                data.append('cloud_name', 'ddnbi0nfz')   
                
            const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
                method: 'post',
                body: data,

            })

            const fileImg = await res.json();

            formik.values.imagenPago = fileImg.secure_url
            toast.current.show({ severity: 'success', summary: 'Éxito en la Acción!', detail: 'Imágen subidas al servidor', life: 3000 });
            limpieza()
        }

        
        setBlockedPanel(false)
        
      }

  return (
    <div className="surface-0 px-4 py-8 md:px-6 lg:px-8">
        <Toast ref={toast} />
        <div className="flex flex-column md:flex-row justify-content-between align-items-center mb-4">
            <div className="flex flex-column text-center md:text-left">
                <span className="text-900 text-5xl mb-2 font-bold ">Mis Pedidos</span>
            </div>
        </div>

        {
            loadingCompras ? (
                <div className='mt-6'>
                    <Cargando></Cargando>
                </div>
            ):(
                comprasUser.length > 0 ? (

                    comprasUser.map((comp, index) => (

                        <div key={index} className='shadow-2 mt-6'>
                            <div className="surface-card grid grid-nogutter border-round">
                                <div className="col-12 flex p-2 surface-100 border-round-top">
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Orden de Compra</span>
                                        <span className="text-900 font-medium block mt-2">{comp.num_order}</span>
                                    </div>
                                    <p-divider align="center" layout="vertical" styleclassName="h-full  mx-0 lg:mx-3 surface-border"></p-divider>
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Fecha de la Compra</span>
                                        <span className="text-900 font-medium block mt-2">{comp.fecha_compra}</span>
                                    </div>
                                    <p-divider align="center" layout="vertical" styleclassName="h-full  mx-0 lg:mx-3 surface-border"></p-divider>
                                    <div className="p-2 flex-auto text-center md:text-left">
                                        <span className="text-600 block">Monto Total</span>
                                        <span className="text-900 font-medium block mt-2">BOB. {comp.total_total}</span>
                                    </div>
                                    <p-divider align="center" layout="vertical" styleclassName="h-full  mx-0 lg:mx-3 surface-border"></p-divider>
                                    {
                                        comp.tipo_pago === 102 ? (
                                            <div className="p-2 flex-auto text-center md:text-left">
                                                <span className="text-600 block">Comprobante de Pago Adjuntado</span>
                                                {
                                                    comp.comprobante_de_pago.length > 0 ? (
                                                        <span className="text-900 font-medium block mt-2">Si</span>
                                                    ):(
                                                        <span className="text-900 font-medium block mt-2">No</span>
                                                    )
                                                }
                                                
                                            </div>
                                        ):null
                                    }
                                </div>
                            </div>
                        
                            <div className="col-12">
                                {
                                    comp.productos_venta_bersati.map((prods, indexP) => (

                                        <div key={indexP} className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
                                            <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                                                <img src={`${prods.imagenPrincipal}`} alt={prods.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-8rem h-8rem mr-3 flex-shrink-0" />
                                                <div className="flex flex-column my-auto text-center md:text-left">
                                                    <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">{prods.nombre}</span>
                                                    <span className="text-600 text-sm mb-3">{prods.codigo} | {prods.marca}</span>
                                                    <span className="text-600 text-sm mb-3">Cantidad: {prods.cantidad_prod} | P.U.: BOB. {prods.precio}</span>
                                                    
                                                </div>
                                            </div>
                                            {
                                                comp.confirmado === 1 ?(
                                                    <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{borderRadius:"2.5rem"}}>
                                                        <span className="bg-cyan-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{minWidth:"2rem", minHeight: "2rem"}}>
                                                            <i className="pi pi-check"></i>
                                                        </span>     
                                                        <span className="text-cyan-700">{comp.nombreCicloPedido}</span>
                                                    </div>        
                                                ):null
                                            }
                                            {
                                                comp.confirmado === 2 ?(
                                                    <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{borderRadius:"2.5rem"}}>
                                                        <span className="bg-yellow-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{minWidth:"2rem", minHeight: "2rem"}}>
                                                            <i className="pi pi-money-bill"></i>
                                                        </span>     
                                                        <span className="text-yellow-700">{comp.nombreCicloPedido}</span>
                                                    </div>
                                                ):null
                                            }
                                            {
                                                comp.confirmado === 3 ?(
                                                    <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{borderRadius:"2.5rem"}}>
                                                        <span className="bg-teal-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{minWidth:"2rem", minHeight: "2rem"}}>
                                                            <i className="pi pi-verified"></i>
                                                        </span>     
                                                        <span className="text-teal-700">{comp.nombreCicloPedido}</span>
                                                    </div>
                                                ):null
                                            }
                                            {
                                                comp.confirmado === 5 ?(
                                                    <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{borderRadius:"2.5rem"}}>
                                                        <span className="bg-gray-500 text-white flex align-items-center justify-content-center border-circle mr-2" style={{minWidth:"2rem", minHeight: "2rem"}}>
                                                            <i className="pi pi-cog"></i>
                                                        </span>     
                                                        <span className="text-gray-700">{comp.nombreCicloPedido}</span>
                                                    </div>
                                                ):null
                                            }
                                        </div>
                                    ))
                                }
                                
                            </div>

                            <div className="col-12 p-0 flex border-top-1 surface-border" style={{borderBottomLeftRadius: "6px"}}>
                                <a onClick={() => openDialogComp(comp.num_order, comp.user_id)} className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-color hover:surface-900 hover:text-0 transition-duration-150 w-full" style={{borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px"}}><i className="pi pi-folder mr-2 mb-2 md:mb-0"></i>Subir Comprobante</a>
                            </div>


                        </div>
                    ))

                ):(
                    <p>Sin compras aún...</p>
                )
            )
        }

        <Dialog visible={compDialog} style={{ width: '450px' }} header="Subida de Comprobante de Pago" modal className="p-fluid"  onHide={hideDialogComp}>
            <p className='text-color-secondary text-sx'>*Seleccione la imagen del comprobante de pago, oprima el botón de subir y luego el botón de guardar.</p>
            <BlockUI blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '3rem'}}></i>}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field">
                        <FileUpload className='mt-2' ref={btnRefInput} name="demo[]" chooseLabel='Escoger' uploadLabel='Subir' cancelLabel='Cancelar' url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' onUpload={''} multiple accept="image/*" customUpload='true' uploadHandler={myUploader}  maxFileSize={1000000}
                            emptyTemplate={<p className="m-0">Arrastra y suelta las imágenes para subirlas!</p>} />    
                    </div>
                    {
                        formik.values.imagenPago ? (
                            <Button type="submit" loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                        )
                        :
                        (
                            ''
                        )
                    }
                </form>
            </BlockUI>
        </Dialog>

    </div>
  )
}

export default MisCompras