/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { FileUpload } from 'primereact/fileupload';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DataScroller } from 'primereact/datascroller';
import { Rating } from 'primereact/rating';
import { PickList } from 'primereact/picklist';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';

import {useDispatch, useSelector} from 'react-redux'

import axios from "axios";


import { ventasPCIndexAccion } from '../../../../../Redux/patos/ComprasUserDuck'; 
import { ventasPCI2ndexAccion } from '../../../../../Redux/patos/ComprasUserDuck'; 
import { ventasPCI3ndexAccion } from '../../../../../Redux/patos/ComprasUserDuck'; 

const VentasBersati = () => {

    const dispatch = useDispatch();
    const toast = useRef(null);

    const [productoShow, setProductoShow] = useState([])
    const [productoShow2, setProductoShow2] = useState([])
    const [productoShow3, setProductoShow3] = useState([])
    const [selectedProduct1, setSelectedProduct1] = useState(null);
    const [selectedProduct2, setSelectedProduct2] = useState(null);
    const [selectedProduct3, setSelectedProduct3] = useState(null);

    const [dialogVenta1, setDialogVenta1] = useState(false);
    const [dialogVenta2, setDialogVenta2] = useState(false);
    const [dialogVenta3, setDialogVenta3] = useState(false);

    const [dataShow1, setDataShow1] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);

    useEffect(() => {

        dispatch(ventasPCIndexAccion())        
        dispatch(ventasPCI2ndexAccion())  
        dispatch(ventasPCI3ndexAccion())  
        
    },[dispatch])


    const recagarData1 = () =>{
        dispatch(ventasPCIndexAccion()) 
    }
    const recagarData2 = () =>{
        dispatch(ventasPCI2ndexAccion()) 
    }
    const recagarData3 = () =>{
        dispatch(ventasPCI3ndexAccion())
    }

    const formik = useFormik({
        initialValues: {
            confirmar_pago: '',
            num_order: '',
            id_venta:'',
            tipo_pago:''

        },onSubmit: (data) => {

            //console.log(data)
            guardarData(data)
        }
    })

    const guardarData = (data) =>{
        setLoadingBtn(true)

        const guardar = async (data) =>{
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/confirmarventa`, data);

            console.log(resSave)
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                
                formik.resetForm();
              
                setLoadingBtn(false)
                hideDialog1()
                hideDialog2()
                hideDialog3()
            }else if(resSave.status === 202){
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setLoadingBtn(false)
                   
            }

            recagarData1()
            recagarData2()
            recagarData3()
        }

        guardar(data)
    }
    
    const {arrayVentaPC, loadingVentaPC} = useSelector(store => store.ventasBPC)
    const {arrayVentaPC2, loadingVentaPC2} = useSelector(store => store.ventasBPC2)
    const {arrayVentaPC3, loadingVentaPC3} = useSelector(store => store.ventasBPC3)


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <Button icon="pi pi-eye" placeholder="Left" tooltip="Ver Detalle" tooltipOptions={{position: 'left'}} onClick={() => openDialog1(rowData)} className="p-button-rounded p-button-info"  />
                </div>
            </React.Fragment>
        );
    }

    const actionBodyTemplate2 = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <Button icon="pi pi-eye" placeholder="Left" tooltip="Ver Detalle" tooltipOptions={{position: 'left'}} onClick={() => openDialog2(rowData)} className="p-button-rounded p-button-info"  />
                </div>
            </React.Fragment>
        );
    }

    const actionBodyTemplate3 = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <Button icon="pi pi-eye" placeholder="Left" tooltip="Ver Detalle" tooltipOptions={{position: 'left'}} onClick={() => openDialog3(rowData)} className="p-button-rounded p-button-info"  />
                </div>
            </React.Fragment>
        );
    }

    const fechaBody = (rowData) =>{
        const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        let fechita = new Date(rowData['created_at']);
        return(
            <React.Fragment>
                <p>{fechita.toLocaleDateString('es-Es', opciones)}</p>
            </React.Fragment>
        )
    }


    const actionBodyComprobante = (rowData) =>{
        

        if (rowData.comprobante_de_pago.length > 0) {
            return (
                    <React.Fragment>
                        <p className='p-2 bg-green-300 border-round-sm'>Si</p>
                    </React.Fragment>
                )
        }else{
             return (
                    <React.Fragment>
                        <p>No</p>
                    </React.Fragment>
                )
        } 
            
        
    }

    const actionBodyTipoPago = (rowData) =>{
        if (rowData.tipo_pago === 101) {
            return (
                <React.Fragment>
                    <span className="text-900 mb-1">Pago ContraEntrega</span>
                </React.Fragment>
            )
        }
        if (rowData.tipo_pago === 102) {
            return (
                <React.Fragment>
                    <span className="text-900 mb-1">Pago por QR</span>
                </React.Fragment>
            )
        }
        if (rowData.tipo_pago === 103) {
            return (
                <React.Fragment>
                    <span className="text-900 mb-1">Pago a la Hora de Recoger (Retiro en Local)</span>
                </React.Fragment>
            )
        }
    }

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" onClick={() => recagarData1()} className="p-button-text" />;
    const paginatorLeft2 = <Button type="button" icon="pi pi-refresh" onClick={() => recagarData2()} className="p-button-text" />;
    const paginatorLeft3 = <Button type="button" icon="pi pi-refresh" onClick={() => recagarData3()} className="p-button-text" />;


    const onRowSelect = async (rowData) =>{
        setProductoShow(rowData.data.productos_venta_bersati)
    }
    const onRowSelect2 = async (rowData) =>{
        setProductoShow2(rowData.data.productos_venta_bersati)
    }
    const onRowSelect3 = async (rowData) =>{
        setProductoShow3(rowData.data.productos_venta_bersati)
    }

    const header1 = (
        <div className="text-4xl">
            <h5 className="mx-0 my-1">Envío Domicilio</h5>
        </div>
    );
    
    const header2 = (
        <div className="text-4xl">
            <h5 className="mx-0 my-1">Envío Departamental</h5>
        </div>
    );

    const header3 = (
        <div className="text-4xl">
            <h5 className="mx-0 my-1">Recojo en Local</h5>
        </div>
    );

    const openDialog1 = (rowData) =>{
        console.log(rowData)
        setDataShow1(rowData)
        setDialogVenta1(true)
    }
    const openDialog2 = (rowData) =>{
        setDataShow1(rowData)
        setDialogVenta2(true)
    }

    const openDialog3 = (rowData) =>{
        setDataShow1(rowData)
        setDialogVenta3(true)
    }

    const hideDialog1 = () =>{
        setDataShow1([])
            formik.values.confirmar_pago = '';
            formik.values.num_order = '';
            formik.values.id_venta = '';
            formik.values.tipo_pago = '';
        setDialogVenta1(false)
    }
    const hideDialog2 = () =>{
        setDataShow1([])
        formik.values.confirmar_pago = '';
            formik.values.num_order = '';
            formik.values.id_venta = '';
            formik.values.tipo_pago = '';
        setDialogVenta2(false)
    }
    const hideDialog3 = () =>{
        setDataShow1([])
        formik.values.confirmar_pago = '';
            formik.values.num_order = '';
            formik.values.id_venta = '';
            formik.values.tipo_pago = '';
        setDialogVenta3(false)
    }
    //console.log(productoShow)

    const confirmarVenta1 = (rowD) =>{

            formik.values.confirmar_pago = 1;
            formik.values.num_order = rowD.num_order;
            formik.values.id_venta = rowD.id;
            formik.values.tipo_pago = rowD.tipo_pago;
        console.log(rowD)
        formik.handleSubmit()
    }

    const confirmarVenta2 = (rowD) =>{

            formik.values.confirmar_pago = 1;
            formik.values.num_order = rowD.num_order;
            formik.values.id_venta = rowD.id;
            formik.values.tipo_pago = rowD.tipo_pago;
        console.log(rowD)
    }

    const confirmarVenta3 = (rowD) =>{

            formik.values.confirmar_pago = 1;
            formik.values.num_order = rowD.num_order;
            formik.values.id_venta = rowD.id;
            formik.values.tipo_pago = rowD.tipo_pago;
        console.log(rowD)
    }

  return (
    <div className='w-full overflow-scroll speeddial-linear-demo '>
 <Toast ref={toast} />
        <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
        
            <div className="border-bottom-1 surface-border">
                <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">Ventas Por Confirmar</span>
            </div>    

            <div className='grid w-full px-2 md:px-3 lg:px-5 m-0 mt-4'>
                <div className='col-12 md:col-8 lg:col-8  surface-border'>
                    <div className="card w-full shadow-2" >

                        <DataTable value={arrayVentaPC}  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loadingVentaPC} emptyMessage='Sin datos...'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" paginatorLeft={paginatorLeft}
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} ventas"  showGridlines responsiveLayout="scroll" header={header1}
                            scrollHeight="400px" selectionMode="single" dataKey="id" onRowDoubleClick={onRowSelect} selection={selectedProduct1} onSelectionChange={e => setSelectedProduct1(e.value)}
                            //expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}  rowExpansionTemplate={rowExpansionTemplate} 
                        >

                            
                            <Column field='num_order' header='Número de Orden' className='font-bold' style={{width:'15%'}}></Column>
                            <Column  body={actionBodyTipoPago} header='Tipo de Pago' />
                            <Column field='tipo_envioTms' header='Tipo de Envío' />
                            <Column field='nombreCicloPedido' header='Estado del Pedido' />
                            <Column header='Fecha del Pedido' body={fechaBody} style={{minWidth:'100px'}}/>
                            <Column field='cantidad_prod' header='Cantidad de Items' style={{minWidth:'50px'}}/>
                            <Column field='sub_total' header='Sub Total' />
                            <Column field='total_envio' header='Precio Envío' />
                            <Column field='total_total' header='Total' />
                            <Column header='Comprobante Adjuntado' body={actionBodyComprobante} />
                            <Column header='Acciones' body={actionBodyTemplate} style={{minWidth:'20%'}}></Column>
                        </DataTable>

                    </div>
                </div>

                <div className='col-12 md:col-4 lg:col-4 surface-border'>
                    <div className="card w-full shadow-2" >
                        <DataTable  value={productoShow} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} emptyMessage='Sin venta seleccionada..'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos."  showGridlines responsiveLayout="scroll" 
                            >
                            

                            <Column field='nombre' header='Producto' className='font-bold' style={{width:'15%'}} frozen></Column>
                            <Column field='codigo' header='Código' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='marca' header='Marca' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='cantidad_prod' header='Cantidad' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='isArmado' header='Producto Armado' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_armado' header='Precio Armado' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio' header='Precio Producto' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_envio' header='Precio Envio' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_vendido' header='Precio Final xU' className='' style={{width:'15%'}} frozen></Column>

                        </DataTable>
                    </div>
                </div>

            </div>   

            <Divider className='my-6'/>


            <div className='grid w-full px-2 md:px-3 lg:px-5 m-0 mt-4'>
                <div className='col-12 md:col-8 lg:col-8  surface-border'>
                    <div className="card w-full shadow-2" >

                        <DataTable value={arrayVentaPC2}  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loadingVentaPC2} emptyMessage='Sin datos...'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" paginatorLeft={paginatorLeft2}
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} ventas"  showGridlines responsiveLayout="scroll" header={header2}
                            scrollHeight="400px" selectionMode="single" dataKey="id" onRowDoubleClick={onRowSelect2} selection={selectedProduct2} onSelectionChange={e => setSelectedProduct2(e.value)}
                            //expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}  rowExpansionTemplate={rowExpansionTemplate} 
                        >

                            <Column field='num_order' header='Número de Orden' className='font-bold' style={{width:'8%'}}></Column>
                            <Column  body={actionBodyTipoPago} header='Tipo de Pago' style={{minWidth:'30%'}}/>
                            <Column field='tipo_envioTms' header='Tipo de Envío' />
                            <Column header='Fecha del Pedido' body={fechaBody} style={{minWidth:'100px'}}/>
                            <Column field='departamentoText' header='Departamento' />
                            <Column field='ciudad' header='Ciudad' />
                            <Column field='nombreCicloPedido' header='Estado del Pedido' />
                            <Column field='cantidad_prod' header='Cantidad de Items' />
                            <Column field='sub_total' header='Sub Total' />
                            <Column field='total_envio' header='Precio Envío' />
                            <Column field='total_total' header='Total' />
                            <Column header='Comprobante Adjuntado' body={actionBodyComprobante}/>
                            <Column header='Acciones' body={actionBodyTemplate2} ></Column>
                        </DataTable>

                    </div>
                </div>

                <div className='col-12 md:col-4 lg:col-4 surface-border'>
                    <div className="card w-full shadow-2" >
                        <DataTable  value={productoShow2} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} emptyMessage='Sin venta seleccionada..'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos."  showGridlines responsiveLayout="scroll" 
                            >
                            

                            <Column field='nombre' header='Producto' className='font-bold' style={{width:'15%'}} frozen></Column>
                            <Column field='codigo' header='Código' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='marca' header='Marca' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='cantidad_prod' header='Cantidad' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='isArmado' header='Producto Armado' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_armado' header='Precio Armado' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio' header='Precio Producto' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_envio' header='Precio Envio' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_vendido' header='Precio Final xU' className='' style={{width:'15%'}} frozen></Column>

                        </DataTable>
                    </div>
                </div>
            </div>


            <Divider className='my-6'/>


            <div className='grid w-full px-2 md:px-3 lg:px-5 m-0 mt-4'>
                <div className='col-12 md:col-8 lg:col-8  surface-border'>
                    <div className="card w-full shadow-2" >

                        <DataTable value={arrayVentaPC3}  paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loadingVentaPC3} emptyMessage='Sin datos...'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" paginatorLeft={paginatorLeft3}
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} ventas"  showGridlines responsiveLayout="scroll" header={header3}
                            scrollHeight="400px" selectionMode="single" dataKey="id" onRowDoubleClick={onRowSelect3} selection={selectedProduct3} onSelectionChange={e => setSelectedProduct3(e.value)}
                            //expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}  rowExpansionTemplate={rowExpansionTemplate} 
                        >

                            <Column field='num_order' header='Número de Orden' className='font-bold' style={{width:'8%'}}></Column>
                            <Column  body={actionBodyTipoPago} header='Tipo de Pago' style={{minWidth:'30%'}}/>
                            <Column field='tipo_envioTms' header='Tipo de Envío' />
                            <Column field='nombreCicloPedido' header='Estado del Pedido' />
                            <Column header='Fecha del Pedido' body={fechaBody} style={{minWidth:'100px'}}/>
                            <Column field='cantidad_prod' header='Cantidad de Items' />
                            <Column field='sub_total' header='Sub Total' />
                            <Column field='total_envio' header='Precio Envío' />
                            <Column field='total_total' header='Total' />
                            <Column header='Comprobante Adjuntado' body={actionBodyComprobante}/>
                            <Column header='Acciones' body={actionBodyTemplate3} ></Column>
                        </DataTable>

                    </div>
                </div>

                <div className='col-12 md:col-4 lg:col-4 surface-border'>
                    <div className="card w-full shadow-2" >
                        <DataTable  value={productoShow3} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} emptyMessage='Sin venta seleccionada..'
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos."  showGridlines responsiveLayout="scroll" 
                            >
                            

                            <Column field='nombre' header='Producto' className='font-bold' style={{width:'15%'}} frozen></Column>
                            <Column field='codigo' header='Código' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='marca' header='Marca' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='cantidad_prod' header='Cantidad' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='isArmado' header='Producto Armado' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_armado' header='Precio Armado' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio' header='Precio Producto' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_envio' header='Precio Envio' className='' style={{width:'15%'}} frozen></Column>
                            <Column field='precio_vendido' header='Precio Final xU' className='' style={{width:'15%'}} frozen></Column>

                        </DataTable>
                    </div>
                </div>
            </div>
        
        </div>

            <Dialog visible={dialogVenta1} style={{ width: '450px' }} header="Detalle Venta" modal className="p-fluid"  onHide={hideDialog1}>

                {
                    dataShow1 != null ? (
                        <>
                            <div className='field'>
                                <label className='font-bold'>Nro. de Orden</label>
                                <p className='m-0 ml-4'>{dataShow1.num_order}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Estado del Pedido</label>
                                <p className='m-0 ml-4'>{dataShow1.nombreCicloPedido}</p>
                            </div>

                            {
                                dataShow1.tipo_pago === 101 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago ContraEntrega</p>
                                    </div>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 102 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago por QR</p>
                                    </div>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 103 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago a la Hora de Recoger (Retiro en Local)</p>
                                    </div>
                                ):null
                            }

                            <div className='field mt-2'>
                                <label className='font-bold'>Tipo de Envío</label>
                                <p className='m-0 ml-4'>{dataShow1.tipo_envioTms}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Total</label>
                                <p className='m-0 ml-4'>{dataShow1.total_total}</p>
                            </div>

                            {
                                dataShow1.tipo_pago === 102 ? (
                                    <>         
                                        <div className='field mt-2'>
                                            <label className='font-bold'>Comprobante Adjuntado</label>
                                            {
                                                dataShow1.comprobante_de_pago ? (
                                                    dataShow1.comprobante_de_pago.length > 0 ? (
                                                        <p className='m-0 ml-4'>Si</p>
                                                    ):(
                                                        <p className='m-0 ml-4'>No</p>
                                                    )
                                                ):null
                                            }
                                        </div>
                                        <div className='field mt-2'>
                                            <label className='font-bold'>Imagen de Pago</label>
                                        </div>
                                        <div className='field'>
                                            {
                                                dataShow1.comprobante_de_pago ? (
                                                    dataShow1.comprobante_de_pago.length > 0 ?(
                                                        
                                                            
                                                            <Image width="250" src={dataShow1.comprobante_de_pago[0].url_image_pago} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={dataShow1.comprobante_de_pago[0].num_order} preview />
                                                        
                                                    ):(
                                                        <p className='m-0 ml-4'>Imagen no Adjuntada Aún</p>
                                                    )
                                                ):null
                                            }
                                        </div>
                                        <div className='field mt-4'>
                                            {
                                                dataShow1.comprobante_de_pago ? (
                                                    dataShow1.comprobante_de_pago.length > 0 ?(                          
                                                        <Button loading={loadingBtn} type="submit" onClick={() => confirmarVenta1(dataShow1)} label='Confirmar Pedido' className='p-button-raised p-button-success p-button-text px-6'/>
                                                    ):(
                                                        <p className='m-0 mt-5'>Para confirmar se tiene que adjuntar el comprobante.</p>
                                                    )
                                                ):null
                                            }
                                        </div>
                                    </>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 101 ? (
                                    <div className='field mt-4'>
                                        <Button loading={loadingBtn} type="submit" onClick={() => confirmarVenta1(dataShow1)} label='Confirmar Pedido' className='p-button-raised p-button-success p-button-text px-6'/>
                                    </div>
                                ):null
                            }
                        </>
                    ):null
                }

            </Dialog>


            <Dialog visible={dialogVenta2} style={{ width: '450px' }} header="Detalle Venta" modal className="p-fluid"  onHide={hideDialog2}>
                {   
                    dataShow1 != null ? (
                        <>
                            <div className='field'>
                                <label className='font-bold'>Nro. de Orden</label>
                                <p className='m-0 ml-4'>{dataShow1.num_order}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Estado del Pedido</label>
                                <p className='m-0 ml-4'>{dataShow1.nombreCicloPedido}</p>
                            </div>

                            {
                                dataShow1.tipo_pago === 101 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago ContraEntrega</p>
                                    </div>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 102 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago por QR</p>
                                    </div>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 103 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago a la Hora de Recoger (Retiro en Local)</p>
                                    </div>
                                ):null
                            }
                            
                            <div className='field mt-2'>
                                <label className='font-bold'>Tipo de Envío</label>
                                <p className='m-0 ml-4'>{dataShow1.tipo_envioTms}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Departamento del Envío</label>
                                <p className='m-0 ml-4'>{dataShow1.departamentoText}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Ciudad del Envío</label>
                                <p className='m-0 ml-4'>{dataShow1.ciudad}</p>
                            </div>

                            <div className='field mt-2'>
                                <label className='font-bold'>Total</label>
                                <p className='m-0 ml-4'>{dataShow1.total_total}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Comprobante Adjuntado</label>
                                {
                                    dataShow1.comprobante_de_pago ? (
                                        dataShow1.comprobante_de_pago.length > 0 ? (
                                            <p className='m-0 ml-4'>Si</p>
                                        ):(
                                            <p className='m-0 ml-4'>No</p>
                                        )
                                    ):null
                                }
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Imagen de Pago</label>
                                {
                                    dataShow1.comprobante_de_pago ? (
                                        dataShow1.comprobante_de_pago.length > 0 ?(
                                            <p className='m-0 ml-4'>
                                                
                                                <Image width="250" src={dataShow1.comprobante_de_pago[0].url_image_pago} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={dataShow1.comprobante_de_pago[0].num_order} preview />
                                            </p>
                                        ):(
                                            <p className='m-0 ml-4'>Imagen no Adjuntada Aún</p>
                                        )
                                    ):null
                                }
                            </div>
                            <div className='field mt-4'>
                                {
                                    dataShow1.comprobante_de_pago ? (
                                        dataShow1.comprobante_de_pago.length > 0 ?(                          
                                            <Button loading={loadingBtn} type="submit" onClick={() => confirmarVenta1(dataShow1)} label='Confirmar Pedido' className='p-button-raised p-button-success p-button-text px-6'/>
                                        ):(
                                            <p className='m-0 mt-5'>Para confirmar se tiene que adjuntar el comprobante.</p>
                                        )
                                    ):null
                                }
                            </div>
                        </>
                    ):null
                }
            </Dialog>
            
            <Dialog visible={dialogVenta3} style={{ width: '450px' }} header="Detalle Venta" modal className="p-fluid"  onHide={hideDialog3}>
            {   
                    dataShow1 != null ? (
                        <>
                            <div className='field'>
                                <label className='font-bold'>Nro. de Orden</label>
                                <p className='m-0 ml-4'>{dataShow1.num_order}</p>
                            </div>
                            <div className='field mt-2'>
                                <label className='font-bold'>Estado del Pedido</label>
                                <p className='m-0 ml-4'>{dataShow1.nombreCicloPedido}</p>
                            </div>

                            {
                                dataShow1.tipo_pago === 101 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago ContraEntrega</p>
                                    </div>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 102 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago por QR</p>
                                    </div>
                                ):null
                            }
                            {
                                dataShow1.tipo_pago === 103 ? (
                                    <div className='field mt-2'>
                                        <label className='font-bold'>Tipo de Pago</label>
                                        <p className='m-0 ml-4'>Pago a la Hora de Recoger (Retiro en Local)</p>
                                    </div>
                                ):null
                            }
                        

                            <div className='field mt-2'>
                                <label className='font-bold'>Total</label>
                                <p className='m-0 ml-4'>{dataShow1.total_total}</p>
                            </div>
                            {
                                dataShow1.tipo_pago === 102 ?(
                                    <> 
                                        <div className='field mt-2'>
                                            <label className='font-bold'>Comprobante Adjuntado</label>
                                            {
                                                dataShow1.comprobante_de_pago ? (
                                                    dataShow1.comprobante_de_pago.length > 0 ? (
                                                        <p className='m-0 ml-4'>Si</p>
                                                    ):(
                                                        <p className='m-0 ml-4'>No</p>
                                                    )
                                                ):null
                                            }
                                        </div>
                                        <div className='field mt-2'>
                                            <label className='font-bold'>Imagen de Pago</label>
                                            {
                                                dataShow1.comprobante_de_pago ? (
                                                    dataShow1.comprobante_de_pago.length > 0 ?(
                                                        <p className='m-0 ml-4'>
                                                            
                                                            <Image width="250" src={dataShow1.comprobante_de_pago[0].url_image_pago} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={dataShow1.comprobante_de_pago[0].num_order} preview />
                                                        </p>
                                                    ):(
                                                        <p className='m-0 ml-4'>Imagen no Adjuntada Aún</p>
                                                    )
                                                ):null
                                            }
                                        </div>
                                        <div className='field mt-4'>
                                            {
                                                dataShow1.comprobante_de_pago ? (
                                                    dataShow1.comprobante_de_pago.length > 0 ?(                          
                                                        <Button loading={loadingBtn} type="submit" onClick={() => confirmarVenta1(dataShow1)} label='Confirmar Pedido' className='p-button-raised p-button-success p-button-text px-6'/>
                                                    ):(
                                                        <p className='m-0 mt-5'>Para confirmar se tiene que adjuntar el comprobante.</p>
                                                    )
                                                ):null
                                            }
                                        </div>
                                    </>
                                ):null
                            }

                            {
                                dataShow1.tipo_pago === 103 ? (
                                    <div className='field mt-4'>
                                        <Button loading={loadingBtn} type="submit" onClick={() => confirmarVenta1(dataShow1)} label='Confirmar Pedido' className='p-button-raised p-button-success p-button-text px-6'/>
                                    </div>
                                ):null
                            }


                        </>
                    ):null
                }
            </Dialog>
            

    </div>
  )
}

export default VentasBersati