import React from 'react'
import { Outlet } from 'react-router-dom'

const PrincipalAdmin = ({children}) => {

  return (
    <div className='min-w-screen min-h-screen'>
        <Outlet className=' max-w-screen max-h-screen'></Outlet>
    </div>
  )
}

export default PrincipalAdmin