/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, {useContext, useState, useEffect, useRef} from 'react'
import { Link, useNavigate } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import axios from 'axios';

import * as Yup from 'yup';

import EnvioDomicilio from './opcionesEnvio/EnvioDomicilio';
import EnvioNacional from './opcionesEnvio/EnvioNacional';

import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet'

import {useDispatch, useSelector} from 'react-redux'
import { UserContext } from '../../../../context/UserProvider';
import { cartIndexAccion } from '../../../../Redux/patos/CartDuck';
import { tipoEnvioAccion, departamentosAccion } from '../../../../Redux/patos/FormDuck';
import { async } from '@firebase/util';

const InfoUsuario = () => {
    const dispatch = useDispatch();
    const navegate = useNavigate();

    const {userLoading, userBer, userFire, signOutUser } = useContext(UserContext);

    const formAref = useRef(null);
    const formRef = useRef(null)

    const [email, setEmail] = useState('');
    const [city, setCity] = useState(null);
    const [estado, setEstado] = useState(0);
    const [habilitar, setHabilitar] = useState(false);
    const [opcion, setOpcion] = useState(null);
    const [blockedPanel, setBlockedPanel] = useState(true);

    const [depart, setDepart] = useState(null);
    const [ciudad, setCiudad] = useState('');

    const [data, setData] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)
    const toast = useRef(null);

    const itemSteps = [
        {label: 'Formulario'},
        {label: 'Pago'},
        {label: 'Confirmación'}
      ];
    
    useEffect(() => {

        const VerificarTokenN = () =>{
            let token = ''
              try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
                return token;
              } catch (error) {
                  localStorage.removeItem('_vita');
                  localStorage.removeItem('_dolce');
                  navegate('/login');
                  signOutUser()
                return 401;
              }
            
        }

        if (userLoading) {
            
        }else{
            if (userBer && userFire) {
                const tokenV =  VerificarTokenN();
                const id = userBer.id;
               
                
            }else{
                navegate('/')
            }
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(() => {
        const generarData = () =>{

            dispatch(tipoEnvioAccion())
            dispatch(departamentosAccion())

            
        }
        generarData()

    },[dispatch])

    const storeTipoEnvio = useSelector(store => store.storeTipoEnvio)
    const {arrayDepartamentos, loadingDepartamentos} = useSelector(store => store.storeDepartamentos)

    //setData(arrayDepartamentos)
    useEffect(()=>{
        setData(arrayDepartamentos)
    },[arrayDepartamentos])


    const formik = useFormik({
        initialValues:{
            
            nombres: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            celular: null,     
            ci: null,
            correo: '',
            habilitar: 0,
            estado:'',
   
            user_id:'',
            
            formA: {
                direccion: '',
                zona: '',
                referencia: '',
                latitud:'',
                longitud:'',
              },

              departamento: 0,
              ciudad: '',

        },
        validationSchema: Yup.object({
            nombres: Yup.string()
                .min(3, 'Mínimo tres caracteres.')
                .required('El campo es Requerido'),
            apellidoPaterno: Yup.string()
                .min(3, 'Mínimo tres caracteres.')
                .required('El campo es Requerido'),
            celular: Yup.string()
                .nullable()
                .required('Ingrese su Número de Celular.'),
            ci: Yup.string()
                .nullable()
                .required('Ingrese su Docuemnto de Identidad.'),
            
            
            formA: city === 1 ? Yup.object({
                direccion: Yup.string().min(3, 'Mínimo tres caracteres.').required('El campo es Requerido') ,
                zona: Yup.string().min(3, 'Mínimo tres caracteres.').required('El campo es Requerido'),
                latitud: Yup.string()
                    .required('Añade una ubicación en el mapa.'),
                longitud: Yup.string()
                    .required('Añade una ubicación en el mapa.'),
            }) : Yup.object(),
            
            ciudad: city === 2 ? Yup.string().min(3, 'Mínimo tres caracteres.').required('El campo es Requerido') : Yup.string(),

        }),
        onSubmit: async (data)=>{
            data.correo = email;
            data.user_id = userBer.id;
            console.log('entra')
            
            if (city === 1) {
                data.estado = city;
                //console.log(data)
                DomicilioGuardar(data)
            }
            if (city === 2) {
                data.estado = city;
                data.departamento = depart;
                //data.ciudad = ciudad;
                console.log(data)
                DomicilioGuardar(data)
            }
            if (city === 3) {
                data.estado = city;
                console.log(data)
                DomicilioGuardar(data)
            }

        }
    })

    const passToken = (tokens) => {
        console.log(tokens)
        //setCiudad(tokens)
      }

    const DomicilioGuardar = async(data) =>{
        setLoadingBtn(true)

        const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/datosdomicilioform`, data);
        console.log(resSave)
        if (resSave.status === 200) {     
            toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
            setLoadingBtn(false)
           
        }else {
            setLoadingBtn(false)
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
        }
    }

    const handleSubmit = async () =>{
        
        if (city === 1) {
            //console.log('entraaHandle')
            formik.submitForm();
            //formik.handleSubmit();
        }

        if (city === 2) {
            //console.log('entraaHandle')
            formik.submitForm();
            //formik.handleSubmit();
        }

        if (city === 3) {
            formik.submitForm();
        }
    }

    const response = (val) => {
        if(val){
            setHabilitar(true)
        }
      }

      const callback = (val) => {
          //console.log(val);
          //setEstado(val)
          formik.values.direccion = val;
        };
      
      useEffect(() => {
        if (city === 1) {
            //setOpcion(<EnvioDomicilio hab={response} parentCallback={callback} refId={formAref} ></EnvioDomicilio>);
            setOpcion()
        }
        if (city === 2) {
            //setOpcion();
        }
        if (city === 3) {
            setOpcion(<RecojoEnLocalFragment></RecojoEnLocalFragment>);
        }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    }, [city])

    const RecojoEnLocalFragment = () =>{
        return (
            <React.Fragment>
                <div className='col-12 lg:col-12 field mt-2 '>
                    <Button type='button' loading={loadingBtn} onClick={handleSubmit} label='Guardar Data' className='surface-900'></Button>
                </div>
            </React.Fragment>
        )
    }

    const ExampleDif = (
            <React.Fragment>
                <div className="col-12 mt-4 lg:mt-0 mb-4">
                    <div className="text-900 font-medium text-xl">Datos de Entrega</div>
                </div>
                <div className="col-12 field mb-3">
                    <label htmlFor="direccion" className="text-900 font-medium mb-3">*Dirección</label>
                    <InputText id="direccion" type="text" value={formik.values.direccion} onBlur={formik.handleBlur} onChange={formik.handleChange} className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.direccion && formik.touched.direccion })}/>
                        { formik.errors.direccion && formik.touched.direccion ? (
                            <p className='p-error p-0 m-0'>{formik.errors.direccion}</p>
                        ) : null }
                </div>
            </React.Fragment>
        )
    
    

    const EnvioDomicilioFragment = ({ formik }) =>{

        const [selectedPosition, setSelectedPosition] = useState(null);
        const [markerTitle, setMarkerTitle] = useState('');
        const [position, setPosition] = useState(null);

        const options = {
            center: {lat: -17.78387457963673, lng: -63.18185806274414},
            zoom: 12
        };

        function LocationMarker() {
        
            const map = useMapEvents({
              dblclick(e) {
                //console.log(e.latlng.lat)
                setPosition(e.latlng)
                formik.values.latitud = e.latlng.lat;
                formik.values.longitud = e.latlng.lng;
              },
              locationfound(e) {
              },
            })
          
            return position === null ? null : (
              <Marker position={position} draggable={true}>
                <Popup>Ubicación Marcada</Popup>
              </Marker>
            )
          }

        const handleDireccion = (value) =>{
            setDireccion(value)
            parentCallback(value)
        }

        console.log(userBer)
        return (

            <>
            <div className="col-12 mt-4 lg:mt-0 mb-4">
                    <div className="text-900 font-medium text-xl">Datos de Entrega</div>
                </div>
                <div className="col-12 field mb-3">
                    <label htmlFor="direccion" className="text-900 font-medium mb-3">*Dirección</label>
                    <InputText id="direccion" type="text" value={formik.values.formA.direccion} onBlur={formik.handleBlur} onChange={formik.handleChange} 
                        //className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.formA.direccion && formik.touched.formA.direccion })}
                    />
                        { /*formik.errors.formA.direccion && formik.touched.formA.direccion ? (
                            <p className='p-error p-0 m-0'>{formik.errors.formA.direccion}</p>
                        ) : null */}
                </div>
                <div className="col-12 lg:col-6 field mb-3">
                    <label htmlFor="zona" className="text-900 font-medium mb-3">*Zona</label>
                    <InputText id="zona" type="text" value={formik.values.zona} onChange={formik.handleChange} className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.zona && formik.touched.zona })}/>
                    { formik.errors.zona && formik.touched.zona ? (
                            <p className='p-error p-0 m-0'>{formik.errors.zona}</p>
                        ) : null }
                </div>
                <div className="col-12 lg:col-6 field mb-2">
                    <label htmlFor="referencia" className="text-900 font-medium mb-3">Referencia</label>
                    <InputText id="referencia" type="text" value={formik.values.referencia} onChange={formik.handleChange} className="p-inputtext w-full mb-3" />
                </div>

                <div className="col-12 mb-6">
                    <label htmlFor="referencia" className="text-900 font-medium mb-3 p-invalid"><i className="pi pi-info-circle "></i> Marca en el mapa la ubicación.</label>
                    <MapContainer center={options.center} maxZoom={18} zoom={options.zoom} scrollWheelZoom={true} style={{width: '100%', minHeight: '320px'}} className='p-invalid' >
                            <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LocationMarker />
                    </MapContainer>

                    { formik.errors.latitud && formik.errors.longitud ? (
                            <p className='p-error p-0 m-0'>{formik.errors.latitud}</p>
                        ) : null }

                </div>

                <div className='col-12 lg:col-12 field mt-2'>
                    {
                        city !== null && habilitar === true && city === 1 ?(
                            <Button type='button' onClick={handleSubmit} label='Guardar Data' className='surface-900'></Button>
                        ):(
                            ''
                        )
                    }
                    <Button type='button' onClick={handleSubmit} label='Guardar Data' className='surface-900'></Button>
                </div>
                </>

        )
    }
    const onCiudadChange = (e) =>{
            
        //setCiudad(e.target.value)
        //props.passToken(e.target.value)
    }
    const onDepaChange = (e) => {
        //console.log(e.value)
        setDepart(e.value);
    }

    const EnvioNacionalFragment = (
        /*const [ciudad, setCiudad] = useState('');
        
       

        const onCiudadChange = (e) =>{
            
            setCiudad(e.target.value)
            props.passToken(e.target.value)
        }*/
        //return (

            <React.Fragment>
                <div className="col-12 mt-4  mb-4">
                    <div className="text-900 font-medium text-xl">Datos de Entrega Envío Nacional</div>
                </div>

                <div className="col-6 field mb-3">
                    <label htmlFor="departamento" className="text-900 font-medium mb-3">*Departamento</label>
                    {
                        loadingDepartamentos ? (
                            <p>Cargando...</p>
                        ):(
                            <Dropdown id='departamento' value={depart} optionValue='id' options={data} onChange={onDepaChange} optionLabel="departamento" placeholder="Selecciona un elemento" />
                        )
                    }
                </div>
                <div className='col-6 field'>
                    <label htmlFor="ciudad" className="text-900 font-medium mb-3">*Ciudad</label>
                    <InputText id="ciudad" type="text" value={formik.values.ciudad} onChange={formik.handleChange}  placeholder='ej. Ciudad de Sucre, Camiri, etc..' className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.ciudad && formik.touched.ciudad })}/>
                    { formik.errors.ciudad && formik.touched.ciudad ? (
                            <p className='p-error p-0 m-0'>{formik.errors.ciudad}</p>
                        ) : null }
                </div>

                <div className='col-12 lg:col-12 field mt-2 '>
                    
                    {
                        depart !== null && formik.values.ciudad !== '' ?(
                            <Button type='button'loading={loadingBtn} onClick={handleSubmit} label='Guardar Data' className='surface-900'></Button>
                        ):(
                            ''
                        )
                    }
                </div>
            
            </React.Fragment>
        //)
    )
    //console.log(depart)

    useEffect(() => {

        setEmail(userFire.email)
        
    }, [userFire]);


   
      
   

    //console.log(arrayDepartamentos)
    //console.log(formik)

    useEffect(()=>{
        if (formik.values.ci !== null) {
            setBlockedPanel(false)
        }else{
            setBlockedPanel(true)
        }
    },[formik.values.ci])


    //console.log(formik)

  return (
    <>
        <Toast ref={toast} />
        <div className=" px-4 py-8 md:px-6 lg:px-8">
            <div className='mb-2'>
                <Steps model={itemSteps} activeIndex={0} /> 
            </div>
            <div className="grid -mr-3 -ml-3">
                <div className="col-12 lg:col-8 p-4">
                    <form onSubmit={formik.handleSubmit} ref={formRef}>
                        <div className="surface-card grid formgrid p-fluid border-round shadow-3 p-4">
                            <div className="col-12 mb-4 flex flex-column lg:flex-row align-items-center justify-content-between">
                                <div className="text-900 font-medium text-xl">Información de Contacto</div>
                            </div>

                                <div className="col-12 field mb-0">
                                    <label htmlFor="email" className="text-900 font-medium mb-3">*Email</label>
                                    {
                                        userLoading ? (
                                            <ProgressBar mode="indeterminate" color={'black'} className='w-full h-1rem'/>
                                        ):(
                                            <InputText id="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} type="text" className="p-inputtext w-full mb-3" />             
                                        )
                                    }
                                </div>

                                <Divider className="w-full px-2 py-2" />
                                <div className="col-12 mt-4 lg:mt-0 mb-4">
                                    <div className="text-900 font-medium text-xl">Datos Personales</div>
                                </div>
                                <div className="col-12 field mb-3">
                                    <label htmlFor="nombres" className="text-900 font-medium mb-3">*Nombres</label>
                                    <InputText id="nombres" value={formik.values.nombres} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.nombres && formik.touched.nombres })}/>
                                    { formik.errors.nombres && formik.touched.nombres ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.nombres}</p>
                                        ) : null }
                                </div>
                                <div className="col-12 lg:col-6 field mb-3">
                                    <label htmlFor="apellidoPaterno" className="text-900 font-medium mb-3">*Apellido Paterno</label>
                                    <InputText id="apellidoPaterno" value={formik.values.apellidoPaterno} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className={classNames('p-inputtext w-full',{ 'p-invalid':  formik.errors.apellidoPaterno && formik.touched.apellidoPaterno })}/>
                                    { formik.errors.apellidoPaterno && formik.touched.apellidoPaterno ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.apellidoPaterno}</p>
                                        ) : null }
                                </div>
                                <div className="col-12 lg:col-6 field mb-0">
                                    <label htmlFor="apellidoMaterno" className="text-900 font-medium mb-3">Apellido Materno</label>
                                    <InputText id="apellidoMaterno" value={formik.values.apellidoMaterno} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" className="p-inputtext w-full mb-3" />
                                </div>
                                <div className="col-12 lg:col-6 field mb-3">
                                    <label htmlFor="celular" className="text-900 font-medium mb-3">*Celular</label>
                                    <InputNumber id="celular" name="celular" mode="decimal" value={formik.values.celular} onBlur={formik.handleBlur}  onValueChange={formik.handleChange} useGrouping={false} type="text"  className={classNames(' w-full',{ 'p-invalid':  formik.errors.celular && formik.touched.celular })}/>
                                    { formik.errors.celular && formik.touched.celular ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.celular}</p>
                                        ) : null }
                                </div>
                                <div className="col-12 lg:col-6 field mb-3">
                                    <label htmlFor="ci" className="text-900 font-medium mb-3">*Número de Identidad</label>
                                    <InputNumber id="ci" name="ci" type="text" mode="decimal" value={formik.values.ci} onBlur={formik.handleBlur} onValueChange={formik.handleChange} useGrouping={false} className={classNames(' w-full',{ 'p-invalid':  formik.errors.ci && formik.touched.ci })} />
                                    { formik.errors.ci && formik.touched.ci ? (
                                            <p className='p-error p-0 m-0'>{formik.errors.ci}</p>
                                        ) : null }
                                </div>

                                <Divider className="w-full px-2 py-2" />
                                
                                <div className='col-12 lg:col-6 field mb-0'>

                                    <BlockUI blocked={blockedPanel}>
                                        <div className="text-900 font-medium text-xl mb-4">Selecciona un Tipo de Envío</div>
                                        {
                                            storeTipoEnvio.loadingTipoEnvio ? (
                                                <p>Cargando...</p>
                                            ):(
                                                storeTipoEnvio.arrayTipoEnvio.map((tipoEnvio, index)=>(
                                                    <div key={index} className="field-radiobutton">
                                                        <RadioButton inputId="city1" name="city" value={tipoEnvio.id} onChange={(e) => setCity(e.value)} checked={city === tipoEnvio.id} />
                                                        <label htmlFor="city1">{tipoEnvio.tipo_envio}</label>
                                                    </div>
                                                ))
                                            )
                                        }
                                        {/*<div className="field-radiobutton">
                                            <RadioButton inputId="city1" name="city" value="0" onChange={(e) => setCity(e.value)} checked={city === '0'} />
                                            <label htmlFor="city1">Envío a Domicilio</label>
                                        </div>
                                        <div className="field-radiobutton">
                                            <RadioButton inputId="city2" name="city" value="1" onChange={(e) => setCity(e.value)} checked={city === '1'} />
                                            <label htmlFor="city2">Envío Nacional</label>
                                        </div>
                                        <div className="field-radiobutton">
                                            <RadioButton inputId="city3" name="city" value="2" onChange={(e) => setCity(e.value)} checked={city === '2'} />
                                            <label htmlFor="city3">Recojo en Local</label>
                                    </div> */}

                                    
                                    </BlockUI>
                                </div>
                                    
                                {
                                   //opcion

                                    city === 1 ? (
                                        <>
                                            <EnvioDomicilio formik={formik} />
                                            <div className='col-12 lg:col-12 field mt-2'>
                                                {
                                                    city === 1 && formik.values.formA.direccion !== '' ?(
                                                        <Button type='button' loading={loadingBtn} onClick={handleSubmit} label='Guardar Data' className='surface-900'></Button>
                                                    ):null
                                                }
                                            </div>
                                        </>
                                    ):(
                                        city === 2 ? (
                                            EnvioNacionalFragment
                                        ):null
                                    )
                                    
                                }
                                {
                                    city === 3 ? (
                                        <Button type='button' loading={loadingBtn} onClick={handleSubmit} label='Guardar Data' className='surface-900'></Button>
                                    ):null
                                }
                    
                        </div>
                    </form>
                </div>
                <div className='col-12 md:col-4 lg:col-4 p-4'>
                    <div className="surface-card grid formgrid p-fluid border-round shadow-3 p-4">
                            
                    </div>
                </div>
            </div>

           
        </div>
    </>
  )
}

export default InfoUsuario



/*
Calculo de Delivery

const latBer = -17.78106346785891;
const lngBer = -63.204554045666235;

const latCli = -17.7110710776006;
const lngCli = -63.16657881941156;

const latDelta = latCli - latBer;
const lonDelta = lngCli - lngBer;

let distance = (
  6371000 * Math.acos(
    Math.cos(latBer) *  Math.cos(latCli) *  Math.cos(lonDelta) +
    Math.sin(latBer) * Math.sin(latCli)
  )
);

let distancia2 = (
  6371000 * 2 * Math.asin(
    Math.sqrt(
      Math.cos(latBer) *  Math.cos(latCli) * Math.pow(Math.sin(lonDelta / 2), 2) +
      Math.pow(Math.sin(latDelta / 2), 2)
    )
  )
);

// Precio * 0.00010
// Update header text
let precio = Math.round(distancia2 * 0.00010) 
document.querySelector('#header').innerHTML = precio +' bs.'

*/