/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useContext } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Carousel } from 'primereact/carousel';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import Cargando from '../../../comps/Cargando';

import axios from 'axios';

import {CFlGPAccion, familiasStoreAccion} from '../../../../../Redux/patos/storeCFLGP'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';

import { UserContext } from '../../../../../context/UserProvider';

const StoreFamiliasP = () => {

    const {userBer, userFire, signOutUser} = useContext(UserContext);
    const [layout, setLayout] = useState('grid');
    const [displayPosition, setDisplayPosition] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { cate, fam } = useParams();

    const [varcito, setVarcito] = useState('');
    const [varcito1, setVarcito1] = useState('');
    const toast = useRef(null);

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    
    useEffect(() => {
        const generarData = () =>{

            dispatch(familiasStoreAccion(cate, fam))
            dispatch(CFlGPAccion())

        }
        generarData()
    },[dispatch, cate, fam])

    const storeF = useSelector(store => store.storeFamilias)
    const storeFProd = useSelector(store => store.storeFamiliasProd)

    const storeCFLGP = useSelector(store => store.listcflgp)


    useEffect(() => {

        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
          if (storeCFLGP.arrayCFlGP[index][cate]) {
            //console.log('hola')
            setVarcito(storeCFLGP.arrayCFlGP[index][cate])
            break
          }
          
        }

        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
            if (storeCFLGP.arrayCFlGP[index][fam]) {
              //console.log('hola')
              setVarcito1(storeCFLGP.arrayCFlGP[index][fam])
              break
            }
            
          }
  
      },[cate, fam, storeCFLGP])

    //console.log(varcito)
    //console.log(storeFProd)

    const productTemplate = (product) => {
        return (
                /*<div className='flex align-items-center justify-content-center '>
                    <div className="surface-0 m-3 p-0 border-round shadow-2 w-full md:w-14rem">
                
                        <img src={`${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.nombreLinea} className=" w-full"/>
        
                    <div className="flex flex-column w-full p-4">
                        <span className="text-900 font-medium text-xl border-200 pb-2 h-5rem" style={{borderBottom:"1px solid"}}>{product.nombreLinea}</span>
                        <Link className='no-underline' to={`/tienda/${cate}/${fam}/${product.bLinea}`}> 
                        <span className="text-600 mt-2 flex align-items-center">Ver Ahora <i className="ml-2 text-600 pi pi-fw pi-arrow-right text-xl pt-1"></i></span>
                        </Link>
                    </div>
                    </div>
                </div>*/
            <div className='card align-items-center justify-content-center lg:justify-content-start'>
                <div className="surface-0 m-3 border-round shadow-2">
                <img src={`${product.image}`}  className="border-round-top w-full" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.nombreGrupoProductos} />
                    <div className="flex flex-column w-full p-4">
                        <span className="text-900 font-medium text-xl border-200 pb-2" style={{borderBottom:"1px solid"}}>{product.nombreLinea}</span>
                        <Link className='no-underline' to={`/tienda/${cate}/${fam}/${product.bLinea}`}> 
                            <span className="text-600 mt-2 flex align-items-center">Ver Ahora <i className="ml-2 text-600 pi pi-fw pi-arrow-right text-xl pt-1"></i></span>
                        </Link>
                    </div>
                </div>
            </div>
            
        );
    }

    const VerificarToken = () =>{
        let token = ''
          try {
            token = atob(localStorage.getItem('_vita'));
            //console.log(token)
            return token;
          } catch (error) {
              localStorage.removeItem('_vita');
              localStorage.removeItem('_dolce');
              navigate('/login');
              signOutUser()
            return 401;
          }
        
      }
  
      const SaveAlCarrito = async (data, token) =>{
  
        const tok = 'Bearer ' + token;
        const config = {
            method: 'post',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritoadd',
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
            data: data,
        }
  
        const res = await axios(config)
  
        return res;
      }
  
      const añadirAlCarrito =  async (id) => {
  
            if (userBer && userFire) {
                const tokenV =  VerificarToken();
                //console.log(tokenV)
                if (tokenV === 401) {
                    
                }else{
                    const data = {
                        'producto_id' : id,
                        'cantidad' : 1,
                        'usuario_id' : userBer.id
                    }
  
                    const responFinal = await SaveAlCarrito(data, tokenV)
  
                  if (responFinal.data) {
                        if (responFinal.data.dis === 1) {
                            toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                        }else if(responFinal.data.dis === 0){
                            toast.current.show({severity:'success', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                        }
                  }
                }
                
            }else{
                //console.log('Inicia Sesión')
                onDisplay()
            }
        }
  
  
        const onDisplay = () => {
            setDisplayPosition(true)
        }
        
        const onHide = () => {
            setDisplayPosition(false)
        }
  
        const renderFooter = () => {
            return (
                <div>
                    <Link to={'/register'} className='no-underline'>   
                        <Button label="Regístrate" className="p-button-text text-900 hover:surface-hover" />
                    </Link>
                    <Link to={'/login'} className='no-underline'>
                        <Button label="Iniciar Sesión" className='surface-900' autoFocus />
                    </Link>
                </div>
            );
        }


        const renderGridItem = (data) => {
            return (
                /*<div className="col-12 md:col-4">
                    <div className="product-grid-item card">
                        <div className="product-grid-item-top">
                            <div>
                                <i className="pi pi-tag product-category-icon"></i>
                                <span className="product-category">{data.codigo}</span>
                            </div>
                            
                        </div>
                        <div className="product-grid-item-content">
                        <img src={`${data.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                            <div className="product-name">{data.nombre}</div>
                            <Rating value={data.rating} readOnly cancel={false}></Rating>
                        </div>
                        <div className="product-grid-item-bottom">
                            <span className="product-price">${data.precio}</span>
                            <Button icon="pi pi-shopping-cart" label="Añadir al Carrito" ></Button>
                        </div>
                    </div>
                </div>*/
                data.producto_promo.length > 0 ? (
                  <div className='col-12 md:col-3'>
                    <div className='m-2 p-4'>
                      <div className="relative mb-3">
                        {
                            data.producto_promo[0].imagenPP ? (
                                <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                                    <img alt={data.nombre} src={`${data.producto_promo[0].imagenPP}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                                </Link>
                            ):(
                                <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                                    <img alt={data.nombre} src={`${data.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                                </Link>
                            )
                        }
                        {
                            data.producto_promo[0].descuentoNumber > 0 ? (
      
                                <span className="bg-green-600 text-white font-bold text-xl px-3 py-1 absolute" style={{borderRadius: '1rem', right: '1rem', bottom: '1rem'}}>-%{data.producto_promo[0].descuentoNumber}</span>
                            ):null
                        }
                        {
                            data.producto_promo[0].envioGratis === 1 ? (
                                <span className="bg-green-600 text-white font-bold px-2 py-1 absolute" style={{borderRadius: '1rem', left: '1rem', top: '1rem'}}>Envio Gratis</span>
                            ):null
                        }
                                                                  
                      </div>
                      <div className='h-4rem'>
                        <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline"> 
                          <span className="text-900 font-medium text-xl">{data.nombre} - {data.codigo}</span>
                        </Link>
                      </div>
                      <div className="my-3">
                          <span className="mr-3">
                              <i className="pi pi-star-fill text-yellow-500"></i>
                          </span>
                          <span className="text-500">{data.rating} Estrellas!</span>
                      </div>
                      <div className="mb-4 flex">
                          {
                              data.producto_promo[0].descuentoNumber === 0 ? (
                                  <span className="font-bold text-900 text-lg">BOB. {data.precio}</span> 
                              ):(
      
                                  <>
                                      <span className="font-bold text-500 text-lg line-through ">BOB. {data.precio}</span> <span className="font-bold text-lg text-900 ml-2">BOB. {(data.precio - (data.precio * (data.producto_promo[0].descuentoNumber / 100))).toFixed(2)}</span>
                                  </>
                              )
                          }
                          
                      </div>
                      <div className='grid'>
                          <div className='col-6 p-1'>
                              <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                                  <Button label="Detalles"  className="p-button-sm surface-700 hover:surface-900 border-none  w-full" />
                              </Link>
                          
                          </div>
                          <div className='col-6 p-1'>
                              <Button onClick={()=>añadirAlCarrito(data.id)} icon="pi pi-cart-plus" className="p-button-sm surface-900 hover:surface-700 border-none text-0 w-full transition-all transition-duration-300 text-center" tooltip="Añadir al Carrito" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Button>
                          </div>
                      </div>
                    </div>
                  </div>
                ):(
                  <div className='col-12 md:col-3'>
                    <div className='m-2 p-4'>
                      <div className="relative mb-3">
                        <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">  
                            <img src={`${data.imagenPrincipal}`} className="w-full h-22rem" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                        </Link>
                      </div>
                      <div className='h-4rem'>
                        <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                          <span className="text-900 font-medium text-xl">{data.nombre} - {data.codigo}</span>
                        </Link>
                      </div>
                      <div className="my-3">
                          <span className="mr-3">
                              <i className="pi pi-star-fill text-yellow-500"></i>
                          </span>
                          <span className="text-500">{data.rating} Estrellas!</span>
                      </div>
                      <div className="mb-4">
                          
                          <span className="font-bold text-900 text-lg">BOB. {data.precio}</span> 
                          
                      </div>
                      <div className='grid'>
                          <div className='col-6 p-1'>
                              <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                                  <Button label="Detalles"  className="p-button-sm surface-700 hover:surface-900 border-none  w-full" />
                              </Link>
                          
                          </div>
                          <div className='col-6 p-1'>
                              <Button onClick={()=>añadirAlCarrito(data.id)} icon="pi pi-cart-plus" className="p-button-sm surface-900 hover:surface-700 border-none text-0 w-full transition-all transition-duration-300 text-center" tooltip="Añadir al Carrito" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Button>
                          </div>
                      </div>
                    </div>
                  </div>
                )
                
            );
          }

        const itemTemplate = (product, layout) => {
            if (!product) {
                return;
            }
      
            if (layout === 'grid')
                return renderGridItem(product);
          } 

//console.log(storeF)

  return (
    <>
    <Toast ref={toast} position="center-right" className='z-5' />
        {
            storeCFLGP.loadingCFlGP ? (
                <div className='flex align-items-center justify-content-center '>
                    <Cargando />
                </div>
            ) 
            : 
            (
                <div className='card w-full '>
                    <div className='card surface-card card-container w-full'>

                        <ul className="list-none p-3 m-0 surface-card flex align-items-center font-medium overflow-x-auto">
                            <li className="pr-3">
                                <Link to={`/tienda`} className="no-underline cursor-pointer">
                                    <i className="pi pi-home text-900"></i>
                                </Link>
                            </li>
                            <li className="px-2">
                                <i className="pi pi-angle-right text-900"></i>
                            </li>
                            <li className="px-2">
                                <Link to={`/tienda/${cate}`} className="no-underline cursor-pointer text-500 white-space-nowrap">{varcito}</Link>
                            </li>
                            <li className="px-2">
                                <i className="pi pi-angle-right text-900"></i>
                            </li>
                            <li className="px-2">
                                <Link to={`/tienda/${cate}/${fam}`} className="no-underline cursor-pointer text-500 white-space-nowrap">{varcito1}</Link>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="surface-section px-4 py-4 md:px-6 lg:px-8">

                        <div className="surface-section py-5">
                            <div className="border-bottom-1 surface-border">
                                <span className="block text-3xl font-medium text-900 mb-4">{varcito1}</span>
                            </div>
                        </div>

                        <div className="surface-section py-8">
                            <div className="grid">
                                <div className="col-12 lg:col-2 text-center lg:text-left">
                                    <div className="text-900 font-medium text-4xl">Lineas dentro de esta Familia</div>
                                </div>
                                <div className="col-12 lg:col w-full lg:w-9 lg:ml-7 lg:-mt-4">
                                    {
                                        storeF.loadingF ? (
                                            <p>Cargando....</p>
                                        )
                                        :
                                        (
                                            
                                            <Swiper
                                                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                                                spaceBetween={0}
                                                slidesPerView={3}
                                                //loop
                                                autoplay={{
                                                    delay: 3500,
                                                    disableOnInteraction : false
                                                }}
                                            
                                                pagination={{ clickable: true }}
                                                /*onSwiper={(swiper) => console.log(swiper)}
                                                onSlideChange={() => console.log('slide change')}*/
                                                className="z-0  h-30rem w-full" //hdef
                                        
                                                breakpoints={{
                                                    360:{

                                                        slidesPerView: 1,
                                                        
                                                    },
                                                    480:{

                                                        slidesPerView: 1,
                                                        
                                                    },
                                                    // when window width is >= 640px
                                                    640: {
                                                    
                                                        slidesPerView: 1,
                                                        
                                                    },
                                                    // when window width is >= 768px
                                                    768: {
                                                    
                                                    slidesPerView: 2,
                                                    },
                                                    1050: {
                                                        
                                                        slidesPerView: 3,
                                            
                                                    },
                                                }}
                                            > 
                                                {
                                                    storeF.arrayF.map((item, index)=>(

                                                    <SwiperSlide key={index} className='h-full md:p-1'>

                                                        <div className='flex md:m-1 md:p-2 align-items-center justify-content-center  h-full'>
                                                            <div className="surface-0 border-round  w-15rem md:w-18rem">
                                                            <img src={`${item.image}`}  className="border-round-top w-full" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.nombreGrupoProductos} />
                                                                <div className="flex flex-column w-full p-2">
                                                                    <span className="text-900 font-medium text-xl border-200 pb-2" style={{borderBottom:"1px solid"}}>{item.nombreLinea}</span>
                                                                    <Link className='no-underline' to={`/tienda/${cate}/${fam}/${item.bLinea}`}> 
                                                                        <span className="text-600 mt-2 flex align-items-center">Ver Ahora <i className="ml-2 text-600 pi pi-fw pi-arrow-right text-xl pt-1"></i></span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </SwiperSlide>

                                                    ))
                                                }
                                            </Swiper>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="dataview-demo">
                            <div className="card">
                                    {
                                    storeF.loadingF ? (
                                        <p>Cargando...</p>
                                    ):(
                                        <DataView value={storeFProd.arrayFProd} layout={layout} paginator paginatorPosition={'both'} rows={12}
                                                itemTemplate={itemTemplate}  loading={storeFProd.loadingFProd}  />
                                    )
                                    }
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
        <Dialog header="Regístrate o Inicia Sesión" visible={displayPosition} position={'right'} modal={true} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide()}
            draggable={false} resizable={false}>
            <p className="m-0">Que esperas para poder disfrutar de toda la tienda de Bersati.</p>
            <br></br>
            <p className="m-0 text-800">Si no tienes una cuenta <span className='text-900 font-semibold'>Regístrate</span>, si ya tienes una cuenta <span className='text-900 font-semibold'>Inicia Sesión</span></p>
        </Dialog>
    </>
  )
}

export default StoreFamiliasP