/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { ProgressSpinner } from 'primereact/progressspinner';

import axios from "axios";

const InicioAdmin = () => {

  //const userLogin = useSelector((store) => store.userLogin);
  //const {userInfo} = userLogin;
  

    const activo = useSelector(store => store.userLogin.userInfo)
    const [categorias, setCategorias] = useState(null);
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true)
      const generarData = async () =>{
          
          const resCate = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/categorias')
          const resProd = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

          setCategorias(resCate.data)
          setProducts(resProd.data)
      }
      generarData()

      setLoading(false)
  },[])

    console.log(categorias)
  return (
    <>

      {/* <div className='flex flex-column md:flex-row flex-wrap h-full w-full justify-content-center align-content-center'>
        <h1 className='flex align-items-center align-self-center justify-content-center gap-0'>Los detalles llegan pronto...  </h1>
        <ProgressSpinner className='m-0 align-self-center' style={{width: '50px', height: '50px'}} strokeWidth="2"/>
      </div> */}
      <div className='w-screen'>

        <div className='flex justify-content-center align-items-center text-center m-4 w-auto h-10rem surface-0 border-round shadow-2 px-4 py-5 md:px-6 lg:px-8'>
            <h1 className='flex text-4xl'>Bienvenido a la Pantalla Administrativa de Bersati</h1>
        </div>

        <div className="m-4 w-auto surface-0 px-4 py-5 md:px-6 lg:px-8 border-round shadow-2 ">
          <div className="grid">

            <div className="col-12 md:col-6 lg:col-3">
                <div className="surface-card shadow-2 p-3 border-round">
                    <div className="flex justify-content-between ">
                        <div>
                            <span className="block text-500 font-medium mb-3">Categorias</span>
                            {
                              categorias === null ? (
                                <ProgressSpinner className='m-0 align-self-center' style={{width: '50px', height: '50px'}} strokeWidth="2"/>
                              ):(
                                categorias ? <div className="text-900 font-medium text-xl">{categorias.length}</div> : ''
                                
                              )
                            }
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width:"2.5rem", height:"2.5rem"}}>
                            <i className="pi pi-tag text-blue-600 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="col-12 md:col-6 lg:col-3">
                <div className="surface-card shadow-2 p-3 border-round">
                    <div className="flex justify-content-between ">
                        <div>
                            <span className="block text-500 font-medium mb-3">Productos Activos</span>
                            {
                              products === null ? (
                                <ProgressSpinner className='m-0 align-self-center' style={{width: '50px', height: '50px'}} strokeWidth="2"/>
                              ):(
                                products ? <div className="text-900 font-medium text-xl">{products.length}</div> : ''
                                
                              )
                            }
                        </div>
                        <div className="flex align-items-center justify-content-center bg-yellow-100 border-round" style={{width:"2.5rem", height:"2.5rem"}}>
                            <i className="pi pi-box text-yellow-600 text-xl"></i>
                        </div>
                    </div>
                </div>
            </div>

          </div>
        </div>

      </div>

    </>
  )
}

export default InicioAdmin