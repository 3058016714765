/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { FileUpload } from 'primereact/fileupload';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import { PickList } from 'primereact/picklist';
import { InputSwitch } from 'primereact/inputswitch';

import { DataScroller } from 'primereact/datascroller';


import axios from "axios";



import './EleccionEditor.css'
import EjemploComponent from './ejemploComponent';

const EleccionEditor = () => {

    const [products4, setProducts4] = useState(null);
    const [product, setProduct] = useState(null)
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [productDialog, setProductDialog] = useState(false);
    const [productDialogEdit, setProductDialogEdit] = useState(false);
    const [dialogPE, setDialogPE] = useState(false);

    const [productPE, setProductPE] = useState([])

    const [loading, setLoading] = useState(true);
    const [loadingPE, setLoadingPE] = useState(false);
    
    const [blockedPanel, setBlockedPanel] = useState(false);
    const [selectedProduct6, setSelectedProduct6] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    //const [source, setSource] = useState([]);
    //const [target, setTarget] = useState([]);
    const [estadoList, setEstadoList] = useState(false);


    const [nombre, setNombre] = useState('');
    const [descripcion, setDescripcion] = useState('');


    

    const toast = useRef(null);


        const paymentOptions = [
            {name: 'Activo', estado: 1},
            {name: 'Oculto', estado: 0},
        ];
        const estadoItems = [
            {label: 'Activo', value: 1},
            {label: 'Oculto', value: 0},
        ];


    const formik = useFormik({
        initialValues: {
            id: '',
            nombre: '',
            descripcion: '',
            estado:'',
            error: 0,
            sel: 0
  
        },onSubmit: (data) => {

            data.error = 0;
            if (data.nombre === '' || data.nombre === null) {
                data.error = 1;
            }else{
                if (data.sel === 0) {                  
                    guardarData(data)
                }else if (data.sel === 1) {
                    guardarDataEdit(data)
                }else{
                    toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Error al guardar los datos....', life: 3000 });
                }
                
            }

            if (data.error > 1) {
                toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Error al guardar los datos....', life: 3000 });
            }
        }
    })

        useEffect(() => {
            //console.log('????')
            setLoading(true)
                const generarData = async () =>{
                    //console.log('????')
                    const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/editorschindex')
                    //const resData = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')
                    setProducts4(res.data)
                    //setSource(resData.data)
                    setLoading(false)
                }
            generarData()
            //console.log('????')
        
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        const generarDatita = async () =>{
            setLoading(true)

                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/editorschindex')
                    
                setProducts4(res.data)

                
            setLoading(false)
            
        }

        const guardarData = async (data) =>{
            setLoadingBtn(true)
            const guardar = async (data) =>{
                const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/eco/editorschstore`, data);
    
                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Categoria creada con éxito.', life: 3000 });
                    setLoadingBtn(false)
                  }else if(resSave.status === 202){
                      toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Categoria NO creada.', life: 3000 });
                      setLoadingBtn(false)
                  }
    
                  generarDatita()
            }
    
            guardar(data)
            setLoadingBtn(false)
            formik.resetForm()
            hideDialog()
        }
        
        const guardarDataEdit = async (data) =>{
            setLoadingBtn(true)
            const guardar = async (data) =>{
                const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/eco/editorschupdatetext/`+data.id, data);
    
                if (resSave.status === 200) {      
                    toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Categoria creada con éxito.', life: 3000 });
                    setLoadingBtn(false)
                  }else if(resSave.status === 202){
                      toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Categoria NO creada.', life: 3000 });
                      setLoadingBtn(false)
                  }
    
                  generarDatita()
            }
    
            guardar(data)
            setLoadingBtn(false)
            formik.resetForm()
            hideDialogEdit()
        }

        const deleteProduct = async () => {
            setLoading(true)
        
            if (product.id) {
              //console.log(product)
              const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/eco/editorschdelete/${product.id}`)
        
              //console.log(resSave)
              if (resSave.status === 200) {      
                  toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Categoria eliminada con éxito.', life: 3000 });
                  setProduct(null);
                  setDeleteProductDialog(false);
        
              }else if (resSave.status === 202) {
                    setProduct(null);
                    setDeleteProductDialog(false);
                  
                  toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Categoria NO eliminada.', life: 3000 });
              }


              generarDatita()
            }

        }


    const hideDialog = () => {
        formik.resetForm()
        formik.values.nombre = ''
        formik.values.descripcion = ''
        formik.values.estado = ''
        setProductDialog(false);
    }

      const openDialog = () => {
        formik.resetForm()
        setProductDialog(true);
        setEstadoList(false);
        setSelectedProduct6(null) 
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
              
                <Button label="Nuevo EE" onClick={openDialog} icon="pi pi-plus" className="p-button-success mr-2" />

            </React.Fragment>
        )
    }

    const EditEstado = async (val, rowData) =>{
    
        let id = rowData.id;
        let data = {
        estado : val
        }
        const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/eco/editorschupdate/${id}`, data);
    
        if (resSave.status === 200) {      
        toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Estado editado con éxito.', life: 3000 });
    
        }else if(resSave.status === 202){
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Estado NO editado.', life: 3000 });
        }
    
    
        generarDatita()

    }

    const estadoBodyTemplate = (rowData) => {
        return <SelectButton className='z-0' value={rowData.estado} optionLabel="name" optionValue="estado" unselectable onChange={(e) => EditEstado(e.value, rowData)} options={paymentOptions}  />;
    }


    //console.log(selectedProduct6)

    /*useEffect(()=>{
        const generarDat = () =>{
            if (selectedProduct6 === null) {
                
            }else{

                console.log(selectedProduct6)
            }
        }
        generarDat()
    },[selectedProduct6])
*/
    const mensajeonUnblocked = () =>{
        //setEstadoSave(false)
        toast.current.show({ severity: 'info', summary: 'Productos Desbloqueados', detail: 'Seleccione los productos para asignar a las categorias correspondientes.', life: 4000 });
    }

    /*const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }*/

    
    /*const itemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.code}</h5>
                    <i className="pi pi-tag product-category-icon"></i>
                    
                  
                </div>
            </div>
        )
    }*/

    const SeleccionarRow = (data) =>{
        //console.log(data)
        if (data === null) {
            setEstadoList(false)
            setSelectedProduct6(data) 
        }else{
            setSelectedProduct6(data) 
            setEstadoList(true)
        }
        
    }

    const editProduct = (product) => {
        setProduct({...product});
        //console.log(product)
        formik.values.id = product.id;
        formik.values.nombre = product.nombre_ee;
        if (product.descripcion_ee === null) {
            formik.values.descripcion = '';
        }else{

            formik.values.descripcion = product.descripcion_ee;
        }
        formik.values.sel = 1;
        setProductDialogEdit(true);
    }
    const hideDialogEdit = () => {
        formik.resetForm()
        formik.values.id = ""
        formik.values.nombre = ""
        formik.values.descripcion = ""
        formik.values.sel = 0;
        setProductDialogEdit(false);
    }

    const consultaDataP = async (id) =>{
        setLoadingPE(true)

        const conD = async (id) =>{
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/eco/editorschprodindex/'+id)
            setProductPE(res.data)
            setLoadingPE(false)
        }

        conD(id)
    }


    const openDialogPE = (cate) =>{
        //console.log(cate)
        consultaDataP(cate.id)
        setDialogPE(true)
    }


    const hideDialogPE = () => {
        setDialogPE(false)
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
        
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setProduct('')
      }


    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='flex'>
                    <Button icon="pi pi-eye" placeholder="Left" tooltip="Ver Productos" tooltipOptions={{position: 'left'}} onClick={() => openDialogPE(rowData)} className="p-button-rounded p-button-info "  />
                    <Button icon="pi pi-pencil" placeholder="Left" tooltip="Editar Texto" tooltipOptions={{position: 'left'}} onClick={() => editProduct(rowData)} className="p-button-rounded p-button-warning ml-1"  />
                    <Button icon="pi pi-trash" placeholder="Left" tooltip="Eliminar" tooltipOptions={{position: 'left'}} onClick={() => confirmDeleteProduct(rowData)} className="p-button-rounded p-button-danger ml-1"  />
                </div>
            </React.Fragment>
        );
      }

    const deleteProdPE = async (id, bid) =>{
        setBlockedPanel(true)

        
        if (id) {
          //console.log(product)
          const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/eco/editorschproddelete/${id}`)
    
          //console.log(resSave)
          if (resSave.status === 200) {      
              toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Producto quitado con éxito.', life: 3000 });
              setBlockedPanel(false)
    
          }else if (resSave.status === 202) {
                setBlockedPanel(false)
              
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Producto No quitado.', life: 3000 });
          }


          consultaDataP(bid)
        }
        
    }


      const itemTemplate = (data) => {
        return (
            <div className="product-item">
                {
                    data.imagenPrincipal === null ? (
                        <img src={'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                    ):(
                        <img src={`${data.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                    )
                }
                
                <div className="product-detail">
                    <div className="product-name">{data.nombre}</div>
                    <div className="product-description">{data.codigo}</div>
                    {
                        data.estado === 1 ? (
                            <span className={`product-badge status-instock`}>Activo</span>
                        )
                        :
                        (
                            <span className={`product-badge status-outofstock`}>Oculto</span>
                        ) 
                    }
                </div>
                <div className="product-action">
                    <span className="product-price">{data.marca}</span>
                    
                    
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() =>deleteProdPE(data.id, data.eleccion_editor_id)} aria-label="Quitar"></Button>
                    
                    
                </div>
            </div>
        );
    }

    //console.log(productPE)
  return (
    <>

        <div className='w-full overflow-scroll speeddial-linear-demo '>
            <Toast ref={toast} />
            <div className="surface-section px-2 py-5 md:px-4 lg:px-6">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">ELECCIÓN DEL EDITOR</span>
                </div>
            </div>
            <div className="card w-full datatable-filter-demo px-2 md:px-3 lg:px-5" >

                <Toolbar className="mb-1 text-sm p-2" left={leftToolbarTemplate}></Toolbar>
                {/*<DataTable value={products4} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} loading={loading} filterDisplay="row" emptyMessage='Sin datos...'
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} categorias" size="small"  showGridlines 
                selectionMode="radiobutton" selection={selectedProduct6} onSelectionChange={e => setSelectedProduct6(e.value)} 
                className="overflow-scroll p-2 z-1" responsiveLayout="scroll" scrollable  >

                    <Column selectionMode="single" className='w-2rem justify-content-center'></Column>
                    <Column field='nombre_ee' header='Nombre Categoria' className='flex justify-content-center' filter sortable style={{ minWidth: '12rem' }} />
                    <Column field='descripcion_ee' header='Descripción' className='flex justify-content-center' filter sortable style={{ minWidth: '16rem' }} />
                    <Column header='Estado' className='flex justify-content-center' filter sortable style={{ minWidth: '16rem' }} body={estadoBodyTemplate} />
                </DataTable>*/}

                <DataTable value={products4} selectionMode="radiobutton" paginator rows={10} selection={selectedProduct6} onSelectionChange={e => SeleccionarRow(e.value)} 
                    dataKey="id" responsiveLayout="scroll" filterDisplay="row" scrollable resizableColumns columnResizeMode="fit" showGridlines size="small"
                    className="edito p-datatable-customers " loading={loading} emptyMessage='Sin datos...' scrollHeight="flex" style={{ height: 'calc(100vh - 40vh)' }}> 
                    <Column filterMenuStyle={{ width: '3rem' }} style={{ maxWidth: '3rem' }}  selectionMode="single"></Column>
                    <Column field='nombre_ee' header='Nombre Categoria' className=''  filter sortable style={{ minWidth: '12rem' }} />
                    <Column field='descripcion_ee' header='Descripción' className='overflow-scroll' filter sortable style={{ minWidth: '18rem' }} />
                    <Column header='Estado' className='flex justify-content-center' filter sortable style={{ minWidth: '16rem' }} body={estadoBodyTemplate} />
                    <Column header='Acciones' filterMenuStyle={{ width: '3rem' }} style={{ maxWidth: '12rem' }} body={actionBodyTemplate} exportable={false} ></Column>
                </DataTable>

                <Dialog visible={productDialog} style={{ width: '450px' }} header="Nuevo Categoria Elección del Editor" modal className="p-fluid"  onHide={hideDialog}>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field">
                            <label htmlFor="nombre">Nombre Nueva Categoria*</label>
                            <InputText id="nombre" name="nombre" value={formik.values.nombre} onChange={formik.handleChange}  />               
                        </div>
                        <div className="field">
                            <label htmlFor="descripcion">Descripción*</label>
                            <InputText id="descripcion"  name="descripcion" value={formik.values.descripcion} onChange={formik.handleChange}  />               
                        </div>
                        <div className="field">
                            <label htmlFor="estado">Estado</label>
                            <Dropdown inputId="estado" name="estado" 
                            optionValue="value" value={formik.values.estado} optionLabel="label" 
                            options={estadoItems} onChange={formik.handleChange} />              
                        </div>
                        {
                            formik.values.nombre && formik.values.descripcion ? (
                                <Button type="submit"  loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                            )
                            :
                            (
                                ''
                            )
                        }
                    </form>
                </Dialog>

                <Dialog visible={productDialogEdit} style={{ width: '450px' }} header="Editar Categoria Elección del Editor" modal className="p-fluid"  onHide={hideDialogEdit}>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field">
                            <label htmlFor="nombre">Nombre Categoria*</label>
                            <InputText id="nombre" name="nombre" value={formik.values.nombre} onChange={formik.handleChange}  />               
                        </div>
                        <div className="field">
                            <label htmlFor="descripcion">Descripción*</label>
                            <InputText id="descripcion"  name="descripcion" value={formik.values.descripcion} onChange={formik.handleChange}  />               
                        </div>
                        {
                            formik.values.nombre && formik.values.descripcion ? (
                                <Button type="submit"  loading={loadingBtn} label='Guardar Data' className='p-button-raised p-button-success px-6'/>
                            )
                            :
                            (
                                ''
                            )
                        }
                    </form>
                </Dialog>

                <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                        {product && <span>Estas seguro de eliminar la familia <b>{product.nombre}</b>?</span>}
                    </div>
                </Dialog>


                <Dialog visible={dialogPE} style={{ width: '550px' }} header="Productos Categoria Elección del Editor" modal className="p-fluid"  onHide={hideDialogPE}>
                <div className="datascroller-demo">
                    <div className="card">
                        {
                            loadingPE ? (
                                <p>Cargando....</p>
                            ):(
                                <DataScroller value={productPE} itemTemplate={itemTemplate} rows={5} inline scrollHeight="500px" header="Baja para cargar mas..." emptyMessage='Sin Productos asignados..' />
                            )
                        }
                        {/*<DataScroller value={productPE} itemTemplate={itemTemplate} rows={5} inline scrollHeight="500px" header="Scroll Down to Load More" /> */}
                    </div>
                </div>
                </Dialog>


            </div>

           {
             
             estadoList ?(
                <EjemploComponent estado={estadoList} selection={selectedProduct6} />
             )
             :
             (
                ""
             )
           }

        </div>

    </>
  )
}

export default EleccionEditor