/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import { useEffect, useContext, useState, useRef } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';


import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { UserContext } from '../../../../context/UserProvider';
import Cargando from '../../comps/Cargando';

import { cartIndexAccion } from '../../../../Redux/patos/CartDuck';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { Steps } from 'primereact/steps';

import { PaymentContext } from '../../../../context/PaymentProvider';

const CarritoPage = () => {

    const navegate = useNavigate();
    //const [cargando, setCargando] = useState(true) ;
    const dispatch = useDispatch();

    const { subTotalFinal, setSubTotalFinal, totalFinal, setTotalFinal, productosFinal, setProductosFinal } = useContext(PaymentContext);


    const value = 2;
    const {userLoading, userBer, userFire, signOutUser } = useContext(UserContext);
    const [productos , setProductos] = useState([]);
    const [total, setTotal] = useState(0);
    const [checked, setChecked] = useState([]);
    const {arrayCart, loadingCart} = useSelector(store => store.cartIndex)
    const toast = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const itemSteps = [
        {label: 'Carrito'},
        {label: 'Formulario y Pago'},
        {label: 'Confirmación'}
      ];

    useEffect(() => {

        const VerificarTokenN = () =>{
            let token = ''
              try {
                token = atob(localStorage.getItem('_vita'));
                //console.log(token)
                return token;
              } catch (error) {
                  localStorage.removeItem('_vita');
                  localStorage.removeItem('_dolce');
                  navegate('/login');
                  signOutUser()
                return 401;
              }
            
        }

        if (userLoading) {
            
        }else{
            if (userBer && userFire) {
                const tokenV =  VerificarTokenN();
                const id = userBer.id;
                const generarData = () =>{

                    dispatch(cartIndexAccion(tokenV, id))
                }
                generarData()
                
            }else{
                navegate('/')
            }
        }


    },[dispatch, userLoading])
    
    
    useEffect(()=>{
        const putData = () => {
            setProductos(arrayCart)
        }
        
        if (loadingCart === false) {
            
            putData()
            
            
        }
        
    },[loadingCart])
    
    

    useEffect(()=>{
        
        if (loadingCart === false) {
            if (productos.length > 0) {
                let price = 0;
                for (let index = 0; index < productos.length; index++) {
                    if (productos[index]['producto_promo'].length > 0) {
                        let precioDescuento = (productos[index]['precio'] - (productos[index]['precio'] * (productos[index]['producto_promo'][0]['descuentoNumber'] / 100))).toFixed(2)
                        let precioProd = precioDescuento * productos[index]['cantidad'] 
                        price += precioProd 
                    }else{
                        let precioProd = productos[index]['precio'] * productos[index]['cantidad'] 
                        price += precioProd  
                    }
                }
                setTotal(price)

                for (let index = 0; index < productos.length; index++) {

                    if (productos[index]['armado'] &&  productos[index]['precio_armado']) {
                        
                        if (productos[index]['isArmado'] === false || productos[index]['isArmado'] === true) {
                        
                           
                        }else{
                            const inputData = [...productos]
                            inputData[index]['isArmado'] = false;
                            setProductos(inputData)
                        }

                    }
                }
            }else{
                setTotal(0)
            }
        }

    },[productos])


    const VerificarToken = () =>{
        let token = ''
        try {
        token = atob(localStorage.getItem('_vita'));
        //console.log(token)
        return token;
        } catch (error) {
            localStorage.removeItem('_vita');
            localStorage.removeItem('_dolce');
            navegate('/login');
            signOutUser()
        return 401;
        }
        
    }

    const DeleteItemCarrito = async (id, token) =>{

        const tok = 'Bearer ' + token;
        const config = {
            method: 'delete',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritodel/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
        }

        const res = await axios(config)

        return res;
    }

    const CantidadItemCarrito = async (id, token, cantidad) =>{

        const tok = 'Bearer ' + token;
        const config = {
            method: 'put',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritocantidad/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
            data: cantidad
        }

        const res = await axios(config)

        return res;
    }

    const CarritoDelete =  async (id) => {

        if (userBer && userFire) {
            const tokenV =  VerificarToken();
            //console.log(tokenV)
            if (tokenV === 401) {
                
            }else{
                const responFinal = await DeleteItemCarrito(id, tokenV)

               if (responFinal) {
                    if (responFinal.status === 202) {
                        toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }else if(responFinal.status === 200){
                        toast.current.show({severity:'info', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }
               }
            }
            
        }else{
            //console.log('Inicia Sesión')
        }
    }

    const CarritoCantidad =  async (id, cantidad) => {

        if (userBer && userFire) {
            const tokenV =  VerificarToken();
            //console.log(tokenV)
            if (tokenV === 401) {
                
            }else{
                const responFinal = await CantidadItemCarrito(id, tokenV, cantidad)

               if (responFinal) {
                    if (responFinal.status === 202) {
                        toast.current.show({severity:'error', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }else if(responFinal.status === 200){
                        toast.current.show({severity:'info', summary: 'Atención!', detail: responFinal.data.mensaje, life: 3000});
                    }
               }
            }
            
        }else{
            //console.log('Inicia Sesión')
        }
    }


    const handleChangeCantidad = (onChangeValue, prod, i) =>{
        const inputData = [...productos]
        inputData[i].cantidad = onChangeValue.target.value;
        let data = {
            'cantidad': onChangeValue.target.value
        }
        setProductos(inputData)
        CarritoCantidad(prod.id, data)
    }

    const handleChangeDelete = (value, index) =>{
        const deleteVal = [...productos]
        deleteVal.splice(index,1);
        setProductos(deleteVal)
        CarritoDelete(value.id)
        //console.log(value)
    }

    const handleCheked = (target, index) =>{
        /*let datita = {
            keyIndex : index,
            armado : target,
        }

        setChecked(...checked, datita)*/

        console.log(target)
        
        const inputData = [...productos]
        inputData[index].isArmado = target;
        if (target === true) {
            //parseInt(productos[index]['precio']) + parseInt(productos[index]['precio_armado'])
            inputData[index].precio = (parseInt(inputData[index].precio) + parseInt(inputData[index].precio_armado)).toFixed(2);
            setProductos(inputData)
        }else{
            inputData[index].precio = (parseInt(inputData[index].precio) -  parseInt(inputData[index].precio_armado)).toFixed(2);
            setProductos(inputData)
        }
        

    }

    const HandleAddContext = () => {
        setSubTotalFinal((total).toFixed(2))
        setProductosFinal(productos)
    }
    //console.log(productos)
  return (
    <>
        <Toast ref={toast} position="center-right" className='z-5' />
        
        <div className=" px-4 py-8 md:px-6 lg:px-8">
            <div className='mb-2'>
                <Steps model={itemSteps} activeIndex={activeIndex} readOnly={false}/> 
            </div>

            <div className="grid -mr-3 -ml-3">

                <div className="col-12 md:col-8 lg:col-8 p-4">
                    <div className="surface-card grid formgrid border-round shadow-3 p-4">
                        <div className="flex w-full">
                            <div className="ml-0 p-2 w-full">
                                <div className="flex flex-column align-items-left">
                                    <div className="text-900 text-4xl font-normal mb-4"><span className='font-medium'>Carrito ({productos.length+' item(s)'})</span></div>
                                </div>

                                <ul className="list-none p-0 m-0">
                                    {
                                        productos.map((prod, index)=>(        
                                            <li key={index} className="flex flex-column md:flex-row border-bottom-1 surface-border md:align-items-center">
                                                
                                                <div className="flex-auto py-5 md:pl-5">
                                                        <div className='grid'>
                                                            <div className='col-12 md:col-6'>
                                                                <div className='flex flex-column md:flex-row'>

                                                                    {
                                                                        prod.producto_promo[0] ? (
                                                                            <img src={`${prod.producto_promo[0].imagenPP}`} alt={prod.nombre} onError={(e) => e.target.src=`${prod.imagenPrincipal}`} className="w-12rem flex-shrink-0 mx-auto md:mx-0" />
                                                                        ):(
                                                                            <img src={`${prod.imagenPrincipal}`} alt={prod.nombre} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-12rem flex-shrink-0 mx-auto md:mx-0" />
                                                                        )
                                                                    }
                                                                    <div className="w-full flex flex-column align-items-center justify-content-center md:align-items-start mt-3 md:ml-3">
                                                                        <span className="text-900 text-xl font-medium mb-3">{prod.nombre+' - '+prod.codigo}</span>
                                                                        <span className="inline-flex align-items-center mb-2 font-medium">
                                                                            <i className="pi pi-ticket text-700 mr-2"></i>
                                                                            <span className="text-600">{prod.marca}</span>
                                                                        </span>

                                                                        {
                                                                            prod.producto_promo[0] && (prod.producto_promo[0].envioGratis === 1 ) ? (
                                                                                <span className="text-600 mb-2 bg-green-500 text-sm text-center text-white w-7rem border-round-sm">{`ENVIO GRATIS`}</span>
                                                                                
                                                                            ):(
                                                                                ''
                                                                            )
                                                                        }

                                                                        {
                                                                            prod.armado &&  prod.precio_armado ?(
                                                                                <span className="flex align-items-center">
                                                                                    <span className="text-900 font-semibold mr-2">{'Producto Armado'}</span>
                                                                                    <Checkbox inputId="binary" checked={productos[index].isArmado} onChange={e => handleCheked(e.checked, index)} />
                                                                                </span>
                                                                            ):null
                                                                        }  
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-5'>
                                                                <div className="w-full flex align-items-start justify-content-between mt-3 sm:mt-0">
                                                                    <div className="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between">
                                                                        <InputNumber showButtons="true" buttonLayout="horizontal"  min="1" inputClassName="w-3rem text-center" value={prod.cantidad} onValueChange={e=>handleChangeCantidad(e, prod, index)}
                                                                        decrementButtonClassName="p-button-text" incrementButtonClassName="p-button-text" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></InputNumber>
                                                                    </div>
                                                                    <div className="flex flex-column sm:align-items-end">
                                                                        {
                                                                            prod.producto_promo[0] && (prod.producto_promo[0].descuentoNumber > 0 ) ? (
                                                                                <>
                                                                                    <span className="text-900 text-xl font-medium flex align-content-center"> <Badge className='text-sm flex align-items-center mr-1' value={`- %`+prod.producto_promo[0].descuentoNumber} severity="success"></Badge> <span className='line-through '>BOB. {(prod.precio * prod.cantidad).toFixed(2)}</span> </span>
                                                                                    <span className="text-900 text-xl font-medium mb-2 sm:mb-3 flex align-content-center">BOB. { ((prod.precio - (prod.precio * (prod.producto_promo[0].descuentoNumber / 100)) ).toFixed(2)  * prod.cantidad).toFixed(2) }</span>
                                                                                </>
                                                                            ):(
                                                                                <span className="text-900 text-xl font-medium mb-2 sm:mb-3">BOB. {(productos[index].precio * prod.cantidad).toFixed(2)}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 md:col-1'>
                                                                <div className="w-full flex align-items-center justify-content-center mt-3 sm:mt-0">
                                                                        <a onClick={e=>handleChangeDelete(prod,index)} className="cursor-pointer text-red-500 font-medium text-base hover:text-red-600 transition-colors transition-duration-300">Quitar</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </li>
                                        ))
                                    }

                                </ul>

                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-12 md:col-4 lg:col-4 p-4'>
                    <div className="surface-card py-2 shadow-3 border-round">
                        <div className='p-4'>
                            <div className="flex flex-column align-items-left">
                                <div className="text-900 text-4xl font-normal mb-4"><span className='font-medium'>Resumen de la Compra</span></div>
                            </div>
                        </div>
                        <div className="flex flex-column align-items-center ">

                            <p className='font-light '>*Envío a domicilio no incluido</p>

                            <div className='text-900 text-4xl font-normal mb-4'>Total: <span className='font-medium'>BOB. {(total).toFixed(2)}</span></div>
                            <Link to={'/checkoutform'} className='no-underline'>
                                <Button onClick={HandleAddContext} label="Proceder a Pagar" className='surface-900'></Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </>
  )
}

export default CarritoPage