/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext, useRef } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Helmet } from 'react-helmet-async';

import { categoriasStoreAccion, CFlGPAccion } from '../../../../../Redux/patos/storeCFLGP'
import Cargando from '../../../comps/Cargando';

import './store.css'

const StoreProductosByCategoria = () => {


    const { cate } = useParams();
    const [varcito, setVarcito] = useState('');
    const [conteito, setConteito] = useState(0);
    const [layout, setLayout] = useState('grid');

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        const generarData = () =>{

            dispatch(categoriasStoreAccion(cate))
            dispatch(CFlGPAccion())

        }
        generarData()
    },[dispatch, cate])

    const responsiveOptions = [
        {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 1
      },
      {
          breakpoint: '600px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '480px',
          numVisible: 1,
          numScroll: 1
      }
    ];

    const storeC = useSelector(store => store.storeCategorias)
    const storeCateProd = useSelector(store => store.storeCategoriasProd)

    const storeCFLGP = useSelector(store => store.listcflgp)

    useEffect(() => {
        setConteito(storeCateProd.arrayProd.length)
        for (let index = 0; index < storeCFLGP.arrayCFlGP.length; index++) {
          if (storeCFLGP.arrayCFlGP[index][cate]) {
            //console.log('hola')
            setVarcito(storeCFLGP.arrayCFlGP[index][cate])
            break
          }
          
        }
  
      },[cate, storeCFLGP, storeCateProd])

      //console.log(storeCateProd)


      const renderGridItem = (data) => {
        return (
            data.producto_promo.length > 0 ? (
                <div className='col-12 md:col-6 lg:col-4'>
                  <div className='m-2 p-4'>
                    <div className="relative mb-3">
                      {
                          data.producto_promo[0].imagenPP ? (
                            <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                              <img alt={data.nombre} src={`${data.producto_promo[0].imagenPP}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                            </Link>
                          ):(
                            <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                              <img alt={data.nombre} src={`${data.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="w-full h-22rem" />
                            </Link>
                          )
                      }
                      {
                          data.producto_promo[0].descuentoNumber > 0 ? (
    
                              <span className="bg-green-600 text-white font-bold text-xl px-3 py-1 absolute" style={{borderRadius: '1rem', right: '1rem', bottom: '1rem'}}>-%{data.producto_promo[0].descuentoNumber}</span>
                          ):(
                              ''
                          )
                      }
                      {
                          data.producto_promo[0].envioGratis === 1 ? (
                              <span className="bg-green-600 text-white font-bold px-2 py-1 absolute" style={{borderRadius: '1rem', left: '1rem', top: '1rem'}}>Envio Gratis</span>
                          ):(
                              ''
                          )
                      }
                                                                
                    </div>
                    <div className='h-4rem'>
                      <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                          <span className="text-900 font-medium text-xl">{data.nombre} - {data.codigo}</span>
                      </Link>
                    </div>
                    <div className="my-3">
                        <span className="mr-3">
                            <i className="pi pi-star-fill text-yellow-500"></i>
                        </span>
                        <span className="text-500">{data.rating} Estrellas!</span>
                    </div>
                    <div className="mb-4 flex">
                        {
                            data.producto_promo[0].descuentoNumber === 0 ? (
                                <span className="font-bold text-900 text-lg">BOB. {data.precio}</span> 
                            ):(
    
                                <>
                                    <span className="font-bold text-500 text-lg line-through ">BOB. {data.precio}</span> <span className="font-bold text-lg text-900 ml-2">BOB. {(data.precio - (data.precio * (data.producto_promo[0].descuentoNumber / 100))).toFixed(2)}</span>
                                </>
                            )
                        }
                        
                    </div>
                    <div className='grid'>
                        <div className='col-6 p-1'>
                            <Link to={`/tienda/productodetalle/${data.id}`} className="no-underline">
                                <Button label="Detalles"  className="p-button-sm surface-700 hover:surface-900 border-none  w-full" />
                            </Link>
                        
                        </div>
                        <div className='col-6 p-1'>
                            <Button  icon="pi pi-cart-plus" className="p-button-sm surface-900 hover:surface-700 border-none text-0 w-full transition-all transition-duration-300 text-center" tooltip="Añadir al Carrito" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Button>
                        </div>
                    </div>
                  </div>
                </div>
              ):(
                <div className='col-12 md:col-6 lg:col-4'>
                  <div className='m-2 p-4 box '>
                    <div id='imagenPrueba' className="flex justify-content-center relative mb-3">
                      <Link  to={`/productos/productodetalle/${data.id}`} className="no-underline">
                        <img src={`${data.imagenPrincipal}`} className="w-21rem h-22rem" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.nombre} />
                      </Link>
                    </div>
                    <div className='flex flex-column h-4rem w-full text-center'>
                      <Link to={`/productos/productodetalle/${data.id}`} className="no-underline text-center">
                        <span className="text-900 font-medium text-xl">{data.nombre}</span>
                      </Link>
                      <span className="text-900 font-medium text-sm mt-1">{data.codigo}</span>
                    </div>
                  </div>
                </div>
              )
            
            
        );
    }
    
    
    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'grid')
            return renderGridItem(product);
    } 

    const refContainer = useRef(null); 
        useEffect(() => { 
            refContainer.current.scrollIntoView({ behavior: "smooth" }); 
        });
        
    return (
        <div ref={refContainer}>  
            <Helmet>
                <title>{varcito} - Bersati</title> 
            </Helmet>

            {
                storeCFLGP.loadingCFlGP ? (
                    <div className='flex align-items-center justify-content-center '>
                              <Cargando />
                    </div>
                )
                :
                (
                    <>                 
                        <div className="surface-section px-4 pt-5 pb-4 md:px-6 lg:px-8">  
                            <div className='card surface-card p-0 card-container w-full'>
                                <ul className="list-none p-0 m-0 surface-card flex align-items-center font-medium overflow-x-auto">
                                <li className="">
                                    <Link to={`/productos`} className="no-underline cursor-pointer">
                                        <i className="pi pi-home text-900"></i>
                                    </Link>
                                </li>
                                <li className="px-1">
                                    <i className="pi pi-angle-right text-900"></i>
                                </li>
                                <li className="px-1">
                                    <Link to={`/productos/${varcito}`} className="no-underline cursor-pointer text-500 text-xl white-space-nowrap">{varcito}</Link>
                                </li>
                                </ul>
                            </div>

                            <div className="surface-section py-5 text-center">
                                <div className="border-bottom-1 surface-border">
                                    <span className="block text-6xl font-bold text-900 mb-4">{varcito}</span>
                                    <span className="block text-xl font-medium text-900 mb-4">{conteito} productos</span>
                                </div>
                            </div>

                            <div className="dataview-demo">
                                <div className="card">
                                        {
                                        storeC.loadingProd ? (
                                            <p>Cargando...</p>
                                        ):(
                                            <DataView value={storeCateProd.arrayProd} layout={layout} paginator paginatorPosition={'bottom'} rows={12}
                                                    itemTemplate={itemTemplate}  loading={storeCateProd.loadingProd} emptyMessage='Coming Soon ...' />
                                        )
                                        }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default StoreProductosByCategoria