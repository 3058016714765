/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import { classNames, ConnectedOverlayScrollHandler } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Divider } from 'primereact/divider';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Badge } from 'primereact/badge';
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';


import { useFormik } from 'formik';

import axios from "axios";

import { Password } from 'primereact';

const UserManagement = () => {

    let emptyProduct = {
        id:'',
        name: '',
        email: '',
        password: '',
        isAdmin: '',
        img_user: '',
    };


    const [users, setUsers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [userDialog, setUserDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rol, setRol] = useState(null);
    const [contra, setContra] = useState(true);
    const [contrasena, setContrasena] = useState('')
    const btnRefInput = useRef();
    const [boton, setBoton] = useState(false)
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    
    const [imgURL, setImgURL] = useState("");
    const toast = useRef(null);

    useEffect(() => {
        setLoading(true)
            const generarData = async () =>{
                
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/allusers')
    
                setUsers(res.data)


                setLoading(false)
            }

            generarData()
    
        //setLoading(false)
    }, []); 

    const rolItems = [
        {label: 'Administrador', value: 1},
        {label: 'Normal', value: 0}
    ];

    const formik = useFormik({
        initialValues : {
            id:'',
            name: '',
            email: '',
            password: '',
            isAdmin: '',
            img_user: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Este campo es requerido.';
            }

            if (!data.email) {
                errors.email = 'Este campo es requerido.';
            }


            return errors;
        },
        onSubmit: (data) => {
            data.isAdmin = rol;
            data.password = contrasena;
            console.log(data)
            if (formik.values.id) {
                //console.log('Entra al edit')
                //$2y$10$TiSZKAXvzOGnnXgLkjhpj.3DVkJewQST7c5iC/6LNngLNjic7DSJe
                guardarEditProducto(data)
                limpieza()
                setImgURL("")
            }else{
                data.img_user = imgURL;
                guardarProducto(data)  
                limpieza()
                setImgURL("")
            }

            }
        });


    const guardarProducto = async (data) => {
        setLoading(true)
        
        if (data.name.trim()) {

            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/newusers', data)
    
            console.log(resSave)
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                formik.resetForm()
                setBoton(false)
                hideDialog()   
            }else if (resSave.status === 400) {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setBoton(false)
            }
        }

        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/allusers')
    
            setUsers(res.data)
        }

        generarData()


        setLoading(false)
    }

    const guardarEditProducto = async (data) => {
        setLoading(true)
        
        if (data.id) {

            const resSave = await axios.put(process.env.REACT_APP_UNSPLASH_URL+`/editusers/${data.id}`, data)
    
            //console.log(resSave)
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                formik.resetForm()
                setBoton(false)
                hideDialog()
            }else if (resSave.response.status === 400) {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
                setBoton(false)
                hideDialog()
            }
        }

        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/allusers')
    
            setUsers(res.data)
        }

        generarData()


        setLoading(false)
    }

    const deleteProduct = async () => {
        setLoading(true)
        
        if (product.id) {
            //console.log(product)
            const resSave = await axios.delete(process.env.REACT_APP_UNSPLASH_URL+`/deleteusers/${product.id}`)
    
            //console.log(resSave)
            if (resSave.status === 200) {      
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: resSave.data.mensaje, life: 3000 });
                setProduct(null);
                setDeleteProductDialog(false);

            }else if (resSave.status === 400) {
                setDeleteProductDialog(false);
                setProduct(null);
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: resSave.data.mensaje, life: 3000 });
            }
        }

        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/allusers')
    
            setUsers(res.data)
        }

        generarData()


        setLoading(false)
    }

    const openNew = () => {
        setSelectedUser(null);
        setUserDialog(true);
    };

    const showedito = (user) =>{
        formik.values.id = user.id;
        formik.values.name = user.name;
        formik.values.email = user.email;
        formik.values.img_user = user.img_user;
    }

    const editUser = (user) => {
        setContra(true)
        setSelectedUser(user);
        showedito(user)
        setRol(user.isAdmin)
        setUserDialog(true);
    };

    const hideDialog = () => {
        formik.resetForm()
        formik.values.name = "";
        formik.values.email = "";
        formik.values.img_user = "";
        setContrasena('')
        setUserDialog(false);
        setSelectedUser(null);
    };

    

    const userDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => {/* save user */}} />
        </React.Fragment>
    );

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                
                {cancelButton}
                {uploadButton}
                
            </div>
        );
    }

    const myUploader = async (event) => {
        // convert file to base64 encoded

        //console.log('ingresa')
        const file = event.files[0];
        //console.log(file)

        const data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'bersatimg')
        data.append('cloud_name', 'ddnbi0nfz')
    
        const res = await fetch('https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload', {
            method: 'post',
            body: data,

        }) //blob:url
        //setImgURL(blob)
        const fileImg = await res.json();

        //console.log(fileImg);
        setImgURL(fileImg.secure_url)
        formik.values.img_user = fileImg.secure_url
        limpieza()
    }

    const limpieza = () =>{
        btnRefInput.current.clear();
        //console.log(btnRefInput)
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    /* Eliminar un soloProducto*/
    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    //https://res.cloudinary.com/ddnbi0nfz/image/upload/v1730269530/bersati/ty8hj4nk9srlphatpyzw.jpg -> miAvatar

    return (
        <div className='surface-section m-0 p-0 md:m-2 md:p-2 max-w-full overflow-hidden shadow-4'>
             <Toast ref={toast} />
             <div className=" px-2 py-5 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4 uppercase ">Gestión de Usuarios</span>
                </div>
            </div>
            <div className="user-management">
                <Toolbar className="mb-4" right={() => (
                    <Button label="Añadir Usuario" icon="pi pi-plus" className="p-button-sm p-button-success" onClick={openNew} />
                )}>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    </span>
                </Toolbar>

                <DataTable value={users} paginator rows={5} globalFilter={globalFilter} responsiveLayout="scroll" loading={loading}>
                    <Column field="name" header="Nombre" sortable></Column>
                    <Column field="email" header="Email" sortable></Column>
                    <Column header="Rol" sortable 
                        body={(rowData) =>(
                            <React.Fragment>
                                {
                                    rowData.isAdmin === 1 ? (
                                        <Badge severity="success" value="Administrador"></Badge>
                                    ):(
                                        <Badge severity="info" value="Normal"></Badge>
                                    )
                                }
                            </React.Fragment>
                        )}
                    />
                    <Column header="Avatar" 
                        body={(rowData) =>(
                            <React.Fragment>
                                {
                                    rowData.img_user === null || rowData.img_user === '' ? (
                                        <Avatar label="B" shape="circle" size="large"  style={{ backgroundColor: '#c3bda8', color: '#ffffff' }}/>
                                    ):(
                                        <Avatar image={rowData.img_user} size="large"  shape="circle"/>
                                    )
                                }
                            </React.Fragment>
                        )}
                    />
                    <Column
                        header="Actions"
                        body={(rowData) => (
                            <React.Fragment>
                                <Button icon="pi pi-pencil" className="p-button-text" onClick={() => editUser(rowData)} />
                                {
                                    rowData.email === "admin@bersati.com" ? (''):(

                                        <Button icon="pi pi-trash" className="p-button-text p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
                                    )
                                }
                            </React.Fragment>
                        )}
                    />
                </DataTable>

                <Dialog visible={userDialog} style={{ width: '450px' }} header={selectedUser ? "Editar Usuario" : "Nuevo Usuario"} modal className="p-fluid" onHide={hideDialog}>
                    <form onSubmit={formik.handleSubmit} className="p-fluid">    
                        <div className="field">
                            <label htmlFor="name" className={classNames({ 'p-invalid': isFormFieldValid('name') })} >*Nombre Completo</label>
                            <InputText id="name" value={formik.values.name} onChange={formik.handleChange}  autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                            {getFormErrorMessage('name')}
                        </div>
                        {
                            formik.values.email === "admin@bersati.com" ? (
                                <div className="field">
                                    <label htmlFor="email" className={classNames({ 'p-invalid': isFormFieldValid('email') })} >*Email</label>
                                    <InputText id="email" value={formik.values.email} disabled onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                    {getFormErrorMessage('email')}
                                </div>
                            ):(

                                <div className="field">
                                    <label htmlFor="email" className={classNames({ 'p-invalid': isFormFieldValid('email') })} >*Email</label>
                                    <InputText id="email" value={formik.values.email} onChange={formik.handleChange}  className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                    {getFormErrorMessage('email')}
                                </div>
                            )
                        }
                        {
                            selectedUser ? (
                                <div className="field">
                                    <label>Mantener Contraseña?</label>
                                    <ToggleButton className='mt-1 surface-900 text-white w-8' checked={contra} onChange={(e) => setContra(e.value)} onLabel="Si mantener" offLabel="No mantener" onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />
                                </div>
                            ) : (
                                ''
                            )
                        }
                        {
                            selectedUser ? (
                            <div className="field">
                                <label htmlFor="password" className={classNames({ 'p-invalid': isFormFieldValid('password') })} >*Contraseña</label>
                                <Password className='w-full' value={contrasena} onChange={(e) => setContrasena(e.target.value)}  disabled={contra} toggleMask feedback/>
                                {getFormErrorMessage('password')}
                            </div>
                            ):(
                                <div className="field">
                                    <label htmlFor="password" className={classNames({ 'p-invalid': isFormFieldValid('password') })} >*Contraseña</label>
                                    <Password className='w-full' value={contrasena} onChange={(e) => setContrasena(e.target.value)} toggleMask feedback/>
                                    {getFormErrorMessage('password')}
                                </div>
                            )
                        }
                        
                        <Divider className='p-3' />

                        <div className="field">
                            <label htmlFor="img_user" >Avatar</label>
                            <InputText id="img_user" value={formik.values.img_user} onChange={formik.handleChange} />
                        </div>

                        <span className='text-color-secondary text-xs my-1'>*Primero debe subir la imagen y luego guardar los datos...</span> 
                        <div className='field my-1'>
                            <FileUpload ref={btnRefInput} name="demo[]" headerTemplate={headerTemplate} url='https://api.cloudinary.com/v1_1/ddnbi0nfz/image/upload' chooseLabel='Escoger' cancelLabel='Cancelar' multiple accept="image/*" maxFileSize={1000000}
                            customUpload='true' uploadHandler={myUploader} 
                            emptyTemplate={<p className="m-0">Arrastre y suelte los archivos aquí para cargarlos.</p>} 
                            />
                        </div>
                        {
                            selectedUser ? (
                                <div className='field'>
                                    <label htmlFor="role">Rol</label>
                                    <Dropdown value={rol} options={rolItems} onChange={(e) => setRol(e.value)} placeholder="Selecciona un Rol"/>
                                </div>
                            ):(
                                ''
                            )
                        }
                        <div className='flex'>
                            <Button label="Guardar Datos"  type="submit"  loading={loading} icon="pi pi-check"   className="p-button-rounded p-button-success" />
                        </div>
                    </form>
                </Dialog>


                <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                        {product && <span>Estas seguro de eliminar al usuario <b>{product.name}</b>?</span>}
                    </div>
                </Dialog>


            </div>
        </div>
    );
};

export default UserManagement;