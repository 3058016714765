/* eslint-disable no-unused-vars */
import React from 'react'
import './cargando.css'
import { ProgressBar } from 'primereact/progressbar';

//import Bersati2 from '../../../assets/img/Bersati2.png' 
import BersatiLogo from '../../../assets/img/BersatiLogo.png'
const Cargando = () => {
  return (
    <div className='flex flex-column align-items-stretch text-center align-self-center'>
        <div className='flex align-content-center align-self-center flex-wrap align-items-center'>
            <img src={BersatiLogo} alt="logoRotate" className='m-8 h-10rem App-logo animation-duration-3000 flex align-items-center justify-content-center'/>    
        </div>
    </div>
  )
}

export default Cargando