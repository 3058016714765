/* eslint-disable default-case */
/* eslint-disable no-unused-vars */

import axios from "axios";


//CONSTANTES

const dataInicialCart = {
    arrayCart : [],
    loadingCart : true,
    error: ''
};

const dataInicialCartSave = {
    arrayCartS : [],
    loadingCartS : '',
    error:''
};

const dataInicialCartFive = {
    arrayCartF : [],
    loadingCartF : true,
    errorF:''
};

const dataInicialConfirmation = {
    arrayConf : [],
    loadingConf : true,
    errorConf:''
};


//TYPES

const OBTENER_CARTINDEX_EXITO = 'OBTENER_CARTINDEX_EXITO'
const OBTENER_CARTINDEX_REQUEST = 'OBTENER_CARTINDEX_REQUEST'
const OBTENER_CARTINDEX_ERROR = 'OBTENER_CARTINDEX_ERROR'

const OBTENER_CARTSAVE_EXITO = 'OBTENER_CARTSAVE_EXITO'
const OBTENER_CARTSAVE_REQUEST = 'OBTENER_CARTSAVE_REQUEST'
const OBTENER_CARTSAVE_ERROR = 'OBTENER_CARTSAVE_ERROR'

const OBTENER_CARTFIVE_EXITO = 'OBTENER_CARTFIVE_EXITO'
const OBTENER_CARTFIVE_REQUEST = 'OBTENER_CARTFIVE_REQUEST'
const OBTENER_CARTFIVE_ERROR = 'OBTENER_CARTFIVE_ERROR'

const OBTENER_CONFIRMATION_EXITO = 'OBTENER_CONFIRMATION_EXITO'
const OBTENER_CONFIRMATION_REQUEST = 'OBTENER_CONFIRMATION_REQUEST'
const OBTENER_CONFIRMATION_ERROR = 'OBTENER_CONFIRMATION_ERROR'




//REDUCERS

export function cartIndexReducer (state = dataInicialCart, action){
    switch (action.type) {
        case OBTENER_CARTINDEX_REQUEST:
            return { loadingCart: true, arrayCart:[] };
        case OBTENER_CARTINDEX_EXITO:
            return {...state, arrayCart: action.payload, loadingCart: false}; 
        case OBTENER_CARTINDEX_ERROR:
            return {error: action.payload, loadingCart: false};       
        default:
            return state
    }
}

export function cartSaveReducer (state = dataInicialCartSave, action){
    switch (action.type) {
        case OBTENER_CARTSAVE_REQUEST:
            return { loadingCartS: true, arrayCartS:[] };
        case OBTENER_CARTSAVE_EXITO:
            return {...state, arrayCartS: action.payload, loadingCartS: false};   
        case OBTENER_CARTSAVE_ERROR:
            return {error: action.payload, loadingCartS: false};     
        default:
            return state
    }
}

export function cartIndexFiveReducer (state = dataInicialCartFive, action){
    switch (action.type) {
        case OBTENER_CARTFIVE_REQUEST:
            return { loadingCartF: true, arrayCartF:[] };
        case OBTENER_CARTFIVE_EXITO:
            return {...state, arrayCartF: action.payload, loadingCartF: false};   
        case OBTENER_CARTFIVE_ERROR:
            return {errorF: action.payload, loadingCartF: false};     
        default:
            return state
    }
}

export function ConfirmationReducer (state = dataInicialConfirmation, action){
    switch (action.type) {
        case OBTENER_CONFIRMATION_REQUEST:
            return { loadingConf: true, arrayConf:[] };
        case OBTENER_CONFIRMATION_EXITO:
            return {...state, arrayConf: action.payload, loadingConf: false};   
        case OBTENER_CONFIRMATION_ERROR:
            return {errorF: action.payload, loadingConf: false};     
        default:
            return state
    }
}




//ACCIONES

export const cartIndexAccion = (token, id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CARTINDEX_REQUEST})

        const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carrito/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }


        const res = await axios(config)
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_CARTINDEX_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}

export const cartSaveAccion = (data, token) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CARTSAVE_REQUEST})

        /*console.log(token)
        console.log(data)*/
        const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'post',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritoadd',
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            },
            data: data,
        }

        const res = await axios(config)
        console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_CARTSAVE_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}


export const cartIndexFiveAccion = (token, id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CARTFIVE_REQUEST})

        /*console.log(token)
        console.log(data)*/
        const tok = 'Bearer ' + token;
        //console.log(tok)
        const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }

        const res = await axios(config)
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_CARTFIVE_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}


export const confirmationAccion = (id, venta_id) => async (dispatch) => {
    
    try {
        dispatch({type:OBTENER_CARTFIVE_REQUEST})

        /*console.log(token)
        console.log(data)*/
        //const tok = 'Bearer ' + token;
        //console.log(tok)
        /*const config = {
            method: 'get',
            url: process.env.REACT_APP_UNSPLASH_URL+'/carritofive/'+id,
            headers:{
                'Content-Type':'application/json',
                'Authorization': tok,
            }
        }*/

        //const res = await axios(config)

        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/confirmationsale/'+venta_id+'/'+id)
        //console.log(res)
        if (res.status === 202) {
            console.log(res)
        }else{
            dispatch({
                type: OBTENER_CONFIRMATION_EXITO,
                payload: res.data
            })
        }
        
    } catch (error) {
        console.log(error)
    }
}