/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* react-hooks/exhaustive-deps */

import React, { useRef, useState } from 'react';
import {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { StyleClass } from 'primereact/styleclass';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Sidebar } from 'primereact/sidebar';


import { Link, Outlet, useLocation  } from 'react-router-dom'
import { obtenerNavbarAccionC } from '../../../Redux/patos/navbarDuck';


import './Navbar.css'
const NavbarNew = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    
    const toggleBtnUser = useRef(null)
    const unset = {backgroundColor: 'unset'}
    const surface = {backgroundColor: 'rgb(37, 37, 37, 0.9', color: '#fff'}
    const surfacePlomo = {backgroundColor: '#939599', color: '#fff'}
    const surfaceCafe = {backgroundColor: '#b9a199', color: '#fff'}
    const clases = "shadow-none lg:shadow-2 lg:absolute w-full left-0 top-100 pl-2 md:pl-8 md:pr-6 z-2 lg:px-6 py-0 lg:py-4 line-height-2"
    const vacio = ""
    const [BgColor, setBgColor] = useState(unset)
    const [BgColorRes, setBgColorRes] = useState(unset)
    const [oculto, setOculto] = useState("hidden")
    const [ocultoNav, setOcultoNav] = useState("hidden")
    const [textColor, setTextColor] = useState("text-white")

    const [visible, setVisible] = useState(false);
    const [tipoMenu, setTipoMenu] = useState(unset)


    const Btn3 = useRef(null);

    useEffect(() => {
        const generarData = () =>{

            dispatch(obtenerNavbarAccionC())
        }
        generarData()
    },[dispatch])

    /*useEffect(() => {

        const consultarEstadoMenu = () =>{
            if (location.pathname ==='/productos') {
                setTextColor('text-900')
            }else{
                setTextColor('text-white')
            }
        }

        consultarEstadoMenu();

    },[])*/
    

    const navbarC = useSelector(store => store.navbarListaC)

    const cabeza = <diV><h1>BERSATI</h1></diV>;

    useEffect(() => { 
        if (location.pathname === '/quienesomos' || location.pathname === '/marcasxbersati' || location.pathname === '/contacto') {
            setTipoMenu('absolute')
            setBgColor(unset)
        }else{
            setTipoMenu('static')
            setBgColor(surfaceCafe)
        }
    }, [location.pathname]);

    
        /*const refContainer = useRef(null); 
        useEffect(() => { 
            refContainer.current.scrollIntoView({ behavior: "smooth" }); 
        }); */

        //location.pathname === '/quienesomos'

//https://www.youtube.com/watch?v=_xR8Js2b6IU

    //console.log(navbarC)
    return ( 
        /** ref={refContainer}*/
        <div  className='relative'>
            {/*<div style={BgColor} className="flex absolute font-normal w-full pt-4 px-3 md:px-6 align-items-center justify-content-between top-0 z-2"></div>*/}

            <div style={location.pathname === '/quienesomos' || location.pathname === '/marcasxbersati' || location.pathname === '/contacto' ? BgColor : surfaceCafe} className={`flex ${tipoMenu} font-normal w-full pt-3  px-3 md:px-6 align-items-center justify-content-between top-0 z-2`}>
                

                    <div className='flex w-full align-items-center justify-content-between lg:justify-content-start '>
                        <div  className="flex align-items-end flex-wrap md:px-5">    
                            <Link  to={'/'} className={`${textColor} no-underline align-items-end flex cursor-pointer block text-6xl  vertical-align-baseline sombrita font-normal`}>
                                BERSATI
                            </Link>
                        </div>
                        <a className={`${textColor} cursor-pointer block lg:hidden `} onClick={(e) => setVisible(true)}>
                            <i className="pi pi-bars text-4xl "></i>
                        </a>

                        <div style={BgColorRes} className={`${ocultoNav} w-full align-items-center justify-content-between lg:flex absolute lg:static left-0 top-100 px-6 lg:px-0 lg:shadow-none vertical-align-middle`} onMouseLeave={() => setOcultoNav("hidden")}>
                        <section></section>
                            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                                <li>
                                    <a onMouseEnter={ () => { setBgColor(surface); setTextColor('text-white') ; setOculto(vacio) }} onMouseLeave={() => {setBgColor(unset); setTextColor('text-white') ; setOculto("hidden"); setOcultoNav("hidden")}} ref={toggleBtnUser} className={`${textColor} no-underline flex px-0 lg:pr-5  pt-3 pb-3 sombrita lg:pl-8 align-items-center font-normal hover:underline text-lg transition-colors transition-duration-150`}>
                                        <span className="no-underline text-xl vertical-align-middle font-light sombrita">CATÁLOGO</span>
                                    </a>   
                                    <div style={surface} className={ `${textColor} ${clases} ${oculto}` } onMouseEnter={ () => { setBgColor(surface); setTextColor('text-white') ; setOculto(vacio) }} onMouseLeave={() => {setBgColor(unset); setTextColor('text-white') ; setOculto("hidden"); setOcultoNav("hidden")}} >
                                        
                                        <div className="flex flex-column mt-4 lg:flex-row lg:justify-content-between">
                                            <div  className='grid w-full align-items-center md:px-5'>
                                                {
                                                    navbarC.loadingC ? (
                                                        <>
                                                            <div className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                                            </div>
                                                            <div className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                                            </div>
                                                            <div className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                                            </div>
                                                            <div className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                                            </div>
                                                            <div className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                                            </div>
                                                            <div className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-6rem md:w-12rem"></Skeleton>
                                                            </div>
                                                        </>
                                                        
                                                    )
                                                    :
                                                    (
                                                        <>
                                                        
                                                            <div className='col-12 md:col-4 py-0'>
                                                                <Link to={'/categorias'} className='text-white no-underline'>
                                                                    <p className='cursor-pointer text-xl my-1 hover:underline'>Ver todas las categorias.</p>
                                                                </Link>
                                                            </div>
                                                            {
                                                                navbarC.arrayC.map((navcate, index) => (
                                                            
                                                                    <div key={index} className='col-12 md:col-4 py-0'>
                                                                        <Link to={`/productos/${navcate.bCategoria}`} className="text-white no-underline ">
                                                                            <p className='cursor-pointer text-xl font-light my-1  hover:underline'>{navcate.nombreCategoria}</p>
                                                                        </Link>
                                                                    </div>
                                                                    
                                                                    
                                                                ))
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>
                                <li>
                                    <Link to={'/quienesomos'}  className={`${textColor} no-underline flex px-0 lg:px-5 py-3 align-items-center hover:underline text-lg transition-colors transition-duration-150`}>
                                        <span className="vertical-align-middle text-xl  font-light sombrita">QUIENES SOMOS</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/marcasxbersati'} className={`${textColor} no-underline flex px-0 lg:px-5 py-3 align-items-center hover:underline text-lg transition-colors transition-duration-150`}>
                                        <span className="vertical-align-middle text-xl  font-light sombrita">MARCAS</span>
                                    </Link> 
                                </li>
                                <li>
                                    <Link to={'/contacto'}  className={`${textColor} no-underline flex px-0 lg:px-5 py-3 align-items-center hover:underline text-lg transition-colors transition-duration-150`}>
                                        <span className="vertical-align-middle text-xl  font-light sombrita">CONTÁCTANOS</span>
                                    </Link> 
                                </li>
                            </ul>
                            <Sidebar id='sidenav' visible={visible} header={cabeza} position="left" className="p-sidebar-sm h-full" onHide={() => setVisible(false)} icons={() => (
                                <React.Fragment >
                                    <h2 className='flex left-0'>BERSATI</h2>
                                </React.Fragment>
                            )}>
                                <div className="overflow-y-auto flex-1">
                                    <ul className="list-none p-0 m-0" >
                                        <li>
                                            <StyleClass nodeRef={Btn3} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                                <a ref={Btn3} className="flex px-0 py-3 text-lg align-items-center cursor-pointer border-transparent transition-duration-150 transition-colors" style={{borderRadius: "12px"}}>
                        
                                                    <span className="font-light">CATÁLOGO</span>
                                                    <i className="pi pi-chevron-down ml-auto"></i>
                                                </a>
                                            </StyleClass>
                                                <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                    {
                                                    navbarC.loadingC ? (
                                                        <>
                                                            <li className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-full md:w-12rem"></Skeleton>
                                                            </li>
                                                            <li className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-full md:w-12rem"></Skeleton>
                                                            </li>
                                                            <li className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-full md:w-12rem"></Skeleton>
                                                            </li>
                                                            <li className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-full md:w-12rem"></Skeleton>
                                                            </li>
                                                            <li className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-full md:w-12rem"></Skeleton>
                                                            </li>
                                                            <li className='"hidden md:flex lg:flex mt-4 col-12 md:col-4'>
                                                                <Skeleton className="mb-2 h-2rem w-full md:w-12rem"></Skeleton>
                                                            </li>
                                                        </>
                                                        
                                                    )
                                                    :
                                                    (
                                                        navbarC.arrayC.map((navcate, index) => (
                                                    
                                                            <li key={index} className='col-12 md:col-4'>
                                                                <Link to={`/productos/${navcate.bCategoria}`} className="text-900 no-underline ">
                                                                    <p className='cursor-pointer font-light text-lg hover:underline'>{navcate.nombreCategoria}</p>
                                                                </Link>
                                                            </li>
                                                            
                
                                                        ))
                                                    )
                                                }
                                                </ul>
                                        </li>
                                        <li>
                                            <Link to={'/quienesomos'} className="no-underline text-900 flex px-0 lg:px-5 py-3 align-items-center hover:underline text-lg ">
                                                <span className="vertical-align-middle font-light">QUIENES SOMOS</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/marcasxbersati'} className="no-underline text-900 flex px-0 lg:px-5 py-3 align-items-center hover:underline text-lg ">
                                                <span className="vertical-align-middle font-light">MARCAS</span>
                                            </Link> 
                                        </li>
                                        <li>
                                            <Link to={'/contacto'} className="no-underline text-900 flex px-0 lg:px-5 py-3 align-items-center hover:underline text-lg ">
                                                <span className="vertical-align-middle font-light">CONTÁCTANOS</span>
                                            </Link> 
                                        </li>
                                    </ul>
                                </div>
                            </Sidebar>
                        </div>
                    </div>

                    <div className='hidden align-items-center hidden lg:flex lg:static left-0 top-100 px-6 lg:px-0 lg:shadow-none vertical-align-middle'>
                        <Button icon="pi pi-search"  className="p-button-rounded p-button-secondary p-button-outlined p-button-sm "  aria-label="Search" />
                    </div>
            </div>
        </div>
     )
}
 
export default NavbarNew;