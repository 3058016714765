/* eslint-disable no-unused-vars */
import { createContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'


import axios from "axios";

export const PaymentContext = createContext();

const PaymentProvider = ({children}) => {

    const navigate = useNavigate();

    const [usuarioId, setUsuarioId] = useState('');
    const [tipoEnvio, setTipoEnvio] = useState('');
    const [userMap, setUserMap] = useState('');
    const [nacional, setNacional] = useState([]);
    const [local, setLocal] = useState('');

    const [productosFinal, setProductosFinal] = useState('');
    const [subTotalFinal, setSubTotalFinal] = useState('');
    const [totalFinal, setTotalFinal] = useState('');


    return (
        <PaymentContext.Provider value={{usuarioId, setUsuarioId, tipoEnvio, setTipoEnvio, userMap, setUserMap, nacional, 
                                        setNacional, local, setLocal, subTotalFinal, setSubTotalFinal, totalFinal, setTotalFinal, productosFinal, setProductosFinal}}>
            {children}
        </PaymentContext.Provider>
      )
}

export default PaymentProvider