/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
//import { ProductService } from '../service/ProductService';
import './EditPrecioProducto.css';

import axios from "axios";

const EditPrecioProducto = () => {

    const [products4, setProducts4] = useState(null);
    const [loading, setLoading] = useState(true);

    const toast = useRef(null);


    //const productService = new ProductService();

    useEffect(() => {
        setLoading(true)
        const generarData = async () =>{
            
            const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

            setProducts4(res.data)
        }
        generarData()
        setLoading(false)
      
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isPositiveInteger = (val) => {
        //let str = String(val);
        let str = val;
        //str = ;
        //console.log(Number.parseFloat(val))
        if (!Number.parseFloat(val)) {
            //console.log('es número');
            return false;
        }
        return true;
    }

    const generarData = async () =>{
        setLoading(true)
        const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')

        setProducts4(res.data)
        setLoading(false)
    }

    const onCellEditComplete = (e) => {
        //console.log(e.newRowData.id)
        let { rowData, newValue, field, originalEvent: event } = e;
        let idCon = rowData.id;
        switch (field) {
            //case 'quantity':
            case 'precio':
                if (isPositiveInteger(newValue))
                    //console.log('valor');
                    updatePrecio(newValue, idCon)
                    //rowData[field] = newValue;
                else
                    //console.log('Salio');
                    event.preventDefault();
                break;

            default:
                break;
        }
    }

    const updatePrecio = async (newValue, id) => {

        let data = {
            precio : newValue
        }

        const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/save/precioproducto/${id}`, data);

        //console.log(resSave)

        if (resSave.status === 200) {      
            toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Precio editado con éxito.', life: 3000 });
  
        }else {
            toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Precio NO editado.', life: 3000 });
        }

        generarData()
    }


    const cellEditor = (options) => {
        if (options.field === 'precio')
            return priceEditor(options);
        else
            return textEditor(options);
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }


    const priceEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="BOB" locale="en-ES" />
    }


    const priceBodyTemplate = (rowData) => {
        return new Intl.NumberFormat('en-ES', { style: 'currency', currency: 'BOB' }).format(rowData.precio);
    }

  return (
    <>
        <Toast ref={toast} />
        <div className=' w-full overflow-scroll speeddial-linear-demo '>
            <div className="surface-section py-5 px-2 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">EDITOR DE PRECIOS BERSATI</span>
                </div>
            </div>
            <div className="datatable-editing-demo w-full px-2 md:px-3 lg:px-5" >
                <Toast ref={toast} />
                <div className="card w-full p-fluid " >
                    <DataTable value={products4} paginator rows={20} rowsPerPageOptions={[5, 10, 25]} loading={loading} filterDisplay="row" 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos" size="small"  showGridlines selectionMode="single"
                    editMode="cell"  className="editable-cells-table overflow-scroll" responsiveLayout="scroll" scrollHeight="flex" scrollable  >
                        {/*
                            columns.map(({ field, header }) => {
                                return <Column key={field} field={field} header={header} filter sortable style={{ width: '25%' }} body={field === 'price' && priceBodyTemplate}
                                    editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                            })
                        */}
                        <Column field='codigo' header='Código' filter sortable style={{ minWidth: '13rem' }}  />
                        <Column field='codigo_selkis' header='Cod. Selkis' filter sortable style={{ minWidth: '13rem' }}  />
                        <Column field='nombre' header='Nombre' filter sortable style={{ minWidth: '16rem' }}  />
                        <Column field='precio' header='Precio' filter sortable style={{ minWidth: '16rem' }} 
                        body={priceBodyTemplate} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                    </DataTable>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default EditPrecioProducto