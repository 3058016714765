import React from 'react'
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { Helmet } from 'react-helmet-async';

import imgPruebita from '../assets/img/404-error.jpg'


const NoBersati = () => {
  return (

    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 min-h-screen">
      <Helmet>
          <title>404 Not Found - Bersati</title> 
      </Helmet>
      <div className="flex flex-column lg:flex-row justify-content-center align-items-center gap-7">
          <div className="text-center lg:text-right">
              <div className="mt-6 mb-3 font-bold text-6xl text-900">Are you lost?</div>
              <p className="text-700 text-3xl mt-0 mb-6">Sorry, we couldn't find the page.</p>
              <Link to={'/'}>
                <Button to="/" label="Vuelve a la página de incio." className="p-button-secondary"></Button >
              </Link>
          </div>
          <div>
              <img src={imgPruebita} alt="error404" className="w-full md:w-28rem" />
          </div>
      </div>
    </div>
  )
}

export default NoBersati