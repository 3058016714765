/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';

import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import {useDispatch, useSelector} from 'react-redux'

import { despachoIndexAccion1 } from '../../../../../Redux/patos/DespachosDuck'; 
import { despachoIndexAccion2 } from '../../../../../Redux/patos/DespachosDuck'; 
import { despachoIndexAccion3 } from '../../../../../Redux/patos/DespachosDuck'; 

import { Link, useNavigate } from "react-router-dom";

const EntregaPedidosBersati = () => {

    const dispatch = useDispatch();
    const toast = useRef(null);
    const navegate = useNavigate();


    const {arrayDes1, loadingDes1} = useSelector(store => store.storeDespacho1)
    const {arrayDes2, loadingDes2} = useSelector(store => store.storeDespacho2)
    const {arrayDes3, loadingDes3} = useSelector(store => store.storeDespacho3)


    useEffect(() => {

        dispatch(despachoIndexAccion1())        
        dispatch(despachoIndexAccion2())  
        dispatch(despachoIndexAccion3())  
        
    },[dispatch])


    const fechaDes = (data) =>{
        const opciones = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        let fechita = new Date(data);
        return(
            <React.Fragment>
                {fechita.toLocaleDateString('es-Es', opciones)}
            </React.Fragment>
        )
    }

    //console.log(arrayDes3)

  return (
    <>
        <div className='w-full overflow-scroll speeddial-linear-demo '>
            <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">Despachos Bersati</span>
                </div>  
                
                <div className='mt-4'>
                    <Panel className='mt-4' header="Despacho Santa Cruz">
                        <div className="grid">
                            {
                                loadingDes1 ? (
                                    <p>Cargando...</p>
                                ):(
                                    arrayDes1.length > 0 ?(
                                        arrayDes1.map((des, index)=>(    
                                            <div key={index} className="col-12 md:col-4 xl:col-4 p-3">
                                                <div  className="surface-card shadow-2 border-rounded p-4">
                                                    <div className="flex border-bottom-1 surface-border pb-4">
                                                        <div className="flex flex-column align-items-start">
                                                            <span className="text-lg text-900 font-medium mb-3">{des.nombres} {des.apellido_p} {des.apellido_m}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Numero de Orden:</span> {des.num_order}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Fecha del Pedido:</span> {fechaDes(des.created_at)}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Dirección:</span> {des.direccion}</span>
                                                            <span className="text-600 font-medium mb-2"><span className='font-bold'>Total:</span> BOB. {des.total_total}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-content-end pt-4">
                                                        <Button label="Ver" onClick={() => navegate('/protected/admin/despachosbersatidetalle/'+des.num_order)} icon="pi pi-search" className=" p-button-secondary w-6 mr-2"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                    :
                                    (
                                        <div className="col-12 md:col-4 xl:col-4 p-3">
                                            <div className="surface-card shadow-2 border-rounded p-4">
                                                <div className="flex surface-border pb-4">
                                                    <div className="flex flex-column align-items-start">
                                                        <span className="text-600 font-medium mb-2">Sin Registro</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
 
                        </div>
                    </Panel>

                    <Panel className='mt-4' header="Despacho Departamental">
                        <div className="grid">
                            {
                                loadingDes2 ? (
                                    <p>Cargando...</p>
                                ):(
                                    arrayDes2.length > 0 ?(
                                        arrayDes2.map((des, index)=>(    
                                            <div key={index} className="col-12 md:col-4 xl:col-4 p-3">
                                                <div className="surface-card shadow-2 border-rounded p-4">
                                                    <div className="flex border-bottom-1 surface-border pb-4">
                                                        <div className="flex flex-column align-items-start">
                                                            <span className="text-lg text-900 font-medium mb-3">{des.nombres} {des.apellido_p} {des.apellido_m}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Numero de Orden:</span> {des.num_order}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Fecha del Pedido:</span> {fechaDes(des.created_at)}</span>
                                                            <span className="text-600 font-medium mb-2"><span className='font-bold'>Total:</span> BOB. {des.total_total}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-content-end pt-4">
                                                        <Button label="Ver" onClick={() => navegate('/protected/admin/despachosbersatidetalle/'+des.num_order)} icon="pi pi-search" className=" p-button-secondary w-6 mr-2"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                    :
                                    (
                                        <div className="col-12 md:col-4 xl:col-4 p-3">
                                            <div className="surface-card shadow-2 border-rounded p-4">
                                                <div className="flex surface-border pb-4">
                                                    <div className="flex flex-column align-items-start">
                                                        <span className="text-600 font-medium mb-2">Sin Registro</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </Panel>

                    <Panel className='mt-4' header="Despacho En Tienda">
                        <div className="grid">
                            {
                                loadingDes3 ? (
                                    <p>Cargando...</p>
                                ):(
                                    arrayDes3.length > 0 ?(
                                        arrayDes3.map((des, index)=>(    
                                            <div key={index} className="col-12 md:col-4 xl:col-4 p-3">
                                                <div className="surface-card shadow-2 border-rounded p-4">
                                                    <div className="flex border-bottom-1 surface-border pb-4">
                                                        <div className="flex flex-column align-items-start">
                                                            <span className="text-lg text-900 font-medium mb-3">{des.nombres} {des.apellido_p} {des.apellido_m}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Numero de Orden:</span> {des.num_order}</span>
                                                            <span className="text-600 font-medium mb-2"> <span className='font-bold'>Fecha del Pedido:</span> {fechaDes(des.created_at)}</span>
                                                            <span className="text-600 font-medium mb-2"><span className='font-bold'>Total:</span> BOB. {des.total_total}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-content-end pt-4">
                                                        <Button label="Ver" onClick={() => navegate('/protected/admin/despachosbersatidetalle/'+des.num_order)} icon="pi pi-search" className=" p-button-secondary w-6 mr-2"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                    :
                                    (
                                        <div className="col-12 md:col-4 xl:col-4 p-3">
                                            <div className="surface-card shadow-2 border-rounded p-4">
                                                <div className="flex surface-border pb-4">
                                                    <div className="flex flex-column align-items-start">
                                                        <span className="text-600 font-medium mb-2">Sin Registro</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </Panel>
                </div>

            </div>
        </div>
    </>
  )
}

export default EntregaPedidosBersati