/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { PickList } from 'primereact/picklist';
import { Badge } from 'primereact/badge';
import { useFormik, useFormikContext } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import { SpeedDial } from 'primereact/speeddial';
import { Button } from 'primereact/button';

import { ScrollTop } from 'primereact/scrolltop';
import { Fab, Action } from 'react-tiny-fab';

import axios from "axios";


import './EditCategoriaProducto.css';

const EditCategoriaProducto = () => {

    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const [categorias, setCategorias] = useState(null);
    const [familias, setFamilias] = useState(null);
    const [lineas, setLineas] = useState(null);
    const [gp, setGP] = useState(null);


    const [familias1, setFamilias1] = useState([]);
    const [lineas1, setLineas1] = useState(null);
    const [gp1, setGP1] = useState(null);


    const [estadoFami, setEstadoFami] = useState(true);
    const [estadoLine, setEstadoLine] = useState(true);
    const [estadoGP, setEstadoGP] = useState(true);
    const [estadoList, setEstadoList] = useState(true);
    const [estadoSave, setEstadoSave] = useState(true);

    const toast = useRef(null);
    //const { , submitForm } = useFormikContext();
    const formRef = useRef()
    //const { submitForm } = useFormikContext();


    useEffect(() => {
        setLoading(true)
            const generarData = async () =>{
                
                const res = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/productoindex')
                const resC = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/categorias')
                const resF = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/familias')
                const resL = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/lineas')
                const resGP = await axios.get(process.env.REACT_APP_UNSPLASH_URL+'/save/gp')
    
                setSource(res.data)
                setCategorias(resC.data)
                setFamilias(resF.data)
                setLineas(resL.data)
                setGP(resGP.data)

                setLoading(false)
            }

            generarData()
    
        //setLoading(false)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            categoria: '',
            familia: '',
            linea: '',
            grupoProductos: '',
            productos:''
        },onSubmit: (data) =>{
            data.productos = target;
            if (data.productos.length > 0 || data.gp === '') {
                //console.log(data)
                GuardarData(data)
            }else{
                toast.current.show({ severity: 'error', summary: 'Sin Productos', detail: 'Añada productos a la lista.', life: 4000 });
                //setEstadoSave(true)
            }
            
        }
    })

    const GuardarData = async (data) =>{
        setLoading1(true)

        const saveData = async () =>{
            const resSave = await axios.post(process.env.REACT_APP_UNSPLASH_URL+`/save/cflgp_productos`, data);

            //console.log(resSave)
            
            if (resSave.status === 200) {    
                setLoading1(false)  
                toast.current.show({ severity: 'success', summary: 'Acción Exitosa!', detail: 'Datos Guardados.', life: 3000 });
                setTarget([])
            }else {
                toast.current.show({ severity: 'error', summary: 'Acción Rechazada!', detail: 'Datos NO Guardados.', life: 3000 });
            }
        }

        await saveData()
    }

    useEffect(()=>{
       
        const respuesta = () =>{
            if (formik.values.categoria) {
                setEstadoFami(false)
                let varcito = []
                formik.values.familia = ''
                formik.values.linea = ''
                formik.values.grupoProductos = ''
                setEstadoSave(true)
                for (let index = 0; index < familias.length; index++) {
                    if (familias[index].categoria_id === formik.values.categoria) {
                        varcito.push(familias[index])
                    }else{
                        //familias1.splice(index, 1)
                    }
                }
                setFamilias1(varcito)

            }else{
                setEstadoFami(true)
            }
        }

        respuesta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formik.values.categoria])

    useEffect(()=>{
       
        const respuesta = () =>{
            if (formik.values.familia) {
                setEstadoLine(false)
                let varcito1 = []
                formik.values.linea = ''
                formik.values.grupoProductos = ''
                setEstadoSave(true)
                for (let index = 0; index < lineas.length; index++) {
                    if (lineas[index].familias_id === formik.values.familia) {
                        varcito1.push(lineas[index])
                    }else{
                        //familias1.splice(index, 1)
                    }
                }
                setLineas1(varcito1)

            }else{
                setEstadoLine(true)
            }
        }

        respuesta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formik.values.familia])

    useEffect(()=>{
       
        const respuesta = () =>{
            if (formik.values.linea) {
                setEstadoGP(false)
                let varcito2 = []
                formik.values.grupoProductos = ''
                setEstadoSave(true)
                for (let index = 0; index < gp.length; index++) {
                    if (gp[index].lineas_id === formik.values.linea) {
                        varcito2.push(gp[index])
                    }else{
                        //familias1.splice(index, 1)
                    }
                }
                setGP1(varcito2)

            }else{
                setEstadoGP(true)
            }
        }

        respuesta()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formik.values.linea])

    useEffect(()=>{
        const respuesta = () =>{
            if (formik.values.grupoProductos) {
                setEstadoList(false)
            }else{
                setEstadoList(true)
            }
            
        }
        respuesta()
    },[formik.values.grupoProductos])

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    }

    const itemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="image-container">
                    <img src={`images/product/${item.imagenPrincipal}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
                </div>
                <div className="product-list-detail">
                    <h5 className="mb-2">{item.nombre}</h5>
                    <i className="pi pi-tag product-category-icon"></i>
                    <span className="product-category" onError={(e) => e.target.label='...'}>{item.codigo}</span>
                    <div>
                        <span className="product-category">{item.nombreCategoria} - {item.nombreFamilia} - {item.nombreLinea} - {item.nombreGrupoProductos}</span>
                    </div>
                </div>
                <div className="product-list-action">
                    <h6 className="mb-2 marca">{item.marca}</h6>
                    {
                        item.estado ? (
                            <span className={`product-badge status-instock`}>ACTIVO</span>
                        )
                        :
                        (
                            <span className={`product-badge status-outofstock`}>OCULTO</span>
                        )
                    }
                    
                </div>
            </div>
        );
    }

    const mensajeonUnblocked = () =>{
        setEstadoSave(false)
        toast.current.show({ severity: 'info', summary: 'Productos Desbloqueados', detail: 'Seleccione los productos para asignar a las categorias correspondientes.', life: 4000 });
    }

    const items = [
        {
            label: 'Add',
            icon: 'pi pi-pencil',
            type:'submit',
            command: () => {
                const handleSubmit = () => {
                    if (formRef.current) {
                        formik.handleSubmit()
                      }
                  }
                  handleSubmit()
            }
        },
    ];
    //console.log(target)
    //console.log(formik.values.categoria)
    //console.log(formik.values.familia)
    //console.log(formik.values.linea)
    //console.log(formik.values.grupoProductos)

    const fil = ['codigo', 'nombre']

  return (
    <>
        <Toast ref={toast} />
        <div className='p-2  w-full overflow-scroll speeddial-linear-demo '>
            <div className="surface-section px-2 py-5 md:px-4 lg:px-6">
                <div className="border-bottom-1 surface-border">
                    <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">ASIGNACIÓN CFLGP A PRODUCTOS</span>
                </div>
                
            </div>
            
            <form  onSubmit={formik.handleSubmit}>
                <h3 className='px-2 mb-6 md:px-4 lg:px-6'>*Seleccione las caracteristicas primero</h3>
                <div className='card w-full px-2 mb-6 md:px-4 lg:px-6'>
                    <div className='grid p-fluid'>
                        <div className='col-12 md:col-6'>
                            <div className="field pt-3">
                                <BlockUI blocked={loading}>
                                    <span className="p-float-label p-1">
                                        
                                        <Dropdown inputId="categoria" name="categoria" filter  optionValue="id" value={formik.values.categoria} optionLabel="nombreCategoria" options={categorias} onChange={formik.handleChange}/>
                                        <label htmlFor="categoria" className='mb-2'>Categoría*</label>
                                    
                                    </span>
                                </BlockUI>
                            </div>
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="field pt-3">
                                <span className="p-float-label p-1">
                                    <Dropdown inputId="familia" name="familia" filter disabled={estadoFami} optionValue="id" value={formik.values.familia} optionLabel="nombreFamilia" options={familias1} onChange={formik.handleChange} />
                                    <label htmlFor="familia">Familia*</label>
                                </span>
                            </div>
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="field pt-3">
                                <span className="p-float-label p-1">
                                    <Dropdown inputId="linea" name="linea" filter disabled={estadoLine} optionValue="id" value={formik.values.linea} optionLabel="nombreLinea" options={lineas1} onChange={formik.handleChange} />
                                    <label htmlFor="linea">Linea*</label>
                                </span>
                            </div>
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className="field pt-3">
                                <span className="p-float-label p-1">
                                    <Dropdown inputId="grupoProductos" name="grupoProductos" filter disabled={estadoGP} optionValue="id" value={formik.values.grupoProductos} optionLabel="nombreGrupoProductos" options={gp1} onChange={formik.handleChange} />
                                    <label htmlFor="grupoProductos">Grupo de Productos*</label>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
                <h3 className='px-2 mb-6 md:px-4 lg:px-6'>*Seleccione los productos</h3>
                <div className="picklist-demo ">
                    <div className="card ">
                        <BlockUI blocked={estadoList} onUnblocked={mensajeonUnblocked} template={<i className="pi pi-lock text-white" style={{'fontSize': '5rem'}} />}>
                            <PickList className='' source={source} target={target} itemTemplate={itemTemplate} sourceHeader="Productos." targetHeader="Seleccionados."
                                sourceStyle={{ minHeight: '500px' }} targetStyle={{ minHeight: '500px' }} onChange={onChange}
                                filterBy={'codigo'}   sourceFilterPlaceholder="Buscar por Código" targetFilterPlaceholder="Buscar por Código" />
                        </BlockUI>
                    </div>
                </div>
                
                
            </form>
            <Fab
                style={{bottom: '10px', right: '-8px', padding:'0'}}
                icon = {<i className="pi pi-plus"></i>}
                alwaysShowTitle={true}
                >
                <Action
                    text="Guardar!"
                    style={{background: '#0f346c'}}
                    onClick={formik.handleSubmit}
                    disabled={estadoSave}
                >
                    <i className="pi pi-save"></i>
                </Action>
            </Fab>
            
        </div>
        
    </>
  )
}

export default EditCategoriaProducto