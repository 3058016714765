/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */


import React, {useContext, useEffect, useRef, useState } from 'react';
import { Divider } from 'primereact/divider';
import { Helmet } from 'react-helmet-async';

import imgMB from '../../../assets/img/marcas-banner.jpeg'
import marcaCasaa from '../../../assets/img/casaa-marca.avif'
import marcaSerraGaucha from '../../../assets/img/serragaucha-marca.png'
import marcaDKmobiliario from '../../../assets/img/dkmobiliario-marca.png'
import marcaElise from '../../../assets/img/elise-marca.png'
import marcaAncezki from '../../../assets/img/ancezki-marca.png'
import marcaAccord from '../../../assets/img/accord-marca.svg'

const MarcasBersati = () =>{

    
    
    
    
    return(

        <>
        <Helmet>
          <title>Marcas - Bersati</title> 
        </Helmet>
            <div className='flex relative p-6 overflow-hidden bg-no-repeat bg-cover bg-bottom h-45rem md:h-screen align-content-center' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${imgMB})`}}>
                    
                    <div className='flex-column align-content-end text-center w-full bottom-0 md:text-right '>

                        <div className="text-6xl lg:w-30rem text-white font-light mb-4">Ofrecemos muebles importados de alta calidad que transforman tus espacios.</div>
    
                    </div>
                    
            </div>

            <div className='surface-section px-4 py-8 md:px-6 lg:px-8'>
                <div className="font-bold text-900 text-3xl mb-3 text-center">Marcas que definen estilo y calidad.</div>
                <div className="text-700 text-center mb-3">Trabajamos con marcas reconocidas a nivel mundial que comparten nuestra pasión por la calidad, el diseño y la elegancia. Descubre las firmas que nos ayudan a transformar cada espacio en algo único.</div>

                <div className='flex flex-wrap mt-8 justify-content-center'>
                    <div className='w-full p-6 md:w-9 md:px-8 lg:w-4 xl:w-20rem lg:p-5 lg:my-0'>
                        <img src={marcaCasaa} alt="logo-casaa" className="w-full h-6rem"/>
                        <Divider layout="horizontal" className="surface-border my-2 max-w-screen"></Divider>
                        <div className="mt-3 mb-2 font-medium text-900 text-xl text-center	">CASAA BR</div>
                    </div>
                    <div className='w-full p-6 md:w-9 md:px-8 lg:w-4 xl:w-20rem lg:p-5 lg:my-0'>
                        <img src={marcaSerraGaucha} alt="logo-casaa" className="w-full h-6rem"/>
                        <Divider layout="horizontal" className="surface-border my-2 max-w-screen"></Divider>
                        <div className="mt-3 mb-2 font-medium text-900 text-xl text-center	">SERRA GAUCHA BR</div>
                    </div>
                    <div className='w-full p-6  md:w-9 md:px-8 lg:w-4 xl:w-20rem lg:p-5 lg:my-0'>
                        <img src={marcaDKmobiliario} alt="logo-casaa" className="w-full h-6rem bg-black-alpha-70"/>
                        <Divider layout="horizontal" className="surface-border my-2 max-w-screen "></Divider>
                        <div className="mt-3 mb-2 font-medium text-900 text-xl text-center	">DK MOBILIARIO BR</div>
                    </div>
                    <div className='w-full p-6  md:w-9 md:px-8 lg:w-4 xl:w-20rem lg:p-5 lg:my-0'>
                        <img src={marcaElise} alt="logo-casaa" className="w-full h-6rem"/>
                        <Divider layout="horizontal" className="surface-border my-2 max-w-screen"></Divider>
                        <div className="mt-3 mb-2 font-medium text-900 text-xl text-center	">CASAA BR</div>
                    </div>
                    <div className='w-full p-6  md:w-9 md:px-8 lg:w-4 xl:w-20rem lg:p-5 lg:my-0'>
                        <img src={marcaAncezki} alt="logo-casaa" className="w-full h-6rem"/>
                        <Divider layout="horizontal" className="surface-border my-2 max-w-screen"></Divider>
                        <div className="mt-3 mb-2 font-medium text-900 text-xl text-center	">SERRA GAUCHA BR</div>
                    </div>
                    <div className='w-full p-6  md:w-9 md:px-8 lg:w-4 xl:w-20rem lg:p-5 lg:my-0'>
                        <img src={marcaAccord} alt="logo-casaa" className="w-full h-6rem"/>
                        <Divider layout="horizontal" className="surface-border my-2 max-w-screen "></Divider>
                        <div className="mt-3 mb-2 font-medium text-900 text-xl text-center	">DK MOBILIARIO BR</div>
                    </div>
                </div>

            </div>
        </>

    )

}

export default MarcasBersati