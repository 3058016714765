/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */


import React, {useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import imgQS from '../../../assets/img/bersati_quienes_somos1.jpg'
import imgQS1 from '../../../assets/img/poltrona_quienes_somos_true.png'

const QuieneSomos = () =>{

    
    
    
    
    return(

        <>
        <Helmet>
          <title>Quienes Somos - Bersati</title> 
        </Helmet>
            <div className='flex relative p-6 overflow-hidden bg-no-repeat bg-cover bg-top h-45rem md:h-screen align-content-center' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imgQS})`}}>
                    
                    <div className='flex-column align-content-end text-center w-full bottom-0 md:text-right '>

                        <div className="text-6xl text-white font-light mb-4">Elegancia y distinción en cada detalle.</div>
    
                    </div>
    
            </div>
            <div className='surface-section py-8 block'>
                <div className='grid'>
                    <div className='col-12 md:col-6 lg:col-6 p-4 flex align-content-center flex-column  md:align-self-center px-4 md:px-6 lg:px-8 h-auto'>
                        <p className="flex text-600 font-medium text-xl align-items-center">Somos el equipo..</p>
                        <div className="text-900 font-bold text-7xl mt-2">BERSATI</div>
                        <p className="text-600 text-xl mt-3 mb-5 line-height-3 text-justify">Creamos espacios que inspiran. En Bersati, nos especializamos en la selección de muebles elegantes y exclusivos, importados de las mejores fábricas del mundo. Nuestra pasión por el diseño y la calidad se refleja en cada pieza, brindando estilo y distinción a cada rincón de tu hogar.</p>       
                    </div>
                    <div className='flex col-12 md:col-6 lg:col-6 p-0 justify-content-end'>
                        <img src={imgQS1} className="relative w-auto h-35rem right-0" alt="ImgPoltrona" />
                    </div>
                </div>
            </div>
        </>
    )

}

export default QuieneSomos