/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from "axios";
import { BlockUI } from 'primereact/blockui';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
//import Cookies from 'js-cookie'


const GetProducto = () => {

  const [data, setData] = useState(null);
  const [mensage, setMensage] = useState('');
  const [mensage1, setMensage1] = useState('');
  const [dataPro, setDataPro] = useState([]);

  const [blockedPanel, setBlockedPanel] = useState(false);


  useEffect(() => {

    const generarData = async () =>{

      /*var config = {
        method: 'get',
        url:'http://localhost:5000/api/productos',
        headers: { 
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/json"
        }
      };
      
        
      await Axios(config).then((response) => {
          console.log(response.data)
      }).catch(function (error) {
        //console.log(error);
           console.log(error)
        });*/

        const res = await axios.get('http://localhost:5000/api/productos')
        console.log(res)
    }
        

    //generarData()


  },[])

  //const XMLHttpRequest = require("xmlhttprequest").XMLHttpRequest;
  //const xhr = new XMLHttpRequest();

const pedirData = async () =>{

  var config = {
    method: 'get',
    url: process.env.REACT_APP_UNSPLASH_URL+'/selkis/prod',
    headers: { 
      "Content-Type": "application/json"
    }
  };
  
    
  await axios(config).then((response) => {
      console.log(response.data)
      setData(response.data)
  }).catch(function (error) {
    //console.log(error);
       console.log(error)
  });
}

const procesarData = () =>{
  //console.log(data.resultado)

  let itemus = [];
  for (let index = 0; index < data.resultado.length; index++) {
    
    if (index === 0) {
      
      let varcito = {
        contador : parseInt(data.resultado.length),
      }
      itemus.push(varcito)
    }else{
      let varcito = {
        id : data.resultado[index][0],
        codigo : data.resultado[index][1],
        nombre : data.resultado[index][3],
        marca : data.resultado[index][5],
        precio : data.resultado[index][10],
      }
      itemus.push(varcito)
    }

    
    
  }

  setDataPro(itemus)
  setMensage('Datos Procesados.....')
}

const mandarData = async () =>{
  setBlockedPanel(true)
  const res = await axios.post(process.env.REACT_APP_UNSPLASH_URL+'/selkis/productos', dataPro);

  //console.log(res)
  if (res.status === 200) {
    //console.log(res.mensaje)
    setBlockedPanel(false)
    setMensage1('DATOS GUARDADOS')
  }else{
    setBlockedPanel(false)
    setMensage1('DATOS NO GUARDADOS - ERROR')
  }
  
}

const events1 = [
  { status: '1er Paso', descripcion: 'Se solicita la información a Selkis.', buton:<Button label="Solicitar Data" onClick={()=> pedirData()} />},
  { status: '2er Paso', descripcion: 'Se procesa dicha información en Bersati.', buton:<Button label="Procesar Data" onClick={()=> procesarData()} /> },
  { status: '3er Paso', descripcion: 'Se manda la información a la Base de Datos.', buton:<Button label="Enviar Data" onClick={()=> mandarData()} />},
];

const customizedContent = (item) => {
  return (
      <Card title={item.status}>
          
          <p>{item.descripcion}</p>
              {
                item.buton
              }
      </Card>
  );
};


//console.log(parseInt(data.totalCount) + 1)

//console.log(dataPro)
//console.log(dataPro.length)

  return (
    <>
      <BlockUI className='h-full border-round-md w-full  overflow-scroll' blocked={blockedPanel} template={<i className="pi pi-spin pi-spinner" style={{'fontSize': '5em'}} />}>
        <div className='border-round-md border-round-md w-full  overflow-scroll' >
          <div className='p-3'>
                <div className="surface-section px-2 py-5 md:px-3 lg:px-5">
                    <div className="border-bottom-1 surface-border">
                        <span className="block text-2xl md:text-3xl font-medium text-900 mb-4">SINCRONIZACIÓN CON LA BASE DE DATOS DE SELKIS</span>
                    </div>
                </div>
                <div className='px-2 md:px-3 lg:px-5'>

                  
                  <p><span className='text-color-secondary'>*Inicie Sesión en Selkis para poder sincronizar los productos de Selkis a Bersati.</span></p>
                  <p><span className='text-color-secondary'>*Sincronizar la información cada vez que se adicione un producto.</span></p>
                  {
                    data ? (
                    <div className='p-1'>
                      <Message severity="info" text={data.mensaje} className='mr-2' />
                      {
                        mensage === '' ? (
                          ''
                        ):(
                          <Message severity="info" text={mensage} />
                        )
                        
                      }
                      {
                        mensage1 === '' ? (
                          ''
                        ):(
                          <Message severity="info" text={mensage1} />
                        )
                      }
                    </div>
                    )
                    :
                    (
                      ""
                    )
                  }
                  
                  <div className='p-2 mt-4'>
                    <Timeline value={events1} content={customizedContent} align="alternate" />
                  </div>
                </div>
               
                
            </div>
            
        </div>
      </BlockUI>
        
        
    </>
  )
}

export default GetProducto