/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, {useContext, useEffect, useRef, useState } from 'react'

import { StyleClass } from 'primereact/styleclass';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { Tree } from 'primereact/tree';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { Helmet } from 'react-helmet-async';

import ReactPlayer from 'react-player'

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import {useDispatch, useSelector} from 'react-redux'
import { motion } from "framer-motion"


import { carouselIndexEcoAccion } from '../../../Redux/patos/EcoDuck';
import Cargando from '../comps/Cargando';

import { obtenerNavbarAccionC } from '../../../Redux/patos/navbarDuck';

import { UserContext } from '../../../context/UserProvider';
//import { Link } from 'react-router-dom';

import './Stylo.css'

import imgPruebita from '../../../assets/img/banner_foot.png'
import videoShow from '../../../assets/video/videoMostrar.mp4';

const InicioNew = () =>{

    const dispatch = useDispatch();
    const ecoCarousel = useSelector(store => store.ecoCarousel)
    const [page, setPage] = useState(0)

    const {userBer, userFire} = useContext(UserContext);

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    });
    const [cursorVariant, setCursorVariant] = useState("default");

    //console.log(mousePosition)

    useEffect(() => {

        const mouseMove = e =>{
            //console.log(e)
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener('mousemove', mouseMove);

        return () =>{
            window.removeEventListener('mousemove', mouseMove);
        }

    }, []);

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
      };

    const variants ={
        default:{
            x: mousePosition.x - 16,
            y: mousePosition.y - 16,
            visibility: 'hidden'
        },
        text:{
            height: 100,
            width: 100,
            x: mousePosition.x - 50,
            y: mousePosition.y - 50
        }
    }
    const [cursorX,setCursorX] = useState();
    const [cursorY,setCursorY] = useState();

   /* window.addEventListener('mousemove', (e) => {
        setCursorX(e.pageX)
        setCursorY(e.pageY)
    })*/

    useEffect(() => {
        const generarData = () =>{   
            dispatch(carouselIndexEcoAccion())
            
        }
        generarData()
    },[dispatch])


    useEffect(() => {
        const generarData = () =>{

            dispatch(obtenerNavbarAccionC())
        }
        generarData()
    },[dispatch])

    const textEnter = () => setCursorVariant("text")
    const textLeave = () => setCursorVariant("default")

    const navbarC = useSelector(store => store.navbarListaC)
    const video = 'https://res.cloudinary.com/ddnbi0nfz/video/upload/v1729882446/jus52edf8n5qtxegpszx.mp4'

   // console.log(navbarC )

    return(

            <div className='w-screen overflow-x-hidden '>
                <Helmet>
                    <title>Bersati</title> 
                </Helmet>
                {/*<div className="surface-section">
                    <div className="surface-section h-45rem bg-no-repeat bg-cover bg-center flex align-items-center" style={{background: ` url(${LogoPrueba})`}} >
                        {/* <div className="px-4 mx-4 lg:px-6 lg:mx-6"> 
                            <span className="block text-3xl text-white mb-4">New Trend</span>
                            <span className="block text-5xl font-medium text-white mb-4">Special Collection</span>
                            <a className="py-2 w-13rem text-center block mb-4 text-xl text-white font-medium border-2 cursor-pointer surface-border-0 border-round bg-white-alpha-30">Explore Collection</a>
                        </div>
                    </div>
                </div>*/}
            

                <video autoPlay muted loop className='w-full' >
                    <source className='' src="https://res.cloudinary.com/ddnbi0nfz/video/upload/v1729882446/jus52edf8n5qtxegpszx.mp4" type='video/mp4' />
                </video>


                <Divider layout="horizontal" className="surface-border mt-8 max-w-screen"></Divider>
                
                
                <div className='surface-section  md:py-8 z-2'>

                    
                    <div className='grid '>
                        {
                            navbarC.loadingC ? (
                                
                                    <div className='col-12'> <Cargando /> </div>
                                
                                
                            )
                            :
                            (
                                navbarC.arrayC.map((navcate, index) => (
                                    
                                        index === 0 || index % 2 === 0 ? 
                                            (
                                            <div key={index} className='col-12 md:col-12 lg:col-6 mb-8'>
                                                <div className='relative'>
                                                    <div className=' box '>
                                                        <div className='movede grid flex flex-wrap align-content-center align-items-center justify-content-end z-1'>
                                                            <div onMouseEnter={textEnter} onMouseLeave={textLeave}  className='p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                                                <h1 className='mb-0 font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                                
                                                                <h2 className='text-4xl md:text-right px-6 md:pl-4'>{navcate.descripcion}</h2>
                                                            </div>
                                                            <div  id='imagenPrueba' className='p-0 col-12 md:col-7 flex justify-content-center md:justify-content-start align-items-center cursor-pointer z-1'>
                                                                <Link to={`/productos/${navcate.bCategoria}`} className="no-underline">
                                                                    <img onMouseEnter={textEnter} onMouseLeave={textLeave}  src={`${navcate.image}`} alt="stad1" className="flex imgE w-20rem h-30rem border-round "/>
                                                                </Link>
                                                            </div>
                                                            
                                                        </div>
                                                        {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} */}
                                                        <div id='activador'  className='fondoCate absolute h-25rem p-0 m-0 w-10 z-0 left-0'>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            )
                                        :
                                            (
                                            <div key={index} className='col-12 md:col-12 lg:col-6 mb-8'>
                                                <div className='relative'>
                                                    <div className='box-izq '>
                                                        <div className='moveiz grid flex align-content-center align-items-center justify-content-start  z-1 '>

                                                            <div id='imagenPrueba' className='hidden md:flex p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                                                <Link to={`/productos/${navcate.bCategoria}`} className="no-underline">
                                                                    <img onMouseEnter={textEnter} onMouseLeave={textLeave}  src={`${navcate.image}`} alt="stad1" className="hidden md:flex imgE  w-20rem h-30rem border-round "/>
                                                                </Link>
                                                            </div>
                                                            <div onMouseEnter={textEnter} onMouseLeave={textLeave}  className='hidden md:inline p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                                                <h1 className='mb-0  font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                                
                                                                <h2 className='text-4xl  md:text-left px-6 md:pr-4'>{navcate.descripcion}</h2>
                                                            </div>
                                                            

                                                            <div  className='inline md:hidden p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-4 md:mt-8 '>
                                                                <h1 className='mb-0  font-bold text-6xl'>{navcate.nombreCategoria}</h1>
                                                                
                                                                <h2 className='text-4xl px-6 md:pr-2'>{navcate.descripcion}</h2>
                                                            </div>
                                                            <div id='imagenPrueba' className='flex md:hidden p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                                                <Link to={`/productos/${navcate.bCategoria}`} className="no-underline">
                                                                    <img onMouseEnter={textEnter} onMouseLeave={textLeave}  src={`${navcate.image}`} alt="stad1" className="flex md:hidden imgE  w-20rem h-30rem border-round "/>
                                                                </Link>
                                                            </div>

                                                        </div>
                                                        {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} */}
                                                        
                                                        <div id='activador'  className='fondoCate-izq absolute h-25rem p-0 m-0 w-10 z-0 right-0'>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            )
    
                                ))
                            )
                        }

                        {/*<div className='col-12 md:col-6'>
                            <div className='relative'>
                                <div className='box'>
                                    <div className='movede grid flex align-content-center align-items-center z-1'>
                                        <div className='p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                            <h1 className='mb-0 font-bold text-6xl'>MESAS</h1>
                                            <h2 className='mt-0 font-bold text-6xl'>BERSATI</h2>
                                            <h2 className='text-4xl md:text-right px-6 md:pl-4'>Diseños exclusivos de mesas linea Bersati.</h2>
                                        </div>
                                        <div id='imagenPrueba' className='p-0 col-12 md:col-7 flex justify-content-center md:justify-content-start align-items-center cursor-pointer z-1'>
                                            <img src={imgPruebita} alt="stad1" className="w-20rem h-30rem border-round hover:shadow-2"/>
                                        </div>
                                        
                                    </div>
                                    {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} 
                                    <div id='activador'  className='fondoCate absolute h-30rem p-0 m-0 w-full z-0'>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className='col-12 md:col-6'>
                            <div className='relative'>
                                <div className='box-izq'>
                                    <div className='moveiz grid flex align-content-center align-items-center z-1 '>

                                        <div id='imagenPrueba' className='hidden md:flex p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                            <img src={imgPruebita} alt="stad1" className="hidden md:flex imgE  w-20rem h-30rem border-round hover:shadow-2"/>
                                        </div>
                                        <div  className='hidden md:inline p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-8 '>
                                            <h1 className='mb-0  font-bold text-6xl'>MESAS</h1>
                                            <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                            <h2 className='text-4xl  md:text-left px-6 md:pr-4'>Diseños exclusivos de mesas linea Bersati.</h2>
                                        </div>
                                        

                                        <div  className='inline md:hidden p-0 col-12 md:col-5 text-center cursor-pointer z-2 mt-4 md:mt-8 '>
                                            <h1 className='mb-0  font-bold text-6xl'>MESAS</h1>
                                            <h2 className='mt-0  font-bold text-6xl'>BERSATI</h2>
                                            <h2 className='text-4xl px-6 md:pr-2'>Diseños exclusivos de mesas linea Bersati.</h2>
                                        </div>
                                        <div id='imagenPrueba' className='flex md:hidden p-0 col-12 md:col-7  justify-content-center md:justify-content-end align-items-center  cursor-pointer z-1'>
                                            <img src={imgPruebita} alt="stad1" className="flex md:hidden imgE  w-20rem h-30rem border-round hover:shadow-2"/>
                                        </div>

                                    </div>
                                    {/* style={{translate: "none",rotate: "none", scale: "none", transform: "scale(0,1)"}} 
                                    <div id='activador'  className='fondoCate-izq absolute h-30rem p-0 m-0 w-full z-0'>
                                    </div>
                                </div>
                                
                            </div>
                        </div>*/}

                    </div>
                </div>
                {/*<div className='flex h-screen'>*/}
                    {/* optimizado  -- https://res.cloudinary.com/ddnbi0nfz/video/upload/f_auto:video,q_auto/jus52edf8n5qtxegpszx */}
                    {/*<ReactPlayer width='100%' controls height='100%' loop={true} url='https://res.cloudinary.com/ddnbi0nfz/video/upload/v1729882446/jus52edf8n5qtxegpszx.mp4' /> */}
                {/*</div>*/}
                
                <motion.div 
                    className="cursor"    
                    variants={variants} 
                    animate={cursorVariant}
                >
                    <div className='flex align-items-center justify-content-center w-full h-full'>
                        <p className='z-1 text-white p-0 m-0'>descubre más</p>
                    </div>
                </motion.div>

                <div className=''>

                    {
                        ecoCarousel.loadingCarousel ? (
                        
                        <div className='hidden flex justify-content-center align-content-center'>
                            <Cargando />
                        </div>
                        )
                        :
                        (
                            ecoCarousel.arrayCarousel.length > 0 ? (
                                <Swiper
                                    slidesPerView={1}
                                    loop={true}
                                    effect={'fade'}
                                    modules={[EffectFade, Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                    autoplay={{
                                        delay: 4000,
                                        disableOnInteraction: false,
                                      }}
                                    navigation
                                    centeredSlides={true}
                                    pagination={{ clickable: true }}
                                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                                    /*onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() => console.log('slide change')}*/
                                    className="z-0 w-screen hdef mb-4  " //hdef
                                >
                                    
                                    
                                    {
                                    ecoCarousel.loadingCarousel ? (
                                            ''
                                    )
                                    :
                                    (
                                        ecoCarousel.arrayCarousel.map((car, index) =>(
                                                <SwiperSlide key={index} className=' overflow-hidden'>
                                                    {/*<a  target="_blank" rel="noreferrer">
                                                        <Image onClick={()=>console.log('click')} imageClassName='hidden md:inline-flex lg:inline-flex h-full w-full absolute stiloTrue bg-left' src={car.imagen} alt={car.link} /> 
                                                        <Image imageClassName='inline-flex md:hidden lg:hidden h-full w-full absolute stiloTrue bg-left' src={car.imgresponsive} alt={car.link} /> 
                                                    </a>*/}
                                                    
                                                    <div className="hidden surface-section h-full w-screen stiloTrue bg-center md:flex lg:flex align-items-end " style={{background: `url(${car.imagen})`}}>
                                                        <div className="flex align-content-end px-3 mx-3 lg:px-6 lg:mx-6">
                                                            {/*<a href={car.link === null ? null :`${car.link}`} target="_blank" rel="noreferrer" className="no-underline py-2 w-13rem text-center  mb-4 text-xl text-white font-medium border-2 cursor-pointer border-transparent border-round surface-900">Ver Ahora!</a>*/}
                                                        </div>
                                                    </div>

                                                    <div className="flex surface-section h-full w-screen stiloTrue overflow-hidden bg-no-repeat bg-cover bg-top md:hidden lg:hidden align-items-end " style={{background: `url(${car.imgresponsive})`}}>
                                                        <div className="flex align-content-end px-3 mx-3 lg:px-6 lg:mx-6">
                                                            {/*<a href={car.link === null ? null :`${car.link}`} target="_blank" rel="noreferrer" className="no-underline py-2 w-13rem text-center  mb-4 text-xl text-white font-medium border-2 cursor-pointer border-transparent border-round surface-900">Ver Ahora!</a> */}
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                        ))
                                    )
                                    }
      
                                    <div  className="autoplay-progress" slot="container-end">
                                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                                            <circle cx="24" cy="24" r="20"></circle>
                                        </svg>
                                        <span ref={progressContent}></span>
                                    </div>
                                </Swiper>
                            ):(
                                <Divider layout="horizontal" className="surface-border  max-w-screen"></Divider>
                            )
                        
                        )
                    }
                </div>

                <div className=' flex align-items-end justify-content-center my-8 overflow-hidden bg-no-repeat bg-cover bg-top h-45rem md:h-screen align-content-center' style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imgPruebita})`}}>
                        
                    <div className='flex flex-column text-center p-4'>
                        <div className="text-6xl text-white font-bold mb-1 "> <span className='sombrita'>BERSATI</span></div>
                        <div className="text-5xl text-white font-light mb-2 sombrita">Brindando calidad, elegancia y comodidad.</div>

                        <p className="text-xl mt-2 mb-2 line-height-3 text-white sombrita">Una tienda diseñada para reflejar tu estilo.</p>
                    </div>

                </div>

            </div>
    )
}

export default InicioNew